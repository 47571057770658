import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { GridColumn, ButtonPrimary, H3, Subheader, H2, P } from "notes";
import { formatCurrency } from "Components";
import { showData } from "Data";
import {
  Image,
  EventDetails,
  Divider,
  PurchasedBadge,
  PerforatedLine,
  Requests,
} from "./Components";
import { Separator } from "../Desktop";
import { PackageNotification, AddToCalendar } from "../Components";

export const TicketSmall = ({
  show,
  purchased,
  onActionClick,
  onScrollClick,
  calendarEvent,
  ...props
}) => {
  const { preShow } = useContext(ThemeContext);
  let topPackage = show?.paymentItems.reduce(
    (max, item) => (max.amount > item.amount ? max : item),
    0
  );
  return (
    <TicketContainer {...props}>
      <Image image={show.image}>
        <EventDetails date={show.date} time={show.time} length={show.length} />
      </Image>
      <Divider purchased={purchased} />
      <ArtistInfo xCenter>
        {purchased && <PurchasedBadge text="You Have a Ticket" />}
        <ArtistName purchased={purchased}>{show.artist}</ArtistName>
        <Subheader>{show.subtitle}</Subheader>
      </ArtistInfo>
      <PerforatedLine />
      <BottomContainer>
        {preShow &&
          purchased &&
          (show.requests ? (
            <Requests
              content="Vote for your show’s set list"
              buttonText="Go To Set The Set"
              onClick={onActionClick}
            />
          ) : (
            <ReturnText>Return here at showtime to watch!</ReturnText>
          ))}
        {!purchased && (show.paymentItems.length < 2 || !preShow) && (
          <Price>{show.priceLabel}</Price>
        )}
        {(!purchased || !preShow) && (
          <Purchase onClick={onActionClick}>
            {preShow ? (
              show.paymentItems.length > 1 ? (
                <>
                  Get Your Ticket {<Separator />}{" "}
                  {formatCurrency(show.minPrice)}
                </>
              ) : (
                "Get Your Front Row Ticket"
              )
            ) : (
              "Show Details"
            )}
          </Purchase>
        )}
        {purchased && <AddToCalendar event={calendarEvent} />}
        {!purchased && preShow && show.paymentItems.length > 1 && (
          <PackageNotification
            image={topPackage.assets.primary.path}
            eventId={show.id}
            text={
              show.packageIncentive ??
              "Want exclusive show merch or a VIP experience?"
            }
            linkText={"Learn More"}
            onClick={onScrollClick}
          />
        )}
      </BottomContainer>
    </TicketContainer>
  );
};

const TicketContainer = styled(GridColumn)`
  margin: 0 auto;
  width: 100%;
  & + & {
    margin-top: 24px;
  }
`;

const BottomContainer = styled(GridColumn)`
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 16px;
  padding-top: 0;
  text-align: center;
  width: 100%;
`;

const Purchase = styled(ButtonPrimary)`
  margin-top: 16px;
  width: 100%;
`;

const Price = styled(H3)`
  font-size: 25px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0;
  margin-top: 16px;
  text-align: center;
  text-transform: uppercase;
`;

export const ArtistInfo = styled(GridColumn)`
  background-color: #fff;
  border-width: 0;
  border-color: ${(props) => props.theme.palette.gray.lighter};
  min-height: 138px;
  padding: 24px;
  text-align: center;
  width: 100%;
`;

export const ArtistName = styled(H2)`
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 29px;
  margin-bottom: 8px;
`;

const ReturnText = styled(P)`
  font-weight: 800;
  margin-top: 24px;
  margin-bottom: 8px;
`;
