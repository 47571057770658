export const musicianFormFields = {
  header: {
    title: "Perform on Set.Live",
    subtitle:
      "Are you a musician and want to host shows on Set.Live? Submit the following form and our artist team will get in touch to get you set-up.",
  },
  fields: [
    {
      id: "T6evsspkFEmLryuL8Zoq3A",
      order: 1,
      label: "Name",
      type: "double",
      required: true,
      placeholder: ["First or given name...", "Last or family name..."],
      name: "name",
      names: ["firstName", "lastName"],
    },
    {
      id: "t3h6tqGPUkupDFjFTSn1qQ",
      order: 2,
      label: "Email address",
      type: "email",
      required: true,
      placeholder: "john@email.com",
      name: "email",
    },
    {
      id: "LybtjoFgYEWPqDVh92lF4A",
      order: 3,
      label: "Phone number",
      type: "phone",
      required: false,
      name: "phone",
    },
    {
      id: "Lsxemw4Iu0GmHBTbKXsaTQ",
      order: 4,
      label: "What is your relationship to the artist?",
      type: "radio",
      required: true,
      name: "relationship",
      options: [
        {
          id: 1,
          title: "I am the artist",
          selected: false,
        },
        {
          id: 2,
          title: "Manager",
          selected: false,
        },
        {
          id: 3,
          title: "Booking Agent",
          selected: false,
        },
        {
          id: 4,
          title: "Other",
          selected: false,
        },
      ],
    },
    {
      id: "mbMHjrtyjkyzdHi13Xxb0A",
      order: 5,
      required: true,
      label:
        "Tell us about the artist you would like to book for the set.live stage",
      type: "textarea",
      name: "about",
      placeholder:
        "What’s the artist or band name? What kind of music do they perform? What’s their background? Do they have any specific hopes or goals in using Set.Live?",
    },
  ],
};
