import React, { useContext, useEffect, useState } from "react";
import { StoreContext, MessageContext } from "Components";
import { Payments, Form, PaymentContext } from "./Components";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { P } from "notes";
export * from "./Components/PaymentProvider";

export const Payment = ({ items, onComplete, billingText, ...props }) => {
  const { Store, user } = useContext(StoreContext);
  const { displayMessage } = useContext(MessageContext);
  const stripe = useStripe();
  const elements = useElements();
  const [stripeReady, setStripeReady] = useState(false);
  const [api] = useState(new Payments(stripe, Store));
  const { savedCards, updatePaymentMethods } = useContext(PaymentContext);
  const [cardError, setCardError] = useState();
  const [processing, setProcessing] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);

  const total = items.amount * items.quantity;

  const handleSubmit = () => {
    setProcessing(true);
    if (!total) {
      return api.submitPayment();
    }
    const cardEle = elements.getElement(CardElement);
    if (cardEle) {
      api.addPaymentMethod(cardEle);
    } else {
      api.submitPayment();
    }
  };

  useEffect(() => {
    api.on("payment_request_init", (result, api) => {
      if (result) {
        setPaymentRequest(api.paymentRequest);
      } else {
        setPaymentRequest(null);
      }
    });
    api.on("payment_request_update", (result, api) => {
      if (result) {
        setPaymentRequest(api.paymentRequest);
      } else {
        setPaymentRequest(null);
      }
    });
    api.init().then(() => setStripeReady(true));
  }, []);

  useEffect(() => {
    if (stripeReady) {
      api.setItem(items);
    }
  }, [items, stripeReady]);

  useEffect(() => {
    if (stripeReady && user) {
      api.on("payment_method", (result, api) => {
        if (result.success) {
          api.submitPayment();
          updatePaymentMethods();
        } else {
          setCardError(result.context.message);
          setProcessing(false);
        }
      });
      api.on("payment", (result, api) => {
        if (result.success) {
          setProcessing(false);
          displayMessage({
            children: <P>Purchase Completed!</P>,
            color: "green",
            timeout: 5000,
          });
          onComplete(result);
        } else {
          setCardError(result.context.message);
          setProcessing(false);
        }
      });
    }
  }, [stripeReady, user]);

  if (!user) {
    return null; //TODO: handlesignup
  }

  return (
    <Form
      {...props}
      total={total}
      savedCards={savedCards}
      cardError={cardError}
      onSubmit={handleSubmit}
      processing={processing}
      billingText={billingText}
      RequestButton={
        paymentRequest ? (
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        ) : null
      }
    />
  );
};
