import React from "react";
import styled from "styled-components";
import { GridRow, Icon } from "notes";

export const EventDetails = ({ date, time, length, ...props }) => {
  return (
    <EventDetailsStyle xCenter yCenter {...props}>
      <Icon form name="Calendar" /> Live {date} at {time} ({length} Show)
    </EventDetailsStyle>
  );
};

const EventDetailsStyle = styled(GridRow)`
  border-bottom: 2px solid ${(props) => props.theme.colors.disabledFill};
  color: ${(props) => props.theme.palette.orange.primary};
  font-size: ${(props) => (props.theme.preShow ? "25px" : "17px")};
  font-weight: 900;
  line-height: ${(props) => (props.theme.preShow ? "32px" : "24px")};
  width: 100%;
  svg {
    display: ${(props) => (props.theme.preShow ? "block" : "none")};
    margin-right: 9px;
    width: 24px;
    height: 24px;
    path {
      fill: ${(props) => props.theme.palette.orange.primary};
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 14px;
    margin-top: 16px;
    padding-bottom: 8px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: ${(props) => (props.theme.preShow ? "1.917vw" : "1.304vw")};
    margin-top: 1.84vw;
    padding-bottom: 1.227vw;
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    font-size: ${(props) => (props.theme.preShow ? "25px" : "17px")};
    line-height: ${(props) => (props.theme.preShow ? "32px" : "24px")};
    margin-top: 24px;
    padding-bottom: 16px;
  }
`;
