import React from "react";
import { Payment } from "Components";
import styled from "styled-components";
import { Modal, withDefaultMedia } from "notes";

export const PaymentSlideout = withDefaultMedia(
  ({ items, onComplete, onCancel, matches }) => {
    const style = matches.large ? largeStyle : smallStyle;
    return (
      <Modal open={true} style={style} setOpen={onCancel}>
        <Payment items={items} onComplete={onComplete} />
      </Modal>
    );
  }
);

const largeStyle = {
  padding: "0",
};

const smallStyle = {
  position: "fixed",
  bottom: "0",
  left: "0",
  right: "0",
  height: "60vh",
  overflow: "scroll",
  padding: "0",
  maxWidth: "unset",
};
