import React, { Fragment } from "react";
import styled from "styled-components";
import { SupportList, SupportBar } from "./Components";
import { withDefaultMedia } from "notes";
import { useListVals, useObjectVal } from "react-firebase-hooks/database";
import { useContext } from "react";
import { StoreContext, EventContext, Packages } from "Components";

export const Leaderboard = withDefaultMedia(
  React.memo(({ matches }) => {
    const {
      Store,
      user: { uid },
      username,
    } = useContext(StoreContext);
    const { event } = useContext(EventContext);
    const { id } = event;
    const [leaders] = useListVals(
      Store.database()
        .ref(`${id}/leaderboard/leaders`)
        .limitToFirst(matches.large ? 5 : 3)
    );
    const [userRank] = useObjectVal(
      Store.database().ref(`${id}/leaderboard/ranks/${uid}`)
    );
    const [total] = useObjectVal(
      Store.database().ref(`${id}/leaderboard/totals/${uid}`)
    );
    if (!leaders || !userRank || typeof total !== "number") {
      return null; //TODO: Nicer loading state
    }
    let levels = {};
    let user = null;
    Object.keys(event.supportLevels).map((levelId) => {
      levels[levelId] = event.supportLevels[levelId].selectedIcon ? (
        <img
          src={`${process.env.REACT_APP_ASSETS_PATH}/${event.supportLevels[levelId].selectedIcon}`}
          height="18"
        />
      ) : null;
    });

    if (!leaders.find((leader) => leader.id === userRank.hash)) {
      const currentLevel = Object.keys(event.supportLevels).reduce(
        (maxKey, currKey) => {
          if (
            (event.supportLevels[maxKey]?.amount || -1) <
              event.supportLevels[currKey].amount &&
            total > event.supportLevels[currKey].amount
          ) {
            return currKey;
          }
          return maxKey;
        },
        {}
      );
      user = {
        ...userRank,
        user: username,
        level: currentLevel,
      };
    }

    return (
      <Container>
        <div style={{ padding: "20px", backgroundColor: "#000000" }}>
          <SupportBar user={user} />
        </div>

        <SupportList
          topSupporters={leaders}
          user={user}
          levels={levels}
        ></SupportList>
        <Packages eventId={event.id} smallView />
      </Container>
    );
  })
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
