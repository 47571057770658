import React from "react";
import styled from "styled-components";
import { GridRow, Icon, H4 } from "notes";

export const PurchasedBadge = ({ text, ...props }) => {
  return (
    <PurchasePill xCenter yCenter {...props}>
      <TicketIcon tertiary name="Ticket" />
      <PurchasedText>{text}</PurchasedText>
    </PurchasePill>
  );
};

const PurchasePill = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.purple.darker};
  border-radius: 24.5px;
  margin-bottom: 2.256vw;
  height: 4.605vw;
  width: 32.237vw;
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 17px;
    margin-bottom: 24px;
    height: 49px;
    width: 343px;
  }
`;

const PurchasedText = styled(H4)`
  color: #ffffff;
  font-size: 1.598vw;
  font-weight: 700;
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 17px;
  }
`;

const TicketIcon = styled(Icon)`
  margin-right: 1.504vw;
  width: 3.008vw;
  height: 3.008vw;
  path {
    fill: #fff;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    margin-right: 16px;
    width: 32px;
    height: 32px;
  }
`;
