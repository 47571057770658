import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { GridColumn, Icon } from "notes";
import { ButtonTertiary } from "Components";
import AddToCalendar2 from "react-add-to-calendar";
import "./style.css";

const calendars = [
  { apple: "Apple Calendar" },
  { google: "Google" },
  { outlook: "Outlook" },
  { outlookcom: "Outlook.com" },
  { yahoo: "Yahoo" },
];

export const AddToCalendar = ({ event, ...props }) => {
  const [showList, setShowList] = useState(false);
  const menuRef = useRef();

  const handleClickOut = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setShowList(false);
    }
  };

  useEffect(() => {
    if (showList) {
      document.addEventListener("click", handleClickOut);
      return () => {
        document.removeEventListener("click", handleClickOut);
      };
    }
  }, [showList]);

  return (
    <Container>
      <AddButton
        iconLeft={<AddIcon tertiary name="CalendarAdd" />}
        onClick={() => setShowList(!showList)}
      >
        Add To Calendar
      </AddButton>
      {showList ? (
        <RefContainer ref={menuRef}>
          <AddToCalendar2
            buttonLabel=""
            // displayItemIcons={true}
            event={event}
            listItems={calendars}
            optionsOpen={true}
            {...props}
          />
        </RefContainer>
      ) : (
        ""
      )}
    </Container>
  );
};

const Container = styled(GridColumn)`
  margin-top: 8px;
  width: 100%;
`;

const AddButton = styled(ButtonTertiary)`
  height: 40px;
  padding: 0;
`;

const RefContainer = styled.div``;

const AddIcon = styled(Icon)`
  path {
    fill: ${(props) => props.theme.palette.black};
  }
`;
