import React, { createContext } from "react";
import { useEvent } from "Components";

export const EventContext = createContext();
export const EventConsumer = EventContext.Consumer;
export const EventProvider = ({ children, id }) => {
  const [event, loading, error] = useEvent(id);
  const fullEvent = {
    id,
    ...event,
  };
  return (
    <EventContext.Provider value={{ event: fullEvent, loading, error }}>
      {children}
    </EventContext.Provider>
  );
};
