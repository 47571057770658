import React, { useContext } from "react";
import { SupportLevelBar } from "notes";
import { EventContext, StoreContext } from "Components";
import { useObjectVal } from "react-firebase-hooks/database";

export const SupportBar = React.memo(() => {
  const { event } = useContext(EventContext);
  const { Store, user } = useContext(StoreContext);
  const levels = Object.values(event.supportLevels)
    .map(({ amount, title, selectedIcon, unselectedIcon }) => ({
      value: amount / 100,
      label: "",
      selectedIcon: () =>
        selectedIcon ? (
          <img
            src={`${process.env.REACT_APP_ASSETS_PATH}/${selectedIcon}`}
            height="18"
          />
        ) : null,
      unselectedIcon: () =>
        unselectedIcon ? (
          <img
            src={`${process.env.REACT_APP_ASSETS_PATH}/${unselectedIcon}`}
            height="18"
          />
        ) : null,
    }))
    .sort((a, b) => a.value - b.value);
  const [total] = useObjectVal(
    Store.database().ref(`${event.id}/leaderboard/totals/${user.uid}`)
  );
  return <SupportLevelBar value={total / 100} sections={levels} />;
});
