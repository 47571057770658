import React from "react";
import styled from "styled-components";
import { GridRow, Subheader } from "notes";
export const OrDivider = () => {
  return (
    <LineContainer yCenter>
      <Line />
      <Subheader>Or</Subheader>
      <Line />
    </LineContainer>
  );
};

const LineContainer = styled(GridRow)`
  height: 24px;
  justify-content: space-between;
  margin-top: 24px;
`;

const Line = styled.div`
  background-color: ${(props) => props.theme.palette.gray.lightest};
  height: 1px;
  width: 39%;
`;
