import React, { useState } from "react";
import styled from "styled-components";
import { LoaderInline, FieldMessage } from "Components";
import { signup } from "services";
import { validate } from "modules";
import { user_schema } from "./schema";
import {
  GridRow,
  ErrorMessage,
  GridColumn,
  Label,
  Footnote,
  Input,
  Icon,
  ButtonPrimary,
  Link,
  Checkbox,
} from "notes";

export const ManualEnroll = ({ onSuccess }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [newUser, setNewUser] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const createAccount = async () => {
    // validate form input
    let errors = validate(newUser, user_schema);
    setFormErrors(errors ? errors : {});
    if (errors) {
      setErrorMessage(
        "Please review the highlighted errors before attempting your submission again."
      );
      return;
    }
    try {
      setSubmitted(true);
      await signup(newUser);
      onSuccess(true);
      setSubmitted(false);
      return true;
    } catch (err) {
      console.log(err);
      setErrorMessage(err.message);
      setSubmitted(false);
    }
  };

  const handleChange = (value, e) => {
    setNewUser({ ...newUser, [e.target.name]: value });
    setErrorMessage("");
    if (formErrors) {
      delete formErrors[e.target.name];
      setFormErrors(formErrors);
    }
  };

  return (
    <Form noShrink>
      {!!errorMessage && (
        <ErrorMessage
          style={{ marginBottom: 0, marginTop: "24px" }}
          content={errorMessage}
        />
      )}

      <Label>Name</Label>
      <GridRow>
        <FieldLeft
          name="firstName"
          value={newUser.firstName}
          onChange={handleChange}
          placeholder="First or given name..."
          error={!!formErrors.firstName}
        />
        <FieldRight
          name="lastName"
          value={newUser.lastName}
          onChange={handleChange}
          placeholder="Last or family name..."
          error={!!formErrors.lastName}
        />
      </GridRow>
      <FieldMessage
        active={!!formErrors.firstName || !!formErrors.lastName}
        content={formErrors.firstName || formErrors.lastName}
      />

      <Label>Email address</Label>
      <Input
        name="email"
        leftIcon={<Icon form name="Email" />}
        placeholder="john@email.com"
        value={newUser.email}
        onChange={handleChange}
        error={!!formErrors.email}
        type="email"
      />
      <FieldMessage active={!!formErrors.email} content={formErrors.email} />

      <Label>Password</Label>
      <Input
        name="password"
        leftIcon={<Icon form name="Key" />}
        rightIcon={
          <TogglePassword
            onClick={() => setHidePassword(!hidePassword)}
            name={hidePassword ? "Hide" : "Show"}
          />
        }
        type={hidePassword ? "password" : "text"}
        placeholder="Password..."
        value={newUser.password}
        error={!!formErrors.password}
        onChange={handleChange}
      />

      <FieldMessage
        active={!!formErrors.password}
        content={formErrors.password}
      />
      <Footnote>Password must contain at least eight characters</Footnote>

      <Checkbox
        name="agree"
        checked={newUser.agree}
        onChange={handleChange}
        title="I am at least 13 years of age"
      />

      <FieldMessage active={!!formErrors.agree} content={formErrors.agree} />

      <LoginButton disabled={submitted} onClick={() => createAccount()}>
        {submitted ? <LoaderInline /> : "Create Account"}
      </LoginButton>
      <Footnote>
        By creating an account you agree to our{" "}
        <InlineLink target="_blank" href="/terms">
          terms of use
        </InlineLink>{" "}
        and{" "}
        <InlineLink target="_blank" href="/privacy">
          privacy policy
        </InlineLink>
        .
      </Footnote>
    </Form>
  );
};

const PasswordLink = styled(Link)`
  display: inline-block;
  font-size: 12px;
  margin-top: 12px;
  width: auto;
`;

const LoginButton = styled(ButtonPrimary)`
  margin-top: 33px;
`;

const Form = styled(GridColumn)`
  ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
  ${PasswordLink} {
    align-self: flex-start;
  }
  ${Footnote} {
    margin-top: 8px;
    margin-bottom: 17px;
  }
  ${LoginButton} + ${Footnote} {
    margin-top: 16px;
    margin-bottom: 0;
    ${Link} {
      font-size: 11px;
    }
  }
`;

const InlineLink = styled(Link)`
  display: inline-flex;
  font-size: 11px;
  text-decoration: underline;
`;

const TogglePassword = styled(Icon)`
  margin-top: -4px;
`;

const FieldLeft = styled(Input)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
`;

const FieldRight = styled(Input)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;
