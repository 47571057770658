import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GridColumn } from "notes";
import {
  Login as LoginComponent,
  FormWrapper,
  PurchaseTicket,
} from "Components";
import { Container } from "../Enrollment";

export const Login = ({ location: { state } }) => {
  const history = useHistory();

  const handleLogin = (success) => {
    !!success && !!state ? history.push("/purchase", state) : history.push("/");
  };

  return (
    <FormWrapper>
      <Container xCenter={!state} showTicket={!!state}>
        {!!state && <PurchaseTicket eventId={state.id} />}
        <LoginComponent
          hasTicket={!!state}
          onSuccess={handleLogin}
          onSignUpClick={() => history.push("/signup", state)}
        />
      </Container>
    </FormWrapper>
  );
};
