import React, { useState } from "react";
import styled from "styled-components";
import { P, H3, ButtonPrimary, Icon, GridRow, H4, ErrorMessage } from "notes";
import { OrDivider } from "Components";
import { CardElement } from "@stripe/react-stripe-js";
import { FormContainer } from "Components/Forms";
import { CreditCardBadge, formatCurrency, LoaderInline } from "Components";
import "./stripe-field.css";

export const Form = ({
  total,
  savedCards,
  cardError,
  onSubmit,
  RequestButton,
  processing,
  billingText = null,
  ...props
}) => {
  const [useOther, setUseOther] = useState(false);

  const SavedCard = ({ last4, brand, exp_month, exp_year }) => {
    return (
      <SavedCardContainer yCenter>
        <CreditCardBadge type={brand} />
        <Last4>{last4}...</Last4>
        <Exp>
          {exp_month}/{exp_year}
        </Exp>
        <UpdateCard onClick={() => setUseOther(true)}>Change</UpdateCard>
      </SavedCardContainer>
    );
  };

  return (
    <Container isFree={total === 0} {...props}>
      {total > 0 ? (
        <>
          <H3>Billing Information</H3>
          {billingText}

          <H3 style={{ paddingTop: "24px" }}>Amount to be charged</H3>
          <H3>{formatCurrency(total)}</H3>
          {RequestButton && (
            <>
              {RequestButton}
              <OrDivider />
            </>
          )}

          <BillingTitle yCenter>
            <LockIcon name="Lock" />
            <H4>Pay With Credit Card</H4>
          </BillingTitle>
          {!useOther && savedCards.length ? (
            savedCards.map(SavedCard)
          ) : (
            <CardElement
              options={{
                classes: {
                  base: "stripe-credit-card",
                  complete: "stripe-credit-card--complete",
                  focus: "stripe-credit-card--focus",
                  invalid: "stripe-credit-card--invalid",
                },
                style: {
                  base: {
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
                disabled: processing,
              }}
            />
          )}
          {cardError && <ErrorMessage content={cardError} />}
          <ConfirmButton onClick={onSubmit} disabled={processing}>
            {processing ? <LoaderInline /> : "Confirm Purchase"}
          </ConfirmButton>
        </>
      ) : (
        <>
          <ConfirmButton onClick={onSubmit} disabled={processing}>
            {processing ? <LoaderInline /> : "Claim Ticket"}
          </ConfirmButton>
        </>
      )}
    </Container>
  );
};

const SavedCardContainer = styled(GridRow)`
  background: ${(props) => props.theme.palette.gray.lightest};
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-radius: 4px;
  height: 40px;
  padding: 0 12px;
  position: relative;
`;

const Last4 = styled(P)`
  color: ${(props) => props.theme.palette.gray.primary};
  font-size: 16px;
  margin-left: 8px;
`;

const Exp = styled(Last4)`
  width: 100%;
`;

const UpdateCard = styled(H4)`
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
`;

const Container = styled(FormContainer)`
  margin: ${(props) => (props.isFree ? 0 : "40px auto 0 auto")};
  @media only screen and ${(props) => props.theme.media.large} {
    margin: ${(props) => (props.isFree ? 0 : "40px auto 40px auto")};
  }
  padding: ${(props) => (props.isFree ? 0 : "24px")};
`;

const BillingTitle = styled(GridRow)`
  margin: 16px 0;
  ${H4} {
    color: ${(props) => props.theme.palette.black};
    font-size: 14px;
    font-weight: 700;
  }
`;

const LockIcon = styled(Icon)`
  margin-right: 4px;
  height: 24px;
  width: 24px;
  path {
    fill: ${(props) => props.theme.palette.black};
  }
`;

const ConfirmButton = styled(ButtonPrimary)`
  margin-top: 16px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-bottom: 16px;
  }
`;
