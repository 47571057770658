import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { GridColumn, GridRow, ButtonPrimary, H3, P } from "notes";
import { formatCurrency } from "Components";
import {
  PurchasedBadge,
  Artist,
  Image,
  EventDetails,
  Description,
  Requests,
  Divider,
} from "./Components";
import { PackageNotification, AddToCalendar } from "../Components";

export const TicketLarge = ({
  show,
  purchased,
  children,
  onActionClick,
  onScrollClick,
  calendarEvent,
  ...props
}) => {
  const { preShow } = useContext(ThemeContext);

  if (!show) {
    return null;
  }

  let topPackage = show?.paymentItems.reduce(
    (max, item) => (max.amount > item.amount ? max : item),
    0
  );

  return (
    <TicketContainer {...props}>
      <Container xCenter yCenter stretch>
        {purchased && <PurchasedBadge text="You Have a Ticket To The Show!" />}

        <Artist name={show.artist} subtitle={show.subtitle} />

        <EventDetails date={show.date} time={show.time} length={show.length} />

        {!preShow && <Description content={show.description} />}

        {preShow &&
          purchased &&
          (show.requests ? (
            <ActionsContainer>
              <PreShowActions>
                <Requests
                  content="Vote for your show’s set list on Set The Set!"
                  buttonText="Go To Set The Set"
                  onClick={onActionClick}
                />
                <AddToCalendar event={calendarEvent} />
              </PreShowActions>
            </ActionsContainer>
          ) : (
            <NoRequestsAction>
              <ReturnText>Return here at showtime to watch!</ReturnText>
              <AddToCalendar event={calendarEvent} />
            </NoRequestsAction>
          ))}

        {(!purchased || !preShow) && (
          <PaddedContainer xCenter>
            {!purchased && (show.paymentItems.length < 2 || !preShow) && (
              <Price>{show.priceLabel}</Price>
            )}
            <Purchase
              variant={
                show.paymentItems.length > 1 && (preShow || purchased)
                  ? true
                  : false
              }
              onClick={onActionClick}
            >
              {preShow ? (
                show.paymentItems.length > 1 ? (
                  <>
                    Get Your Front Row Ticket
                    <Separator />
                    {formatCurrency(show.minPrice)}
                  </>
                ) : (
                  "Get Your Front Row Ticket"
                )
              ) : (
                "Show Details"
              )}
            </Purchase>
            {purchased && <AddToCalendar event={calendarEvent} />}
          </PaddedContainer>
        )}
        {!purchased && preShow && show.paymentItems.length > 1 && (
          <PaddedContainer>
            <PackageNotification
              imagePath={show.incentiveImage ?? topPackage.assets.primary.path}
              eventId={show.id}
              text={
                show.packageIncentive ??
                "Want exclusive show merch or a VIP experience?"
              }
              linkText={"Learn More"}
              onClick={onScrollClick}
            />
          </PaddedContainer>
        )}
      </Container>

      <Divider purchased={purchased} />

      <Image image={show.image} />
    </TicketContainer>
  );
};

const TicketContainer = styled(GridRow)`
  width: 100%;
  margin: 0 auto;
  & + & {
    margin-top: 24px;
  }
`;

const Container = styled(GridColumn)`
  background-color: #fff;
  text-align: center;
  border-top-left-radius: ${(props) => (props.theme.preShow ? "16px" : 0)};
  border-bottom-left-radius: ${(props) => (props.theme.preShow ? "16px" : 0)};
  @media only screen and ${(props) => props.theme.media.medium} {
    padding: 24px 32px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    padding: ${(props) =>
      props.theme.preShow ? "24px 5.215vw 24px 6.135vw" : "24px 56px"};
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    padding: ${(props) =>
      props.theme.preShow ? "24px 68px 24px 80px" : "24px 56px"};
  }
`;

const Price = styled(H3)`
  font-weight: 900;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;

  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 1.917vw;
    line-height: 2.454vw;
    margin-top: 3.067vw;
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    font-size: 25px;
    line-height: 32px;
    margin-top: 40px;
  }
`;

export const Separator = styled.div`
  background-color: #fff;
  margin: 0 12px;
  width: 2px;
  height: 22px;
`;

const PaddedContainer = styled(GridColumn)`
  width: 100%;
  max-width: 392px;
`;

const Purchase = styled(ButtonPrimary)`
  margin-top: ${(props) => (props.variant ? "3.067vw" : "1.227vw")};
  width: 100%;
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    margin-top: ${(props) => (props.variant ? "40px" : "16px")};
  }
`;

const ActionsContainer = styled(GridColumn)`
  background-color: ${(props) => props.theme.palette.teal.lightest};
  border: 1px solid ${(props) => props.theme.palette.teal.lighter};
  margin-top: 40px;
  padding: 16px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-top: 3.067vw;
  }
`;

const PreShowActions = styled(GridColumn)`
  margin: 0 auto;
  max-width: 392px;
  width: 100%;
`;

const NoRequestsAction = styled(PreShowActions)`
  margin-top: 40px;
  max-width: 392px;
  width: 100%;
`;

const ReturnText = styled(P)`
  font-weight: 800;
  margin-bottom: 8px;
`;
