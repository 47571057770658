export const countryCodes = {
  us: {
    name: "United States",
    countryCode: "",
    alias: "America",
    phone: "(999) 999-9999",
    postalCode: "99999",
  },
  ca: {
    name: "Canada",
    countryCode: "1",
    postalCode: "*9*9*9",
  },
  mx: {
    name: "Mexico",
    countryCode: "52",
    postalCode: "99999",
  },
  af: {
    name: "Afghanistan",
    countryCode: "93",
  },
  ax: {
    name: "Aland Islands",
    countryCode: "358 18",
  },
  al: {
    name: "Albania",
    countryCode: "355",
  },
  dz: {
    name: "Algeria",
    countryCode: "213",
  },
  as: {
    name: "American Samoa",
    countryCode: "1 684",
  },
  ad: {
    name: "Andorra",
    countryCode: "376",
  },
  ao: {
    name: "Angola",
    countryCode: "244",
  },
  ai: {
    name: "Anguilla",
    countryCode: "1 264",
  },
  ag: {
    name: "Antigua",
    countryCode: "1 268",
  },
  ar: {
    name: "Argentina",
    countryCode: "54",
  },
  am: {
    name: "Armenia",
    countryCode: "374",
  },
  aw: {
    name: "Aruba",
    countryCode: "297",
  },
  au: {
    name: "Australia",
    countryCode: "61",
    postalCode: "9999",
  },
  at: {
    name: "Austria",
    countryCode: "43",
    postalCode: "9999",
  },
  az: {
    name: "Azerbaijan",
    countryCode: "994",
  },
  bs: {
    name: "Bahamas",
    countryCode: "1 242",
  },
  bh: {
    name: "Bahrain",
    countryCode: "973",
  },
  bd: {
    name: "Bangladesh",
    countryCode: "880",
  },
  bb: {
    name: "Barbados",
    countryCode: "1 246",
  },
  by: {
    name: "Belarus",
    countryCode: "375",
  },
  be: {
    name: "Belgium",
    countryCode: "32",
    postalCode: "9999",
  },
  bz: {
    name: "Belize",
    countryCode: "501",
  },
  bj: {
    name: "Benin",
    countryCode: "229",
  },
  bm: {
    name: "Bermuda",
    countryCode: "1 441",
  },
  bt: {
    name: "Bhutan",
    countryCode: "975",
  },
  bo: {
    name: "Bolivia",
    countryCode: "591",
  },
  ba: {
    name: "Bosnia",
    countryCode: "387",
  },
  bw: {
    name: "Botswana",
    countryCode: "267",
  },
  br: {
    name: "Brazil",
    countryCode: "55",
    postalCode: "99999999",
  },
  vg: {
    name: "British Virgin Islands",
    countryCode: "1 284",
  },
  bn: {
    name: "Brunei",
    countryCode: "673",
  },
  bg: {
    name: "Bulgaria",
    countryCode: "359",
  },
  bf: {
    name: "Burkina Faso",
    countryCode: "226",
  },
  mm: {
    name: "Burma",
    countryCode: "95",
    alias: "Myanmar",
  },
  bi: {
    name: "Burundi",
    countryCode: "257",
  },
  tc: {
    name: "Turks and Caicos Islands",
    countryCode: "1 649",
  },
  kh: {
    name: "Cambodia",
    countryCode: "855",
  },
  cm: {
    name: "Cameroon",
    countryCode: "237",
  },
  cv: {
    name: "Cape Verde",
    countryCode: "238",
  },
  ky: {
    name: "Cayman Islands",
    countryCode: "1345",
  },
  cf: {
    name: "Central African Republic",
    countryCode: "236",
  },
  td: {
    name: "Chad",
    countryCode: "235",
  },
  cl: {
    name: "Chile",
    countryCode: "56",
  },
  cn: {
    name: "China",
    countryCode: "86",
    postalCode: "999999",
  },
  cx: {
    name: "Christmas Island",
    countryCode: "cx",
  },
  cc: {
    name: "Cocos Islands",
    countryCode: "cc",
  },
  co: {
    name: "Colombia",
    countryCode: "57",
  },
  km: {
    name: "Comoros",
    countryCode: "269",
  },
  cd: {
    name: "Congo",
    countryCode: "243",
  },
  ck: {
    name: "Cook Islands",
    countryCode: "682",
  },
  cr: {
    name: "Costa Rica",
    countryCode: "506",
  },
  ci: {
    name: "Cote Divoire",
    countryCode: "225",
  },
  hr: {
    name: "Croatia",
    countryCode: "385",
  },
  cu: {
    name: "Cuba",
    countryCode: "53",
  },
  cy: {
    name: "Cyprus",
    countryCode: "357",
  },
  cz: {
    name: "Czech Republic",
    countryCode: "420",
  },
  dk: {
    name: "Denmark",
    countryCode: "45",
    postalCode: "9999",
  },
  dj: {
    name: "Djibouti",
    countryCode: "253",
  },
  dm: {
    name: "Dominica",
    countryCode: "1767",
  },
  do: {
    name: "Dominican Republic",
    countryCode: "1",
  },
  ec: {
    name: "Ecuador",
    countryCode: "593",
  },
  eg: {
    name: "Egypt",
    countryCode: "20",
  },
  sv: {
    name: "El Salvador",
    countryCode: "503",
  },
  gq: {
    name: "Equatorial Guinea",
    countryCode: "240",
  },
  er: {
    name: "Eritrea",
    countryCode: "291",
  },
  ee: {
    name: "Estonia",
    countryCode: "372",
  },
  et: {
    name: "Ethiopia",
    countryCode: "251",
  },
  eu: {
    name: "Europeanunion",
    countryCode: "eu",
  },
  fk: {
    name: "Falkland Islands",
    countryCode: "500",
  },
  fo: {
    name: "Faroe Islands",
    countryCode: "298",
  },
  fj: {
    name: "Fiji",
    countryCode: "679",
  },
  fi: {
    name: "Finland",
    countryCode: "358",
    postalCode: "99999",
  },
  fr: {
    name: "France",
    countryCode: "33",
    postalCode: "99999",
  },
  pf: {
    name: "French Polynesia",
    countryCode: "689",
  },
  tf: {
    name: "French Territories",
    countryCode: "tf",
  },
  ga: {
    name: "Gabon",
    countryCode: "241",
  },
  gm: {
    name: "Gambia",
    countryCode: "220",
  },
  ge: {
    name: "Georgia",
    countryCode: "995",
  },
  de: {
    name: "Germany",
    countryCode: "49",
    postalCode: "99999",
  },
  gh: {
    name: "Ghana",
    countryCode: "233",
  },
  gi: {
    name: "Gibraltar",
    countryCode: "350",
  },
  gr: {
    name: "Greece",
    countryCode: "30",
    postalCode: "99999",
  },
  gl: {
    name: "Greenland",
    countryCode: "299",
  },
  gd: {
    name: "Grenada",
    countryCode: "1473",
  },
  gp: {
    name: "Guadeloupe",
    countryCode: "590",
  },
  gu: {
    name: "Guam",
    countryCode: "1671",
  },
  gt: {
    name: "Guatemala",
    countryCode: "502",
  },
  gn: {
    name: "Guinea",
    countryCode: "224",
  },
  gw: {
    name: "Guinea-Bissau",
    countryCode: "245",
  },
  gy: {
    name: "Guyana",
    countryCode: "592",
  },
  ht: {
    name: "Haiti",
    countryCode: "509",
  },
  hn: {
    name: "Honduras",
    countryCode: "504",
  },
  hk: {
    name: "Hong Kong",
    countryCode: "852",
  },
  hu: {
    name: "Hungary",
    countryCode: "36",
  },
  is: {
    name: "Iceland",
    countryCode: "354",
  },
  in: {
    name: "India",
    countryCode: "91",
    postalCode: "999999",
  },
  io: {
    name: "Indian Ocean Territory",
    countryCode: "246",
  },
  id: {
    name: "Indonesia",
    countryCode: "62",
    postalCode: "99999",
  },
  ir: {
    name: "Iran",
    countryCode: "98",
  },
  iq: {
    name: "Iraq",
    countryCode: "964",
  },
  ie: {
    name: "Ireland",
    countryCode: "353",
  },
  il: {
    name: "Israel",
    countryCode: "972",
  },
  it: {
    name: "Italy",
    countryCode: "39",
    postalCode: "99999",
  },
  jm: {
    name: "Jamaica",
    countryCode: "1876",
  },
  sj: {
    name: "Jan Mayen",
    countryCode: "sj",
    alias: "Svalbard",
  },
  jp: {
    name: "Japan",
    countryCode: "81",
    postalCode: "9999999",
  },
  jo: {
    name: "Jordan",
    countryCode: "962",
  },
  kz: {
    name: "Kazakhstan",
    countryCode: "7",
  },
  ke: {
    name: "Kenya",
    countryCode: "254",
  },
  ki: {
    name: "Kiribati",
    countryCode: "686",
  },
  kw: {
    name: "Kuwait",
    countryCode: "965",
  },
  kg: {
    name: "Kyrgyzstan",
    countryCode: "996",
  },
  la: {
    name: "Laos",
    countryCode: "856",
  },
  lv: {
    name: "Latvia",
    countryCode: "371",
  },
  lb: {
    name: "Lebanon",
    countryCode: "961",
  },
  ls: {
    name: "Lesotho",
    countryCode: "266",
  },
  lr: {
    name: "Liberia",
    countryCode: "231",
  },
  ly: {
    name: "Libya",
    countryCode: "218",
  },
  li: {
    name: "Liechtenstein",
    countryCode: "423",
  },
  lt: {
    name: "Lithuania",
    countryCode: "370",
  },
  lu: {
    name: "Luxembourg",
    countryCode: "352",
    postalCode: "9999-9999",
  },
  mo: {
    name: "Macau",
    countryCode: "853",
  },
  mk: {
    name: "Macedonia",
    countryCode: "389",
  },
  mg: {
    name: "Madagascar",
    countryCode: "261",
  },
  mw: {
    name: "Malawi",
    countryCode: "265",
  },
  my: {
    name: "Malaysia",
    countryCode: "60",
    postalCode: "99999",
  },
  mv: {
    name: "Maldives",
    countryCode: "960",
  },
  ml: {
    name: "Mali",
    countryCode: "223",
  },
  mt: {
    name: "Malta",
    countryCode: "356",
  },
  mh: {
    name: "Marshall Islands",
    countryCode: "692",
  },
  mq: {
    name: "Martinique",
    countryCode: "596",
  },
  mr: {
    name: "Mauritania",
    countryCode: "222",
  },
  mu: {
    name: "Mauritius",
    countryCode: "230",
  },
  yt: {
    name: "Mayotte",
    countryCode: "yt",
  },
  fm: {
    name: "Micronesia",
    countryCode: "691",
  },
  md: {
    name: "Moldova",
    countryCode: "373",
  },
  mc: {
    name: "Monaco",
    countryCode: "377",
  },
  mn: {
    name: "Mongolia",
    countryCode: "976",
  },
  me: {
    name: "Montenegro",
    countryCode: "382",
  },
  ms: {
    name: "Montserrat",
    countryCode: "1664",
  },
  ma: {
    name: "Morocco",
    countryCode: "212",
  },
  mz: {
    name: "Mozambique",
    countryCode: "258",
  },
  na: {
    name: "Namibia",
    countryCode: "264",
  },
  nr: {
    name: "Nauru",
    countryCode: "674",
  },
  np: {
    name: "Nepal",
    countryCode: "977",
  },
  nl: {
    name: "Netherlands",
    countryCode: "31",
    postalCode: "9999",
  },
  an: {
    name: "Netherlandsantilles",
    countryCode: "an",
  },
  nc: {
    name: "New Caledonia",
    countryCode: "687",
  },
  pg: {
    name: "New Guinea",
    countryCode: "675",
  },
  nz: {
    name: "New Zealand",
    countryCode: "64",
  },
  ni: {
    name: "Nicaragua",
    countryCode: "505",
  },
  ne: {
    name: "Niger",
    countryCode: "227",
  },
  ng: {
    name: "Nigeria",
    countryCode: "234",
  },
  nu: {
    name: "Niue",
    countryCode: "683",
  },
  nf: {
    name: "Norfolk Island",
    countryCode: "672",
  },
  kp: {
    name: "North Korea",
    countryCode: "850",
  },
  mp: {
    name: "Northern Mariana Islands",
    countryCode: "1670",
  },
  no: {
    name: "Norway",
    countryCode: "47",
    postalCode: "9999",
  },
  om: {
    name: "Oman",
    countryCode: "968",
  },
  pk: {
    name: "Pakistan",
    countryCode: "92",
  },
  pw: {
    name: "Palau",
    countryCode: "680",
  },
  ps: {
    name: "Palestine",
    countryCode: "970",
  },
  pa: {
    name: "Panama",
    countryCode: "507",
  },
  py: {
    name: "Paraguay",
    countryCode: "595",
  },
  pe: {
    name: "Peru",
    countryCode: "51",
  },
  ph: {
    name: "Philippines",
    countryCode: "63",
    postalCode: "9999",
  },
  pn: {
    name: "Pitcairn Islands",
    countryCode: "pn",
  },
  pl: {
    name: "Poland",
    countryCode: "48",
  },
  pt: {
    name: "Portugal",
    countryCode: "351",
    postalCode: "9999-999",
  },
  pr: {
    name: "Puerto Rico",
    countryCode: "1",
    postalCode: "99999",
  },
  qa: {
    name: "Qatar",
    countryCode: "974",
  },
  re: {
    name: "Reunion",
    countryCode: "262",
  },
  ro: {
    name: "Romania",
    countryCode: "40",
  },
  ru: {
    name: "Russia",
    countryCode: "7",
  },
  rw: {
    name: "Rwanda",
    countryCode: "250",
  },
  sh: {
    name: "Saint Helena",
    countryCode: "290",
  },
  kn: {
    name: "Saint Kitts and Nevis",
    countryCode: "1869",
  },
  lc: {
    name: "Saint Lucia",
    countryCode: "1758",
  },
  pm: {
    name: "Saint Pierre",
    countryCode: "508",
  },
  vc: {
    name: "Saint Vincent",
    countryCode: "1784",
  },
  ws: {
    name: "Samoa",
    countryCode: "685",
  },
  sm: {
    name: "San Marino",
    countryCode: "378",
  },
  gs: {
    name: "Sandwich Islands",
    countryCode: "gs",
  },
  st: {
    name: "Sao Tome",
    countryCode: "239",
  },
  sa: {
    name: "Saudi Arabia",
    countryCode: "966",
  },
  sn: {
    name: "Senegal",
    countryCode: "221",
  },
  rs: {
    name: "Serbia",
    countryCode: "381",
  },
  sc: {
    name: "Seychelles",
    countryCode: "248",
  },
  sl: {
    name: "Sierra Leone",
    countryCode: "232",
  },
  sg: {
    name: "Singapore",
    countryCode: "65",
    postalCode: "999999",
  },
  sk: {
    name: "Slovakia",
    countryCode: "421",
  },
  si: {
    name: "Slovenia",
    countryCode: "386",
  },
  sb: {
    name: "Solomon Islands",
    countryCode: "677",
  },
  so: {
    name: "Somalia",
    countryCode: "252",
  },
  za: {
    name: "South Africa",
    countryCode: "27",
  },
  kr: {
    name: "South Korea",
    countryCode: "82",
  },
  es: {
    name: "Spain",
    countryCode: "34",
    postalCode: "99999",
  },
  lk: {
    name: "Sri Lanka",
    countryCode: "94",
  },
  sd: {
    name: "Sudan",
    countryCode: "249",
  },
  sr: {
    name: "Suriname",
    countryCode: "597",
  },
  sz: {
    name: "Swaziland",
    countryCode: "268",
  },
  se: {
    name: "Sweden",
    countryCode: "46",
    postalCode: "99999",
  },
  ch: {
    name: "Switzerland",
    countryCode: "41",
    postalCode: "9",
  },
  sy: {
    name: "Syria",
    countryCode: "963",
  },
  tw: {
    name: "Taiwan",
    countryCode: "886",
  },
  tj: {
    name: "Tajikistan",
    countryCode: "992",
  },
  tz: {
    name: "Tanzania",
    countryCode: "255",
  },
  th: {
    name: "Thailand",
    countryCode: "66",
    postalCode: "99999",
  },
  tl: {
    name: "Timorleste",
    countryCode: "670",
  },
  tg: {
    name: "Togo",
    countryCode: "228",
  },
  tk: {
    name: "Tokelau",
    countryCode: "690",
  },
  to: {
    name: "Tonga",
    countryCode: "676",
  },
  tt: {
    name: "Trinidad",
    countryCode: "1868",
  },
  tn: {
    name: "Tunisia",
    countryCode: "216",
  },
  tr: {
    name: "Turkey",
    countryCode: "90",
  },
  tm: {
    name: "Turkmenistan",
    countryCode: "993",
  },
  tv: {
    name: "Tuvalu",
    countryCode: "688",
  },
  ae: {
    name: "U.A.E.",
    countryCode: "971",
    alias: "United Arab Emirates",
  },
  ug: {
    name: "Uganda",
    countryCode: "256",
  },
  ua: {
    name: "Ukraine",
    countryCode: "380",
  },
  gb: {
    name: "United Kingdom",
    countryCode: "44",
    alias: "uk",
    postalCode: "*******",
  },
  uy: {
    name: "Uruguay",
    countryCode: "598",
  },
  vi: {
    name: "US Virgin Islands",
    countryCode: "1340",
  },
  uz: {
    name: "Uzbekistan",
    countryCode: "998",
  },
  vu: {
    name: "Vanuatu",
    countryCode: "678",
  },
  va: {
    name: "Vatican City",
    countryCode: "39",
  },
  ve: {
    name: "Venezuela",
    countryCode: "58",
  },
  vn: {
    name: "Vietnam",
    countryCode: "84",
  },
  wf: {
    name: "Wallis and Futuna",
    countryCode: "681",
  },
  ye: {
    name: "Yemen",
    countryCode: "967",
  },
  zm: {
    name: "Zambia",
    countryCode: "260",
  },
  zw: {
    name: "Zimbabwe",
    countryCode: "263",
  },
};
