export const internalSurveyData = [
  {
    id: 653,
    type: "rating",
    value: 0,
  },
  {
    id: 123,
    order: 1,
    label: "1. How was your stream's audio/video quality?",
    type: "radio",
    options: [
      {
        id: 1,
        title: "Great",
        selected: false,
      },
      {
        id: 2,
        title: "Good",
        selected: false,
      },
      {
        id: 3,
        title: "Okay",
        selected: false,
      },
      {
        id: 4,
        title: "Poor",
        selected: false,
      },
    ],
  },
  {
    id: 123,
    order: 2,
    label: "2. How did the chat work for you?",
    type: "radio",
    options: [
      {
        id: 1,
        title: "Great",
        selected: false,
      },
      {
        id: 2,
        title: "Good",
        selected: false,
      },
      {
        id: 3,
        title: "Okay",
        selected: false,
      },
      {
        id: 4,
        title: "Poor",
        selected: false,
      },
      {
        id: 5,
        title: "I did not use the chat",
        selected: false,
      },
    ],
  },
  {
    id: 123,
    order: 3,
    label: "3. How did the encore voting work for you?",
    type: "radio",
    options: [
      {
        id: 1,
        title: "Great",
        selected: false,
      },
      {
        id: 2,
        title: "Good",
        selected: false,
      },
      {
        id: 3,
        title: "Okay",
        selected: false,
      },
      {
        id: 4,
        title: "Poor",
        selected: false,
      },
      {
        id: 5,
        title: "I did not use the encore voting",
        selected: false,
      },
    ],
  },
  {
    id: 789,
    order: 4,
    label: "4. Tell us about your show experience!",
    type: "textarea",
    placeholder: "",
    value: "",
  },
];
