import React from "react";
import styled from "styled-components";
import { GridRow, ButtonPrimary, Logo, GridColumn, P, Icon } from "notes";

export const Requests = ({ onClick, content, buttonText, ...props }) => {
  return (
    <GridColumn yStart {...props}>
      <GridRow xCenter>
        <STSLogo name="SetTheSetDefault" />
        <VoteText>{content}</VoteText>
      </GridRow>

      <STSButton onClick={onClick}>
        {buttonText} <Icon tertiary name="Next" />
      </STSButton>
    </GridColumn>
  );
};

const STSLogo = styled(Logo)`
  flex-shrink: 0;
  margin-right: 12px;
  width: 24px;
  height: 24px;
`;

const VoteText = styled(P)`
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 16px;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 14px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 13px;
  }
`;

const STSButton = styled(ButtonPrimary)`
  background-color: ${(props) => props.theme.palette.teal.primary};
  svg {
    margin-left: 12px;
    path {
      fill: #fff;
    }
  }
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.palette.teal.darker};
  }
`;
