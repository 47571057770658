import React, { useContext } from "react";
import styled from "styled-components";
import { GridRow, H3 } from "notes";
import { TimeContext } from "Components";
import { Duration } from "luxon";

export const Countdown = ({ startDate }) => {
  const { time: currentTime } = useContext(TimeContext);

  const timeToStart = Duration.fromMillis(startDate - currentTime).shiftTo(
    "days",
    "hours",
    "minutes",
    "seconds",
    "milliseconds"
  );

  if (startDate < currentTime) {
    //TODO: Make this into a watch now action
    return (
      <Container>
        <H3>Your Show is Live Now!</H3>
      </Container>
    );
  }

  return (
    <Container>
      <H3>Next Show In:</H3>
      {timeToStart.days > 0 && (
        <H3>
          {timeToStart.days} Day{timeToStart.days > 1 ? "s" : ""}
        </H3>
      )}
      {timeToStart.hours > 0 && (
        <H3>
          {timeToStart.hours} Hr{timeToStart.hours > 1 ? "s" : ""}
        </H3>
      )}
      {timeToStart.minutes > 0 && (
        <H3>
          {timeToStart.minutes} Min{timeToStart.minutes > 1 ? "s" : ""}
        </H3>
      )}
      {timeToStart.days < 1 && (
        <H3>
          {timeToStart.seconds} Second{timeToStart.seconds > 1 ? "s" : ""}
        </H3>
      )}
    </Container>
  );
};

const Container = styled(GridRow)`
  h3 + h3 {
    margin-left: 12px;
  }
`;
