import React from "react";
import styled from "styled-components";
import { GridColumn } from "notes";

export const Description = ({ content, ...props }) => {
  return (
    <DescriptionStyle {...props}>
      {content.length > 158 ? content.substr(0, 158) + "..." : content}
    </DescriptionStyle>
  );
};

const DescriptionStyle = styled(GridColumn)`
  text-align: left;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-top: 4px;
    font-size: 14px;
    line-height: 18px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    margin-top: 0.613vw;
    line-height: 2.071vw;
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    margin-top: 8px;
    line-height: 27px;
  }
`;
