import React from "react";
import styled from "styled-components";
import { GridColumn, Label, RadioLarge, H3, P, ButtonPrimary } from "notes";
import { formatCurrency, Payment, FormContainer } from "Components";

export const Details = ({ tickets, setTickets, onSuccess }) => {
  const handleSelect = (ticketId) => {
    let newTickets = tickets.map((ticket) => {
      return { ...ticket, selected: false };
    });
    newTickets.find(({ id }) => id === ticketId).selected = true;
    setTickets(newTickets);
  };

  const selectedTicket = tickets.find((tic) => tic.selected);

  return (
    <Wrapper>
      <Container>
        <H3>Purchase Details</H3>
        <P>Please select the ticket you would like to purchase.</P>

        <Form>
          <Label>Ticket Type</Label>
          {tickets.map(
            ({ id, label, amount, description, selected, disabled }, index) => {
              return (
                <Radio
                  key={index}
                  disabled={disabled}
                  title={`${label} (${formatCurrency(amount)})`}
                  checked={!!selected}
                  onChange={() => handleSelect(id)}
                  variant={!description}
                >
                  {description}
                </Radio>
              );
            }
          )}
        </Form>
      </Container>
      <PaymentWrapper isFree={selectedTicket.amount === 0}>
        <PaymentStyle
          items={{ ...selectedTicket, quantity: 1 }}
          onComplete={onSuccess}
          billingText={
            <P>
              Enter the payment information you would like to use for this
              purchase. Your credit card will be securely stored for use during
              the show. You may replace or remove your credit card at any time
              on the billing screen.
            </P>
          }
        />
      </PaymentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(GridColumn)`
  width: 100%;
`;

const PaymentWrapper = styled(GridColumn)`
  background-color: ${(props) => (props.isFree ? "transparent" : "#f6f9fc")};
  margin-left: -24px;
  margin-right: -24px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const PaymentStyle = styled(Payment)`
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  max-width: 392px;
`;

const Container = styled(FormContainer)`
  max-width: 392px;
  margin: 0 auto;
  margin-bottom: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const Form = styled(GridColumn)`
  width: 100%;
  ${Label} {
    margin-top: 16px;
    margin-bottom: 4px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    ${Label} {
      margin-top: 0;
    }
  }
`;

const Radio = styled(RadioLarge)`
  margin: ${(props) => (props.variant ? "7px 0" : 0)};
`;
