import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { Api } from "modules";
import {
  HeaderSecondary,
  FooterBlock,
  ShowRating,
  Survey,
  ModalContext,
} from "Components";
import {
  GridColumn,
  GridRow,
  Icon,
  Input,
  ButtonPrimary,
  withDefaultMedia,
  Modal,
  TertiaryMenu,
  H4,
} from "notes";
import { Chat } from "./Chat";
import { Leaderboard, Vote } from "../";
import { ArtistDescription } from "Routes/Event/Components/PreShow/Blocks";
import { auto } from "@popperjs/core";
import Vimeo from "@u-wave/react-vimeo";
import { useHistory } from "react-router-dom";

const views = [
  { icon: <Icon form name="Message" />, label: "Chat" },
  { icon: <Icon form name="SetList" />, label: "Requests" },
  { icon: <Icon form name="Trophy" />, label: "Ranking" },
];

export const Show = withDefaultMedia(
  ({ matches, show, postShow, onDonate }) => {
    const [hideChat, setHideChat] = useState(false);
    const [view, setView] = useState(views[0]);
    const [ratingModal, setRatingModal] = useState(false);
    const [initialRating, setInitialRating] = useState(0);
    const [amount, setAmount] = useState(5);
    const containerRef = useRef(null);
    const handleRating = (value) => {
      setInitialRating(value);
      setRatingModal(true);
    };
    const handleReport = async (eventId, chatId) => {
      Api.flagChat(eventId, chatId)
        .then(({ data }) => {
          console.log("Report success");
        })
        .catch((error) => {
          console.error(`Got error: ${error.code} - ${error.message}`);
        });
    };
    const { toggleReportUser } = useContext(ModalContext);
    const handleReportUser = (chat, eventId) => {
      toggleReportUser({
        open: true,
        content: chat.content,
        onReport: () => handleReport(eventId, chat.key),
      });
    };
    return (
      <Wrapper>
        {matches.large && <HeaderSecondary inverse />}
        <Container>
          <VideoActionContainer>
            <LeftVideo extend={hideChat} ref={containerRef}>
              <VideoContainer>
                <VideoInner>
                  {!postShow ? (
                    <Vimeo
                      key={show.videoId}
                      video={show.videoId}
                      autoplay
                      responsive
                    />
                  ) : (
                    <ShowRating
                      show={show}
                      value={initialRating}
                      setValue={(value) => handleRating(value)}
                    />
                  )}
                </VideoInner>
              </VideoContainer>
              <Actions yCenter>
                <Support columns={4}>
                  <SupportInput>
                    <Input
                      style={{ minWidth: "104px" }}
                      value={amount}
                      onChange={setAmount}
                      leftIcon={<Icon form name="USD" />}
                      placeholder=""
                    />
                  </SupportInput>
                  <SupportButton onClick={() => onDonate(amount)}>
                    Show Your Support
                  </SupportButton>
                </Support>
                <EncoreButton onClick={() => setView(views[1])}>
                  <Icon form name="SetList" />
                  Vote For Encore
                </EncoreButton>
                <ExpandButton
                  onClick={() => setHideChat(!hideChat)}
                  name={hideChat ? "DefaultPlayer" : "LargePlayer"}
                />
              </Actions>
            </LeftVideo>
            <SideBarContainer hide={matches.large && hideChat}>
              <MobileContainer>
                <div
                  style={{
                    display: view === views[0] ? "block" : "none",
                    flexGrow: 1,
                    overflowY: "auto",
                  }}
                >
                  <Chat
                    id={show.id}
                    flaggedOnClick={(chat) => handleReportUser(chat, show.id)}
                    votingClickHandler={() => setView(views[1])}
                    leaderboardClickHandler={() => setView(views[2])}
                  />
                </div>
                {view === views[1] && <Vote />}
                {view === views[2] && <Leaderboard id={show.id} />}
              </MobileContainer>
              <MobileMenu options={views} setView={setView} view={view} />
            </SideBarContainer>
          </VideoActionContainer>
          <ArtistInfo extend={hideChat}>
            <ArtistDescription showView show={show} />
          </ArtistInfo>
        </Container>
        {matches.large && <FooterBlock secondary />}
        <ModalStyle size="full" open={ratingModal} setOpen={setRatingModal}>
          <ScrollContainer id="scroll-container">
            <CloseIcon
              platform
              name="Close"
              onClick={() => setRatingModal(false)}
            />
            <Survey
              show={show}
              initialRating={initialRating}
              onSuccess={() => setRatingModal(false)}
            />
          </ScrollContainer>
        </ModalStyle>
      </Wrapper>
    );
  }
);

const LeftVideo = styled.div`
  flex-grow: 0;
  transition: width ease 200ms;
  @media only screen and ${(props) => props.theme.media.large} {
    width: calc(100% - 416px);
    flex-grow: 1;
    ${(props) =>
      props.extend &&
      `
      width: calc(100% - 0px)
    `}
  }
`;

const MobileContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 56px;
  display: flex;
  overflow-y: auto;
  @media only screen and ${(props) => props.theme.media.large} {
    position: static;
    height: 100%;
  }
`;

const menuItems = [
  {
    key: "home",
    icon: <Icon tertiary name="Exit" />,
    label: "Back to All Events",
  },
];

const MobileMenu = withDefaultMedia(({ options, view, setView, matches }) => {
  const history = useHistory();
  const handleMenu = (key) => {
    //right now only 1 key
    history.push("/");
  };
  return (
    <>
      <MenuSpacer />
      <MenuContainer>
        {options.map((option) => (
          <MenuOption
            noShrink
            yCenter
            active={view === option}
            onClick={() => setView(option)}
          >
            {option.icon} <H4>{option.label}</H4>
          </MenuOption>
        ))}
        {!matches.large && (
          <TertiaryMenu
            offset="0, 0"
            items={menuItems}
            onSelect={(key) => handleMenu(key)}
            trigger={(props) => (
              <MenuOption {...props}>
                <MoreIcon platform name="More" />
                <H4>More</H4>
              </MenuOption>
            )}
          />
        )}
      </MenuContainer>
    </>
  );
});

const MenuSpacer = styled.div`
  width: 100%;
  height: 53px;
  flex-shrink: 0;
  order: 3;
  @media only screen and ${(props) => props.theme.media.large} {
    display: none;
  }
`;

const MenuContainer = styled(GridRow).attrs({ as: "div" })`
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid
    ${(props) =>
      props.theme.dark
        ? props.theme.palette.gray.primary
        : props.theme.palette.gray.lightest};
  position: fixed;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  height: 53px;
  padding-top: 8px;
  flex-shrink: 0;
  background-color: ${(props) =>
    props.theme.dark ? props.theme.palette.black : "#FAFAFA"};
  z-index: 10;
  @media only screen and ${(props) => props.theme.media.large} {
    position: static;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    background-color: #f5f5f5;
    border-top: 1px solid ${(props) => props.theme.palette.gray.lighter};
    border-bottom: none;
    justify-content: space-around;
    height: 56px;
    padding-top: 9px;
  }
`;

const MenuOption = styled(GridRow).attrs({ as: "div" })`
  display: flex;
  box-sizing: border-box;
  border-bottom: 4px solid
    ${(props) =>
      props.active
        ? props.theme.dark
          ? "#ffffff"
          : props.theme.palette.purple.primary
        : props.theme.dark
        ? props.theme.palette.black
        : "#FAFAFA"};
  height: 44px;
  padding: 0 8px;
  cursor: pointer;
  & + & {
    margin-left: 24px;
  }
  ${H4} {
    color: ${(props) =>
      props.active
        ? props.theme.dark
          ? "#ffffff"
          : props.theme.palette.purple.primary
        : props.theme.dark
        ? props.theme.palette.gray.lighter
        : props.theme.palette.black};
    font-weight: 600;
  }
  svg {
    width: 14px;
    height: 14px;
    margin-right: 9px;
    path {
      fill: ${(props) =>
        props.active
          ? props.theme.dark
            ? "#ffffff"
            : props.theme.palette.purple.primary
          : props.theme.dark
          ? props.theme.palette.gray.lighter
          : props.theme.palette.black};
    }
  }

  @media only screen and ${(props) => props.theme.media.large} {
    &:hover {
      background-color: ${(props) =>
        props.active
          ? props.theme.dark
            ? props.theme.palette.black
            : "#FAFAFA"
          : props.theme.dark
          ? props.theme.palette.purple.primary
          : "#F5F5F5"};
      ${H4} {
        color: ${(props) =>
          props.active
            ? props.theme.dark
              ? "#FFFFFF"
              : props.theme.palette.purple.primary
            : props.theme.dark
            ? "#FFFFFF"
            : props.theme.palette.black};
      }
      svg {
        path {
          fill: ${(props) =>
            props.active
              ? props.theme.dark
                ? "#FFFFFF"
                : props.theme.palette.purple.primary
              : props.theme.dark
              ? "#FFFFFF"
              : props.theme.palette.black};
        }
      }
    }
  }

  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
    border-bottom: 0;
    & + & {
      margin-left: 0;
    }
    svg {
      width: 22px;
      height: 22px;
      margin-right: 0;
      margin-bottom: 4px;
      path {
        fill: ${(props) =>
          props.active
            ? props.theme.palette.black
            : props.theme.palette.gray.lighter};
      }
    }
    ${H4} {
      font-size: 10px;
      line-height: 16px;
      font-weight: 600;
      color: ${(props) =>
        props.active
          ? props.theme.palette.black
          : props.theme.palette.gray.lighter};
    }
  }
`;

const MoreIcon = styled(Icon)`
  && {
    width: 24px;
    height: 24px;
    margin-bottom: 2px;
  }
`;

const Wrapper = styled(GridColumn)`
  background-color: ${(props) => (props.theme.dark ? "#000" : "#fff")};
  transition: background-color ease 2000ms;
`;

const SupportButton = styled(ButtonPrimary)`
  margin-left: 8px;
  width: 280px;
  @media only screen and (max-width: 1182px) {
    width: 222px;
  }
`;

const Container = styled(GridColumn)`
  align-items: flex-start;
  margin: 0 40px;
  padding-bottom: 90px;
  position: relative;
  flex-direction: column;
  @media only screen and (max-width: 1182px) {
    padding: 0;
    margin: 0;
    justify-content: flex-start;
    height: 100vh;
  }
`;

const VideoActionContainer = styled(GridColumn)`
  width: 100%;
  order: 1;
  display: flex;
  flex-grow: 1;
  max-height: 100%;
  @media only screen and (max-width: 1182px) {
    padding: 0;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    display: block;
    position: relative;
  }
`;

const ArtistInfo = styled(GridColumn)`
  padding-right: ${(props) => (props.extend ? 0 : "416px")};
  order: 3;
  display: none;
  @media only screen and (max-width: 1182px) {
    padding: 0 24px 40px 24px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    display: block;
  }
`;

const VideoContainer = styled(GridColumn)`
  padding-bottom: 56.4%;
  width: 100%;
  position: relative;
`;

const VideoInner = styled(GridColumn)`
  background-color: #222;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .vimeo {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .vimeo-image {
      background-size: cover;
    }
    .vimeo-loading {
      display: none;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
    .vimeo-play-button {
    }
  }
`;

const SupportInput = styled(GridRow)`
  width: 104px;
`;

const Support = styled(GridRow)`
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  @media only screen and (max-width: 1182px) {
    padding: 0 24px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    justify-content: flex-start;
  }
`;

const ExpandButton = styled(Icon)`
  cursor: pointer;
  width: 40px;
  height: 40px;
`;

const SideBarContainer = styled(GridColumn)`
  border: 1px solid
    ${(props) =>
      props.theme.dark
        ? props.theme.palette.gray.primary
        : props.theme.palette.gray.lightest};
  background-color: ${(props) => (props.theme.dark ? "#222222" : "#fafafa")};
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.hide ? "none" : "flex")};
  @media only screen and (max-width: 1182px) {
    position: relative;
    order: 2;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: column-reverse;
    justify-content: flex-end;
    top: 0;
    right: 0;
    position: absolute;
    width: 392px;
  }
`;

const EncoreButton = styled(ButtonPrimary)`
  background-color: ${(props) => props.theme.palette.teal.primary};
  color: #fff;
  margin-right: 24px;
  &:hover {
    background-color: ${(props) => props.theme.palette.teal.darker};
    color: #fff;
  }
  svg {
    margin-right: 9px;
    path {
      fill: #fff;
    }
  }
`;

const Actions = styled(GridRow)`
  border-bottom: 2px solid
    ${(props) =>
      props.theme.palette.gray[props.theme.dark ? "primary" : "lightest"]};
  height: 72px;
  justify-content: space-between;
  @media only screen and ${(props) => props.theme.media.large} {
    padding-right: 24px;
  }
  @media only screen and (max-width: 1182px) {
    ${EncoreButton} {
      display: none;
    }
    ${ExpandButton} {
      display: none;
    }
  }
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 32px;
  z-index: 2;
`;

const ModalStyle = styled(Modal)`
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: inherit;
  & > svg {
    display: none;
  }
`;

const ScrollContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;
