import React from "react";
import styled from "styled-components";
import { GridRow, P, Logo, Icon, withDefaultMedia } from "notes";
import { Link } from "Components";

export const SetList = withDefaultMedia(({ link, matches }) => {
  return (
    <Container xCenter yCenter>
      <SetListLogo name="SetTheSetDefault" />
      <P>
        This show is currently accepting song requests!{" "}
        <SetListLink target="_blank" href={link} color="teal">
          {matches.small ? "Submit Yours" : "Submit Yours on Set The Set"}
          {!matches.small && <NextIcon tertiary name="Next" />}
        </SetListLink>
      </P>
    </Container>
  );
});

const Container = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.teal.lightest};
  border: 1px solid ${(props) => props.theme.palette.teal.lighter};
  width: 100%;
  height: 72px;
  margin: 0 auto;
  margin-top: 56px;
  max-width: 1016px;
  padding: 12px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 40px;
  }
`;

const SetListLogo = styled(Logo)`
  flex-shrink: 0;
  margin-right: 16px;
  width: 32px;
  height: 32px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }
`;

const NextIcon = styled(Icon)`
  position: absolute;
  right: -20px;
  margin-left: 4px;
  path {
    fill: ${(props) => props.theme.palette.teal.primary};
  }
`;

const SetListLink = styled(Link)`
  margin-right: 20px;
  position: relative;
  &:hover,
  &:focus {
    ${NextIcon} {
      path {
        fill: ${(props) => props.theme.palette.teal.darker};
      }
    }
  }
`;
