import React, { useContext } from "react";
import styled from "styled-components";
import { Modal, Icon } from "notes";
import { Musicians, FormProvider, ModalContext } from "Components";

export const MusicianModal = ({ open, setOpen }) => {
  const { toggleMusicianSuccess } = useContext(ModalContext);
  const handleSuccess = (success) => {
    setOpen(false);
    success && toggleMusicianSuccess(true);
  };

  return (
    <ModalStyle size="full" open={open} setOpen={setOpen}>
      <ScrollContainer id="scroll-container">
        <CloseIcon platform name="Close" onClick={() => handleSuccess(false)} />
        <FormProvider>
          <Musicians onSuccess={() => handleSuccess(true)} />
        </FormProvider>
      </ScrollContainer>
    </ModalStyle>
  );
};

const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 32px;
  z-index: 2;
`;

const ModalStyle = styled(Modal)`
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: inherit;
  & > svg {
    display: none;
  }
`;

const ScrollContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;
