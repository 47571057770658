import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  GridRow,
  H4,
  ButtonSecondarySmall,
  Link,
  Icon,
  TertiaryMenu,
} from "notes";
import { ButtonSignUp, StoreContext, ModalContext } from "Components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { logout } from "services";

const StyledIcon = styled(Icon)`
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
`;

const menuItems = [
  {
    key: "account",
    icon: <StyledIcon tertiary name="User" />,
    label: "My Account",
  },
  {
    key: "signout",
    icon: <StyledIcon tertiary name="Exit" />,
    label: "Sign Out",
  },
];

export const HeaderActions = ({
  primary,
  inverse,
  onLogin,
  onSignup,
  onHelp,
}) => {
  const { Store, user } = useContext(StoreContext);
  const { toggleLoginModal, toggleSignUpModal } = useContext(ModalContext);
  const [username, loading, error] = useDocumentData(
    Store.firestore().doc(`usernames/${user?.uid}`)
  );
  const history = useHistory();

  if (loading) {
    return null;
  }

  const handleLogout = async () => {
    try {
      await logout();
      history.push("/");
    } catch (err) {
      console.log(err);
    }
  };

  const handleMenu = (key) => {
    switch (key) {
      case "signout":
        return handleLogout();
      case "account":
        return history.push("/account");
      default:
        break;
    }
  };

  return (
    <Actions xEnd inverse={inverse}>
      <DesktopButtons>
        {/* <HowItWorksLink onClick={onHelp}>How It Works</HowItWorksLink> */}
        {!user && (
          <>
            <LoginButton onClick={toggleLoginModal}>Log In</LoginButton>
            {!primary && (
              <SignUpDesktop onClick={toggleSignUpModal}>Sign Up</SignUpDesktop>
            )}
          </>
        )}
      </DesktopButtons>
      {!user && !primary && (
        <MobileButtons xEnd>
          <SignUpMobile onClick={() => history.push("/signup")}>
            Sign Up
          </SignUpMobile>
          <UserDivider>|</UserDivider>
          <LoginMobile onClick={() => history.push("/login")}>
            Log In
          </LoginMobile>
          <HowItWorksMobile xCenter yCenter onClick={onHelp}>
            <HowItWorksIcon
              onClick={() => history.push("/contact")}
              indicator
              name="Questions"
            />
          </HowItWorksMobile>
        </MobileButtons>
      )}
      {username && user && (
        <TertiaryMenu
          offset="0, 10"
          items={menuItems}
          onSelect={(key) => handleMenu(key)}
          trigger={(props) => (
            <UserButton yCenter {...props}>
              <UserIcon xCenter yCenter>
                {username.value.charAt(0)}
              </UserIcon>
              <UserName>{username.value}</UserName>
              <Caret indicator name="Dropdown" />
            </UserButton>
          )}
        />
      )}
    </Actions>
  );
};

const UserButton = styled(GridRow).attrs({ as: "div" })`
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0 12px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0;
  }
`;

const UserIcon = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.orange.primary};
  border-radius: 16px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin-right: 16px;
  text-transform: uppercase;
  width: 32px;
  height: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const Caret = styled(Icon)`
  path {
    fill: #fff;
  }
`;

const UserName = styled(H4)`
  color: #fff;
  font-weight: 600;
  margin-right: 12px;
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const DesktopButtons = styled(GridRow)`
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const MobileButtons = styled(GridRow)`
  display: none;
  @media only screen and ${(props) => props.theme.media.small} {
    display: flex;
  }
`;

const HowItWorksLink = styled(Link)`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  height: 40px;
  margin-right: 24px;
  padding: 0 8px;
  &:hover,
  &:focus {
    color: #fff;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 12px;
    margin-right: 0;
  }
`;

const HowItWorksMobile = styled(GridRow)`
  height: 40px;
  width: 38px;
`;

const HowItWorksIcon = styled(Icon)`
  path {
    fill: #fff;
  }
  height: 16px;
  width: 16px;
`;

const LoginButton = styled(ButtonSecondarySmall)`
  border-color: #fff;
  color: #fff;
  &:hover,
  &:focus {
    background-color: #fff;
    border-color: "#fff";
    color: ${(props) => props.theme.colors.text};
  }
`;

const SignUpDesktop = styled(ButtonSignUp)`
  margin-left: 24px;
`;

const LoginMobile = styled(Link)`
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  padding: 0 8.5px;
  &:hover,
  &:focus {
    color: #fff;
  }
`;

const SignUpMobile = styled(LoginMobile)``;

const UserDivider = styled(LoginMobile)`
  padding: 0;
`;

const Actions = styled(GridRow)`
  height: 40px;
  ${HowItWorksLink} {
    ${(props) =>
      props.inverse &&
      !props.theme.dark &&
      `color: ${props.theme.palette.black};`};
  }
  ${UserName} {
    ${(props) =>
      props.inverse &&
      !props.theme.dark &&
      `color: ${props.theme.palette.black};`};
  }
  ${Caret} {
    ${(props) =>
      props.inverse &&
      !props.theme.dark &&
      `path {
      fill: ${props.theme.palette.black};
    }`}
  }
  ${LoginButton} {
    ${(props) =>
      props.inverse &&
      !props.theme.dark &&
      `color: ${props.theme.palette.black}; border: 2px solid ${props.theme.palette.gray.primary};`}
  }
`;
