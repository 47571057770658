import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { GridColumn, GridRow, H2 } from "notes";
import {
  HeaderBlock,
  SocialBlock,
  MusicianBlock,
  RequestsBlock,
} from "./Blocks";
import { FooterBlock, StoreContext, Ticket, formatShow } from "Components";
import { useCollection } from "react-firebase-hooks/firestore";
import { useHistory } from "react-router-dom";

export const Landing = () => {
  const { Store, tickets } = useContext(StoreContext);
  const history = useHistory();
  const srvTime = useMemo(() => Store.firestore.Timestamp.now(), []);

  const [collection, loading, error] = useCollection(
    Store.firestore()
      .collection("events")
      .where("active", "==", true)
      .where("endDate", ">", srvTime)
      .orderBy("endDate")
      .orderBy("priority")
  );

  if (loading) {
    return null;
  }

  let requestShows = collection?.docs
    .map((event) => formatShow(event.data()))
    .filter(({ requests }) => requests);

  const shows =
    collection?.docs.map((show) => {
      const id = show.id;
      const data = show.data();
      const purchased = !!tickets?.find(
        (tic) => tic?.paymentItem?.eventId === id
      );
      return formatShow({ id, purchased: purchased, ...data });
    }) || [];

  console.log(shows);

  const handleAction = (eventId) => {
    Store.analytics().logEvent("select_content", {
      content_type: "event",
      content_id: eventId,
    });
    history.push(`/event/${eventId}`);
  };

  const purchasedTickets = shows.filter(({ purchased }) => purchased);
  const unPurchasedTickets = shows.filter(({ purchased }) => !purchased);

  return (
    <Wrapper>
      <HeaderBlock nextShows={purchasedTickets} />
      <TicketWrapper>
        {purchasedTickets &&
          purchasedTickets.map((event) => (
            <Ticket
              key={event.id}
              show={event}
              purchased={event.purchased}
              onActionClick={() => handleAction(event.id)}
            />
          ))}
        {purchasedTickets.length !== 0 && unPurchasedTickets.length !== 0 && (
          <>
            <PageDivider />
            <SecondaryTitle>You Might Also Like...</SecondaryTitle>
          </>
        )}
        {unPurchasedTickets &&
          unPurchasedTickets.map((event) => (
            <Ticket
              key={event.id}
              show={event}
              purchased={event.purchased}
              onActionClick={() => handleAction(event.id)}
            />
          ))}
      </TicketWrapper>
      <RequestsBlock shows={requestShows} />
      <MusicianBlock />
      <SocialBlock />
      <FooterBlock />
    </Wrapper>
  );
};

const Wrapper = styled(GridColumn)`
  background-color: #222;
`;

const TicketWrapper = styled(GridColumn)`
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px;
  }
`;

const PageDivider = styled(GridRow)`
  height: 16px;
  width: 100%;
  background-color: ${(props) => props.theme.palette.gray.primary};
  box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.5);
  margin: 72px 0 56px 0;
`;

const SecondaryTitle = styled(H2)`
  color: #fff;
  font-size: 4.294vw;
  font-weight: 700;
  line-height: 5.521vw;
  margin-bottom: 72px;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 40px;
    line-height: 44px;
    text-align: left;
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    font-size: 56px;
    line-height: 72px;
    text-align: center;
  }
`;
