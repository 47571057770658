import React from "react";
import styled from "styled-components";
import {
  Modal,
  GridColumn,
  GridRow,
  H3,
  P,
  Footnote,
  ButtonPrimary,
  ButtonSecondary,
} from "notes";

const exampleMessage =
  "Alii autem, quibus ego cum soluta nobis est consecutus? laudem et benivole collegisti, nec voluptas assumenda est, omnis dolor sit id.";

export const ReportUser = ({
  open,
  setOpen,
  message = exampleMessage,
  onReport,
  ...props
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const handleReport = () => {
    onReport();
    setOpen(false);
  };
  return (
    <ModalStyle size="small" open={open} setOpen={handleClose}>
      <Container xCenter>
        <H3>Report Message</H3>
        <Footnote>Message: {message}</Footnote>
        <P>Are you sure you want to report this message?</P>
        <ButtonContainer yEnd>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <ReportButton onClick={handleReport}>Report</ReportButton>
        </ButtonContainer>
      </Container>
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  margin-top: 8.589vw;
  min-height: 282px;
  svg {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 0;
    max-width: 296px;
    padding: 24px;
  }
`;

const Container = styled(GridColumn)`
  height: 100%;
  ${H3} {
    margin-bottom: 8px;
  }
  ${Footnote} {
    margin-bottom: 24px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    ${H3} {
      font-size: 17px;
      line-height: 22px;
    }
    ${Footnote} {
      margin-bottom: 8px;
    }
  }
`;

const ButtonContainer = styled(GridRow)`
  margin-top: 24px;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
    align-items: stretch;
  }
`;

const CancelButton = styled(ButtonSecondary)`
  @media only screen and ${(props) => props.theme.media.small} {
    order: 2;
  }
`;

const ReportButton = styled(ButtonPrimary)`
  @media only screen and ${(props) => props.theme.media.small} {
    order: 1;
    margin-bottom: 8px;
  }
`;
