import styled from "styled-components";
import { ButtonPrimary } from "notes";

export const ButtonSignUp = styled(ButtonPrimary)`
  background-color: ${(props) => props.theme.colors.orange};
  color: ${(props) => props.theme.colors.white};

  &:hover,
  &:focus {
    background: radial-gradient(circle, #ff8c00 0%, #cc4e00 100%);
  }
`;
