import React from "react";
import styled from "styled-components";
import { H3, H1, GridRow } from "notes";
import { Countdown } from "./Countdown";

export const Messaging = ({ user, shows }) => {
  const nextPurchasedShow = shows?.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  if (nextPurchasedShow.length > 0) {
    return (
      <>
        <H1>
          You have {nextPurchasedShow.length} Upcoming Show
          {nextPurchasedShow.length !== 1 && "s"}!
        </H1>
        <Countdown startDate={nextPurchasedShow[0].start} />
      </>
    );
  }
  if (user) {
    return (
      <>
        <H1>Shows on Set.Live</H1>
        <H3>Get your front row tickets now!</H3>
      </>
    );
  }
  return (
    <>
      <H1>
        Live Concerts Right From
        <br />
        (Your Couch)
      </H1>
      <H3>
        (Bed, Office. Bathroom?) <Light>We don’t judge.</Light>
      </H3>
    </>
  );
};

const Light = styled.span`
  color: ${(props) => props.theme.colors.disabledText};
  font-weight: 300;
`;

const Container = styled(GridRow)`
  h3 + h3 {
    margin-left: 12px;
  }
`;
