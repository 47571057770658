import React from "react";
import styled from "styled-components";
import { GridRow, Icon } from "notes";

export const PurchasedBadge = ({ text, ...props }) => {
  return (
    <PurchasePill xCenter yCenter {...props}>
      <TicketIcon tertiary name="Ticket" /> {text}
    </PurchasePill>
  );
};

const PurchasePill = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.purple.darker};
  border-radius: 24.5px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 8px;
  height: 33px;
  width: 179px;
`;

const TicketIcon = styled(Icon)`
  margin-right: 8px;
  width: 16px;
  height: 16px;
  path {
    fill: #ffffff;
  }
`;
