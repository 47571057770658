import React, { useContext } from "react";
import styled from "styled-components";
import { BlockBackgroundCurve } from "./images";
import { Section, SplashBlock, ModalContext } from "Components";
import { ButtonSecondarySmall, GridColumn, H1, H3 } from "notes";

const keyboardImageDesktop =
  "https://cdn1.musicaudience.info/ff2451387d513e9138ad3ce9d805f887.jpeg";
const keyboardImageMobile =
  "https://cdn1.musicaudience.info/ae5694642fbc70b3ee81d1e398ed2a03.jpeg";

export const MusicianBlock = () => {
  const { toggleMusicianForm } = useContext(ModalContext);
  return (
    <BlockDefault
      desktopImage={keyboardImageDesktop}
      mobileImage={keyboardImageMobile}
    >
      <SectionStyle yCenter>
        <GridColumn style={{ maxWidth: "392px" }} columns={4}>
          <H1>Are You a Musician?</H1>
          <H3>Hold Your Next Show on Set.Live!</H3>
          <GetStartedButton onClick={() => toggleMusicianForm(true)}>
            Get Started
          </GetStartedButton>
        </GridColumn>
      </SectionStyle>
      <BottomCurve />
    </BlockDefault>
  );
};

const BlockDefault = styled(SplashBlock)`
  position: relative;
  z-index: 0;
  h1,
  h3 {
    color: #fff;
    text-align: center;
  }
  h1 {
    font-size: 4.294vw;
    line-height: 5.521vw;
  }
  h3 {
    font-size: 1.917vw;
    line-height: 2.454vw;
    margin-top: 8px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    padding: 56px 24px 89px 24px;
    h1,
    h3 {
      color: #fff;
      text-align: center;
    }
    h1 {
      font-size: 25px;
      line-height: 29px;
    }
    h3 {
      font-size: 17px;
      line-height: 22px;
      margin-top: 4px;
    }
  }

  @media only screen and ${(props) => props.theme.media.extraLarge} {
    padding: 56px 40px 72px 40px;
    h1 {
      font-size: 56px;
      line-height: 72px;
    }
    h3 {
      font-size: 25px;
      line-height: 32px;
      margin-top: 16px;
    }
  }
`;

const SectionStyle = styled(Section)`
  align-items: flex-start;
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: center;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    align-items: center;
  }
`;

const GetStartedButton = styled(ButtonSecondarySmall)`
  border-color: #fff;
  color: #fff;
  margin: 56px auto 0 auto;
  width: 100%;
  &:hover,
  &:focus {
    background-color: #fff;
    border-color: #fff;
    color: ${(props) => props.theme.palette.purple.primary};
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 40px;
  }
`;

const BottomCurve = styled(BlockBackgroundCurve)`
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: auto;
  path {
    fill: #f5f5f5;
  }
`;
