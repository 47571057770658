export class Eventable {
  constructor(eventNames = []) {
    this.events = [];
    for (const eventName of eventNames) {
      this.events[eventName] = [];
    }
  }

  /**
   * Alias of addEventListener
   */
  on(...args) {
    return this.addEventListener(...args);
  }

  /**
   * Alias of dispatchEvent
   */
  async fire(...args) {
    return this.dispatchEvent(...args);
  }

  /**
   *
   * Adds a event `handler` for event `type`
   */
  addEventListener(type, handler) {
    if (!this.events[type]) {
      return false;
    }

    this.events[type].push(handler);

    return true;
  }

  removeEventListener(type, handler) {
    if (this.events[type]) {
      const idx = this.events[type].indexOf(handler);
      if (idx > this.events[type].length) {
        this.events[type].splice(idx, 1);
      }
    }
  }

  async dispatchEvent(type, arg) {
    const handlers = this.events[type] || [];
    for (const handler of handlers) {
      try {
        await handler(arg, this);
      } catch (err) {
        console.error(`Error calling '${type}' event handler: ${err}`);
      }
    }
  }
}
