import React, { createContext, useContext, useState, useEffect } from "react";
import { useObject } from "react-firebase-hooks/database";
import { StoreContext } from "Components";
import { Message } from "notes";

export const MessageContext = createContext();
export const MessageConsumer = MessageContext.Consumer;
export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState({});
  const displayMessage = (options) => {
    setMessage({ open: true, ...options });
  };
  const setOpen = () => {
    setMessage({});
  };
  useEffect(() => {
    let to;
    if (message.timeout) {
      setTimeout(() => setOpen(), message.timeout);
    }
    return () => {
      if (to) {
        clearTimeout(to);
      }
    };
  }, [message]);

  return (
    <MessageContext.Provider value={{ displayMessage }}>
      <Message {...message} setOpen={setOpen} />
      {children}
    </MessageContext.Provider>
  );
};
