import React, { useContext } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { StoreContext, formatCurrency } from "Components";
import { withDefaultMedia } from "notes";
import { TicketLarge, TicketSmall } from "../Ticket";

export const Ticket = withDefaultMedia(
  ({ matches, onPurchase, show, ...props }) => {
    const { Store } = useContext(StoreContext);
    const [tickets, loading, error] = useCollectionOnce(
      Store.firestore()
        .collection("payment_items")
        .where("eventId", "==", show.id)
        .where("type", "==", "ticket")
        .orderBy("amount", "desc")
    );

    if (loading) {
      return null;
    }

    let updatedTickets = tickets.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .reverse();

    let minPrice = updatedTickets.reduce((min, { amount }) => {
      return min && min < amount ? min : amount;
    }, null);

    let maxPrice = updatedTickets.reduce((max, { amount }) => {
      return max && max > amount ? max : amount;
    }, null);

    const priceLabel =
      minPrice === maxPrice
        ? !minPrice
          ? "Free Show"
          : formatCurrency(minPrice)
        : `${formatCurrency(minPrice)} - ${formatCurrency(maxPrice)}`;

    let updatedShow = {
      ...show,
      priceLabel: priceLabel,
      minPrice: minPrice,
      maxPrice: maxPrice,
      paymentItems: updatedTickets,
    };

    let calendarInvite = {
      title: `${show.artist} - ${show.subtitle}`,
      description: show.description,
      location: `https://set.live/event/${show.id}`,
      startTime: new Date(show.start),
      endTime: new Date(show.end),
    };

    return !matches.small ? (
      <TicketLarge
        show={updatedShow}
        calendarEvent={calendarInvite}
        {...props}
      />
    ) : (
      <TicketSmall
        show={updatedShow}
        calendarEvent={calendarInvite}
        {...props}
      />
    );
  }
);
