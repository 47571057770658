import React from "react";
import { Radio, GridColumn } from "notes";
import { FieldMessage } from "Components";

export const RadioField = ({ onChange, value, field, error = {} }) => {
  const handleRadio = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <GridColumn>
      {field.options.map(({ id, title, subtitle }) => (
        <Radio
          key={id}
          checked={value === title}
          onChange={() => handleRadio(title)}
          title={!!subtitle && title}
        >
          {!!subtitle ? subtitle : title}
        </Radio>
      ))}
      <FieldMessage active={!!error.status} content={error.message} />
    </GridColumn>
  );
};
