import React from "react";
import styled from "styled-components";
import { GridRow, GridColumn, H1, H4, Logo } from "notes";
import { Section } from "Components";

const socialItems = {
  text: {
    title: "Stay In The Know",
    subtitle: "Hear about all the latest concerts by following Set.Live!",
  },
  socialLinks: [
    {
      link: "https://www.facebook.com/settheset/",
      icon: "facebook",
    },
    {
      link: "https://twitter.com/settheset",
      icon: "twitter",
    },
    {
      link: "https://www.instagram.com/settheset/",
      icon: "instagram",
    },
    {
      link: "https://www.linkedin.com/company/set-the-set/",
      icon: "linkedin",
    },
  ],
};

export const SocialBlock = ({ content = socialItems }) => {
  const renderIcon = (type) => {
    switch (type) {
      case "facebook":
        return <LogoMargin name="FacebookDefault" />;
      case "twitter":
        return <LogoMargin name="TwitterDefault" />;
      case "instagram":
        return <LogoMargin name="InstagramDefault" />;
      case "linkedin":
        return <LogoMargin name="LinkedInDefault" />;
      default:
        break;
    }
  };

  return (
    <BlockDefault>
      <Section yCenter>
        <Row>
          <TextContainer xStart>
            <H1>{content.text.title}</H1>
            <H4>{content.text.subtitle}</H4>
          </TextContainer>
          <SocialContainer yCenter>
            {content.socialLinks.map(({ link, icon }) => (
              <SocialLink key={icon} href={link} target="_blank">
                {renderIcon(icon)}
              </SocialLink>
            ))}
          </SocialContainer>
        </Row>
      </Section>
    </BlockDefault>
  );
};

const SocialLink = styled.a`
  & + & {
    margin-left: 56px;
  }
`;

const BlockDefault = styled(GridColumn)`
  background-color: #f5f5f5;
  padding: 4.294vw 3.067vw 5.521vw 3.067vw;
  position: relative;
  z-index: 0;
  h1,
  h3 {
    color: ${(props) => props.theme.colors.text};
    text-align: center;
  }
  h1 {
    font-size: 3.067vw;
    line-height: 3.374vw;
  }
  h4 {
    font-size: 1.304vw;
    line-height: 1.687vw;
    margin-top: 8px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    padding: 56px 40px 72px 40px;
    h1 {
      font-size: 25px;
      line-height: 29px;
    }
    h4 {
      font-size: 17px;
      line-height: 22px;
      margin-top: 4px;
      margin-bottom: 40px;
      text-align: center;
    }
  }

  @media only screen and ${(props) => props.theme.media.extraLarge} {
    padding: 56px 40px 72px 40px;
    h1 {
      font-size: 40px;
      line-height: 44px;
    }
    h4 {
      margin-top: 4px;
    }
  }
`;

const Row = styled(GridRow)`
  justify-content: space-between;
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
  }
`;

const LogoMargin = styled(Logo)`
  width: 32px;
  height: 32px;
`;

const SocialContainer = styled(GridRow)`
  justify-content: flex-end;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: center;
  }
`;

const TextContainer = styled(GridColumn)`
  align-items: flex-start;
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: center;
  }
`;
