import React from "react";
import styled from "styled-components";
import { GridRow, BrowserInfo, H4 } from "notes";

export const DeviceArtwork = ({ username }) => {
  const getOSSpecificImage = () => {
    const browserOS = BrowserInfo.getOS();
    if (browserOS.name === "Android") {
      return (
        <AndroidUserImage src="https://firebasestorage.googleapis.com/v0/b/set-live-stage.appspot.com/o/site-assets%2FCreate-Username-Example-Android.png?alt=media" />
      );
    }
    return (
      <SafariUserImage src="https://firebasestorage.googleapis.com/v0/b/set-live-stage.appspot.com/o/site-assets%2FCreate-Username-Example-iPhone.png?alt=media" />
    );
  };

  return (
    <ImageContainer yStart>
      {getOSSpecificImage()}
      <MagnifyImage src="https://firebasestorage.googleapis.com/v0/b/set-live-stage.appspot.com/o/site-assets%2Fmagnify.png?alt=media" />
      <Username>{username ? username : "..."}</Username>
    </ImageContainer>
  );
};

const Username = styled(H4)`
  color: ${(props) => props.theme.palette.gray.lighter};
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  left: 43px;
  top: 283px;
`;

const MagnifyImage = styled.img`
  position: absolute;
  left: 19px;
  top: 220px;
  width: 239px;
  height: 239px;
`;

const ImageContainer = styled(GridRow)`
  position: relative;
  width: 100%;
  max-width: 392px;
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-left: -32px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 56px;
    max-width: 100%;
  }
`;

const SafariUserImage = styled.img`
  width: 391px;
  height: 581px;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 100%;
    height: auto;
  }
`;

const AndroidUserImage = styled.img`
  width: 391px;
  height: 614px;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 100%;
    height: auto;
  }
`;
