import React, { useState, useContext } from "react";
import styled from "styled-components";
import { H1, P, GridColumn, Label, ButtonPrimary } from "notes";
import { musicianFormFields } from "Data";
import { FormContext, FormWrapper, StoreContext } from "Components";
import { Field } from "Components";
import { handleErrors } from "Components/FormProvider";

export const Musicians = ({ onSuccess }) => {
  const { musicianForm, setMusicianForm } = useContext(FormContext);
  const { Store } = useContext(StoreContext);
  const [errors, setErrors] = useState(false);

  const { header, fields } = musicianFormFields;

  const handleChange = (value, name) => {
    setMusicianForm(value);
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = () => {
    let errorObject = handleErrors(fields, musicianForm);

    if (!errorObject) {
      Store.firestore()
        .collection("musicians")
        .add(musicianForm)
        .then((response) => onSuccess(response));
    } else {
      setErrors(errorObject);
    }
  };

  return (
    <FormWrapperStyle hideFooter>
      <Container xCenter yCenter>
        <Card>
          <CardContent>
            <Title>{header.title}</Title>
            <P>{header.subtitle}</P>
            <Form>
              {fields
                .sort((a, b) => a.order - b.order)
                .map((field) => {
                  return (
                    <QuestionContainer
                      fixSize={["text", "email", "double", "phone"].includes(
                        field.type
                      )}
                      key={field.id}
                    >
                      <Label>{field.label}</Label>
                      <Field
                        field={field}
                        value={musicianForm[field.name]}
                        onChange={(value) => handleChange(value, field.name)}
                        error={{
                          status: errors[field.name],
                          message:
                            "This is a required field and cannot be blank.",
                        }}
                      />
                    </QuestionContainer>
                  );
                })}
              <ButtonContainer columns={2}>
                <ButtonPrimary onClick={() => handleSubmit()}>
                  Submit
                </ButtonPrimary>
              </ButtonContainer>
            </Form>
          </CardContent>
        </Card>
      </Container>
    </FormWrapperStyle>
  );
};

const Container = styled(GridColumn)`
  margin: 40px 0 96px 0;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const Card = styled(GridColumn)`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  max-width: 808px;
  min-width: 0;
  width: 100%;
  overflow: hidden;
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 0;
    box-shadow: none;
    padding: 0 24px;
  }
`;

const CardContent = styled(GridColumn)`
  padding: 72px 0;
  margin: 0 auto;
  max-width: 600px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0;
  }
`;

const Title = styled(H1)`
  font-size: 56px;
  line-height: 66px;
  margin-bottom: 16px;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 40px;
    line-height: 44px;
  }
`;

const Form = styled(GridColumn)`
  width: 100%;
  margin-top: 40px;
`;

const QuestionContainer = styled(GridColumn)`
  ${(props) => props.fixSize && "max-width: 392px"};
  & + & {
    margin-top: 24px;
  }
  & > ${Label} {
    margin-bottom: 4px;
  }
`;

const ButtonContainer = styled(GridColumn)`
  margin-top: 52px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 24px;
  }
`;

const FormWrapperStyle = styled(FormWrapper)`
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0;
    & > svg {
      align-self: flex-start;
      margin-left: 24px;
      flex-shrink: 0;
    }
  }
`;
