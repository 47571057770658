export const formatName = (name) => {
  if (name.substr(0, name.indexOf(" "))) {
    return {
      name: {
        firstName: name.substr(0, name.indexOf(" ")),
        lastName: name.substr(name.indexOf(" ") + 1),
      },
    };
  } else {
    return {
      name: {
        firstName: name,
        lastName: "",
      },
    };
  }
};
