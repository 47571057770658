import styled from "styled-components";
import { ButtonPrimary } from "notes";

export const ButtonTertiary = styled(ButtonPrimary)`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.palette.gray.primary};
  color: ${(props) => props.theme.palette.black};
  &:hover,
  &:focus {
    background-color: #fff;
    border: 1px solid ${(props) => props.theme.palette.gray.primary};
  }
`;
