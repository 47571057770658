export const handleErrors = (error) => {
  if (error.code === "resource-exhausted") {
    return {
      available: false,
      type: "error",
      content: "You have attempted too many times, please try again later.",
    };
  } else if (error.code === "permission-denied") {
    return {
      available: false,
      type: "error",
      content: "You must be logged-in to create your display name.",
    };
  } else {
    return {
      available: false,
      type: "error",
      content: "There was an issue with our system, please try again.",
    };
  }
};
