import React from "react";
import styled from "styled-components";
import { Icon, P, Logo, GridColumn, GridRow, ButtonPrimary } from "notes";

export const Requests = ({ onClick, content, buttonText, ...props }) => {
  return (
    <STSContainer xCenter yStart {...props}>
      <STSDetails xCenter>
        <STSLogo name="SetTheSetDefault" />
        <VoteText>{content}</VoteText>
      </STSDetails>
      <STSButton onClick={onClick}>
        {buttonText} <Icon tertiary name="Next" />
      </STSButton>
    </STSContainer>
  );
};

const STSContainer = styled(GridColumn)`
  margin-top: 24px;
  width: 100%;
`;

const STSLogo = styled(Logo)`
  flex-shrink: 0;
  margin-right: 8px;
  width: 26px;
  height: 26px;
`;

const STSDetails = styled(GridRow)`
  margin-bottom: 14px;
  width: 100%;
`;

const VoteText = styled(P)`
  font-size: 17px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
`;

const STSButton = styled(ButtonPrimary)`
  background-color: ${(props) => props.theme.palette.teal.primary};
  width: 100%;
  svg {
    margin-left: 12px;
    path {
      fill: #fff;
    }
  }
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.palette.teal.darker};
  }
`;
