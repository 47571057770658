import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Modal } from "notes";
import { SignUp, ModalContext, StoreContext } from "Components";
import { formatName } from "modules";

export const SignUpModal = ({ open, setOpen }) => {
  const { toggleLoginModal } = useContext(ModalContext);
  const { Store } = useContext(StoreContext);
  const history = useHistory();

  const handleOAuth = (success) => {
    let name = formatName(success.user.displayName);
    Store.firestore().collection("profiles").doc(success.user.uid).set(name);
    !!success && setOpen(false);
  };

  const handleManual = (success) => {
    setOpen(false);
    !!success && history.push("/requestverification");
  };

  return (
    <ModalStyle size="small" open={open} setOpen={setOpen}>
      <SignUp
        onOAuth={handleOAuth}
        onManual={handleManual}
        onLoginClick={() => {
          setOpen(false);
          toggleLoginModal(true);
        }}
      />
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  margin: 5.521vw 0;
  min-height: inherit;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 0;
    min-height: 776px;
  }
`;
