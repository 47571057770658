import React from "react";
import styled from "styled-components";
import { GridRow } from "notes";

export const Divider = ({ purchased, ...props }) => {
  return (
    <DividerContainer {...props}>
      <DividerStyle variant={purchased} xCenter>
        <Caret variant={purchased} />
      </DividerStyle>
    </DividerContainer>
  );
};

const DividerContainer = styled(GridRow)`
  background-color: #fff;
  height: 20px;
`;

const DividerStyle = styled(GridRow)`
  background-color: ${(props) =>
    props.variant
      ? props.theme.palette.purple.primary
      : props.theme.palette.orange.lighter};
  margin-bottom: 13px;
  height: 7px;
  width: 100%;
`;

const Caret = styled.div`
  background-color: ${(props) =>
    props.variant
      ? props.theme.palette.purple.primary
      : props.theme.palette.orange.lighter};
  bottom: 2px;
  display: block;
  position: relative;
  transform: rotate(45deg);
  height: 18px;
  width: 18px;
`;
