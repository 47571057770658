import React from "react";
import { CountrySelector } from "notes";
import { CountryList } from "Data";
import styled from "styled-components";

export const CountrySelect = ({ selected, onChange, ...props }) => {
  if (!selected) {
    onChange(CountryList[0]);
  }
  return (
    <CountrySelectorStyle
      options={CountryList}
      onChange={onChange}
      selected={selected}
      country
      {...props}
    />
  );
};

const CountrySelectorStyle = styled(CountrySelector)`
  align-items: center;
  border-left: 1px solid ${(props) => props.theme.palette.gray.primary};
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: center;
  padding: 0;

  & > svg {
    margin-left: 15px;
    position: relative;
    top: inherit;
    right: inherit;
  }
`;
