import React, { memo } from "react";
import { PhoneField } from "./Phone";
import { RadioField } from "./Radio";
import { TextAreaField } from "./TextArea";
import { DoubleField } from "./DoubleField";
import { EmailField } from "./Email";

export const Field = memo(({ field, value, error, onChange }) => {
  switch (field.type) {
    case "double":
      return (
        <DoubleField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    case "email":
      return (
        <EmailField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    case "phone":
      return (
        <PhoneField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    case "radio":
      return (
        <RadioField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    case "textarea":
      return (
        <TextAreaField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    default:
      break;
  }
});
