import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { GridRow, GridColumn, P, H1, Label, ButtonSecondary } from "notes";
import { HeaderSecondary, StoreContext, FooterBlock } from "Components";
import { logout } from "services";
import { EditName, EditShirtSize, EditShipping } from "./Components";

export const Account = () => {
  const { username, user, Store } = useContext(StoreContext);
  const history = useHistory();
  const [profile, loading, error] = useDocumentData(
    Store.firestore().doc(`profiles/${user?.uid}`)
  );

  if (loading || !user) {
    return null;
  }

  const handleSubmit = (value, name) => {
    Store.firestore()
      .collection("profiles")
      .doc(user.uid)
      .update({ [name]: value });
  };

  const handleLogout = async () => {
    try {
      await logout();
      history.push("/");
    } catch (err) {
      console.log(err);
    }
  };

  const { email, phoneNumber } = user;

  return (
    <Wrapper>
      <HeaderSecondary banner />
      <Container>
        <PageHeader>
          <H1>My Account</H1>
        </PageHeader>

        <Fields>
          <FieldContainer>
            <FieldLabel>Display name</FieldLabel>
            <P>{username}</P>
          </FieldContainer>
          <EditName
            defaultValue={profile?.name}
            onSubmit={(value) => handleSubmit(value, "name")}
          />
          <FieldContainer>
            <FieldLabel>Email address</FieldLabel>
            <P>{email}</P>
          </FieldContainer>
          {phoneNumber && (
            <FieldContainer>
              <FieldLabel>Phone number</FieldLabel>
              <P>{phoneNumber}</P>
            </FieldContainer>
          )}
        </Fields>
        <Divider />

        <Fields>
          <EditShipping
            defaultValue={profile?.shippingAddress}
            onSubmit={(value) => handleSubmit(value, "shippingAddress")}
          />
          <EditShirtSize
            defaultValue={profile?.shirtSize}
            onSubmit={(value) => handleSubmit(value, "shirtSize")}
          />
        </Fields>
        <Divider />

        <ButtonContainer columns={2}>
          <ButtonSecondary onClick={() => handleLogout()}>
            Sign Out
          </ButtonSecondary>
        </ButtonContainer>
      </Container>

      <FooterBlock />
    </Wrapper>
  );
};

const Divider = styled(GridRow)`
  background-color: #fafafa;
  border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  width: 100%;
  max-width: 600px;
  height: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    max-width: 100%;
    border-width: 2px;
  }
`;

const Wrapper = styled(GridColumn)`
  width: 100%;
`;

const PageHeader = styled(GridRow)`
  margin-bottom: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding-left: 24px;
  }
`;

const Container = styled(GridColumn)`
  width: 100%;
  padding: 40px 0 96px 0;
  margin: 0 auto;
  max-width: 1016px;
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-left: 40px;
  }
`;

const Fields = styled(GridColumn)`
  max-width: 600px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    padding-left: 24px;
    max-width: 100%;
  }
  & > div:last-child {
    border-bottom: none;
  }
`;

const FieldLabel = styled(Label)``;

const FieldContainer = styled(GridColumn)`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 16px 0;
  position: relative;
  min-height: 56px;

  ${FieldLabel} {
    margin-bottom: 4px;
    color: ${(props) =>
      props.editMode
        ? props.theme.palette.black
        : props.theme.palette.gray.lighter};
  }
`;

const ButtonContainer = styled(GridColumn)`
  margin-top: 24px;
  max-width: 184px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px;
    max-width: 100%;
  }
`;
