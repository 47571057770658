import React from "react";
import styled from "styled-components";
import { Logo, GridColumn, P } from "notes";

export const Image = ({ image, requests, ...props }) => {
  return (
    <Artist image={image}>
      {requests && (
        <>
          <CallOut />
          <Requests xCenter>
            <STSIcon name="SetTheSetReversed" />
            <RequestText>Accepting Song Requests</RequestText>
          </Requests>
        </>
      )}
    </Artist>
  );
};

const Artist = styled(GridColumn)`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center 40%;
  border-top-right-radius: ${(props) => (props.theme.preShow ? "16px" : 0)};
  border-bottom-right-radius: ${(props) => (props.theme.preShow ? "16px" : 0)};
  padding-bottom: ${(props) => (props.theme.preShow ? "40.5%" : "39.12%")};
  position: relative;
  width: ${(props) => (props.theme.preShow ? "40.5%" : "55.82%")};
  flex-shrink: 0;
  overflow: hidden;
  @media only screen and ${(props) => props.theme.media.medium} {
    width: ${(props) => (props.theme.preShow ? "40.5%" : "50%")};
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    width: ${(props) => (props.theme.preShow ? "40.5%" : "55.82%")};
  }
`;

const CallOut = styled(GridColumn)`
  background-color: ${(props) => props.theme.ticket.callOut};
  border-radius: 78px;
  position: absolute;
  top: -32px;
  right: -32px;
  height: 156px;
  width: 156px;
  z-index: 2;
  @media only screen and ${(props) => props.theme.media.medium} {
    border-radius: 70px;
    top: -20px;
    right: -20px;
    height: 120px;
    width: 120px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    border-radius: 78px;
    top: -32px;
    right: -32px;
    height: 156px;
    width: 156px;
  }
`;

const Requests = styled(GridColumn)`
  position: absolute;
  top: 26px;
  right: 16px;
  height: 60px;
  width: 80px;
  z-index: 3;
  @media only screen and ${(props) => props.theme.media.medium} {
    top: 10px;
    right: 5px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    top: 26px;
    right: 16px;
  }
`;

const STSIcon = styled(Logo)`
  flex-shrink: 0;
  margin-bottom: 8px;
  height: 24px;
  width: 24px;
  path {
    fill: #fff;
  }
`;

const RequestText = styled(P)`
  color: #ffffff;
  font-size: 11px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
  width: 80px;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 10px;
    line-height: 15px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 11px;
    line-height: 17px;
  }
`;
