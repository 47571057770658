import React, { createContext, useContext, useState, useEffect } from "react";
import { StoreContext } from "Components";
import { Payments } from "./";
import { useStripe } from "@stripe/react-stripe-js";

export const PaymentContext = createContext();
export const PaymentConsumer = PaymentContext.Consumer;
export const PaymentProvider = ({ children }) => {
  const { Store, user } = useContext(StoreContext);
  const stripe = useStripe();
  const [api] = useState(new Payments(stripe, Store));
  const [savedCards, setSavedCards] = useState([]);
  useEffect(() => {
    updatePaymentMethods();
  }, [api, user]);

  const updatePaymentMethods = () => {
    api
      .getSavedCards()
      .then((res) => {
        if (res.success) {
          const {
            data: { cards },
          } = res;
          if (cards.length) {
            setSavedCards([cards[0]]);
          } else {
            setSavedCards([]);
          }
        }
      })
      .catch((err) => {
        setSavedCards([]);
      });
  };

  return (
    <PaymentContext.Provider value={{ savedCards, updatePaymentMethods }}>
      {children}
    </PaymentContext.Provider>
  );
};
