import React from "react";
import styled from "styled-components";
import { GridColumn, H3, P, Icon } from "notes";
import {
  OpenAuth,
  ManualEnroll,
  Link,
  OrDivider,
  FormContainer,
} from "Components";

export const SignUp = ({ onOAuth, onManual, onLoginClick, hasTicket }) => {
  return (
    <FormContainerStyle hasTicket={hasTicket}>
      {hasTicket && (
        <PurchaseHeader>
          <H3>Let’s Get You To The Show!</H3>
          <P>
            To grab your seat either log in with your set.live account or
            register below!
          </P>
        </PurchaseHeader>
      )}
      <Content>
        {!hasTicket && (
          <>
            <H3>Sign Up</H3>
            <P>
              Join Set.Live and enjoy front row seats to your favorite artists’
              concerts from anywhere in the world.
            </P>
          </>
        )}

        <OpenAuth onSuccess={onOAuth} />

        <OrDivider />

        <ManualEnroll onSuccess={onManual} />
      </Content>
      <Footer>
        <P>
          Already have an account?
          <Link onClick={onLoginClick}>
            Log In <Icon tertiary name="Next" />
          </Link>
        </P>
      </Footer>
    </FormContainerStyle>
  );
};

const Content = styled(GridColumn)``;

const PurchaseHeader = styled(GridColumn)`
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px;
  }
`;

const Footer = styled(GridColumn)`
  background-color: ${(props) => props.theme.palette.gray.fill};
  margin-top: 56px;
  order: 2;
  padding: 16px;
  @media only screen and ${(props) => props.theme.media.small} {
    order: 1;
    margin-top: 0;
    margin-bottom: 40px;
  }
  ${P} {
    color: ${(props) => props.theme.palette.gray.primary};
    text-align: center;
  }
  ${Link} {
    position: relative;
    margin-right: 24px;
    margin-left: 8px;
    &:hover {
      svg path {
        fill: ${(props) => props.theme.palette.purple.darker};
      }
    }
  }
  svg {
    position: absolute;
    top: 3px;
    right: -24px;
    path {
      fill: ${(props) => props.theme.palette.purple.primary};
    }
  }
`;

const FormContainerStyle = styled(FormContainer)`
  ${Content} {
    ${(props) => props.hasTicket && "order: 2;"};
    padding: ${(props) => (props.hasTicket ? 0 : "0 40px")};
  }
  ${Footer} {
    ${(props) => props.hasTicket && "order: 1;"};
    ${(props) => props.hasTicket && "margin-top: 0;"};
    ${(props) => props.hasTicket && "margin-bottom: 40px;"};
  }
  @media only screen and ${(props) => props.theme.media.small} {
    ${Content} {
      padding: 0 24px;
      order: 2;
    }
  }
`;
