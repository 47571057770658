import React from "react";
import { Message } from "notes";
import { withRouter } from "react-router-dom";

class GlobalErrorHandlerInternal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.props.history.push("/");
  }

  render() {
    return (
      <>
        <Message
          color="red"
          open={!!this.state.hasError}
          setOpen={(open) => this.setState({ hasError: open })}
          timeout={10000}
        >
          Our application has encountered an unexpected issue, while we diagnose
          it, please try again.
        </Message>
        {this.props.children}
      </>
    );
  }
}

export const GlobalErrorHandler = withRouter(GlobalErrorHandlerInternal);
