import React, { useState } from "react";
import styled from "styled-components";
import { GridRow, Icon, H3 } from "notes";
import { socialShare } from "services";

export const Share = ({ showView }) => {
  const [copyNotification, setCopyNotification] = useState(false);
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setCopyNotification(true);
    setTimeout(() => setCopyNotification(false), 2000);
  };
  return (
    <Container>
      <Actions showView={showView}>
        <FacebookIcon
          onClick={() =>
            socialShare("facebook", window.location.href, "Come see the show!")
          }
          name="Facebook"
        />

        <TwitterIcon
          onClick={() =>
            socialShare("twitter", window.location.href, "Come see the show!")
          }
          name="Twitter"
        />
        <LinkContainer xCenter>
          <Notification isVisible={copyNotification} xCenter>
            Copied to Clipboard
            <Caret indicator name="Dropdown" />
          </Notification>
          <LinkIcon
            onClick={() => {
              handleCopy(window.location.href);
            }}
            tertiary
            name="Link"
          />
        </LinkContainer>
      </Actions>
      {showView && (
        <Badge noShrink xCenter yCenter>
          <H3>LIVE</H3>
        </Badge>
      )}
    </Container>
  );
};

const LinkContainer = styled(GridRow)`
  position: relative;
`;

const Notification = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.gray.lightest};
  border-radius: 4px;
  color: ${(props) => props.theme.palette.gray.primary};
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  padding: 4px 6px;
  position: absolute;
  top: -23px;
  transition: all 0.5s ease;
  white-space: nowrap;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const Caret = styled(Icon)`
  position: absolute;
  bottom: -11px;
  path {
    fill: ${(props) => props.theme.palette.gray.lightest};
  }
`;

const Container = styled(GridRow)`
  margin-top: 24px;
  margin-bottom: 4px;
  width: 100%;
  height: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Badge = styled(GridRow)`
  background-color: ${(props) =>
    props.theme.dark
      ? props.theme.palette.red.primary
      : props.theme.palette.gray.lightest};
  ${(props) =>
    props.theme.dark &&
    `box-shadow: 0 0 16px 0 ${props.theme.palette.red.primary};`}
  border-radius: 2px;
  cursor: default;
  width: 40px;
  height: 24px;
  ${H3} {
    color: ${(props) =>
      props.theme.dark ? "#ffffff" : props.theme.palette.gray.lighter};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }
`;

const FacebookIcon = styled(Icon)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  path {
    fill: ${(props) =>
      props.theme.dark ? "#fff" : props.theme.colors.facebook};
  }
  margin-right: 16px;
`;

const TwitterIcon = styled(FacebookIcon)`
  path {
    fill: ${(props) =>
      props.theme.dark ? "#fff" : props.theme.colors.twitter};
  }
`;

const LinkIcon = styled(FacebookIcon)`
  margin-right: 0;
  path {
    fill: ${(props) => (props.theme.dark ? "#fff" : props.theme.palette.black)};
  }
`;

const Actions = styled(GridRow)`
  width: 100%;
  height: 24px;
  ${H3} {
    font-size: 14px;
    line-height: 18px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: center;
    ${(props) => !props.showView && "justify-content: flex-end;"}
  }
`;
