import React from "react";
import styled from "styled-components";
import { GridColumn, Subheader } from "notes";
import { Image } from "./Image";
import { EventDetails, Divider } from "../Mobile/Components";
import { ArtistName } from "../Mobile";
import { formatShow } from "Components/Tickets";

export const TicketStub = ({ show, purchased, children, ...props }) => {
  let { image, requests, date, time, length, artist, subtitle } = formatShow(
    show
  );
  console.log("image", image);
  return (
    <TicketContainer {...props}>
      <Image image={image} requests={requests}>
        <EventDetails date={date} time={time} length={length} />
      </Image>
      <Divider />
      <BottomContainer xCenter yCenter>
        <ArtistNameStyle>{artist}</ArtistNameStyle>
        <Subheader>{subtitle}</Subheader>
      </BottomContainer>
    </TicketContainer>
  );
};

const TicketContainer = styled(GridColumn)`
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  & + & {
    margin-top: 40px;
  }
`;

const ArtistNameStyle = styled(ArtistName)`
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 4px;
  }
`;

const BottomContainer = styled(GridColumn)`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-top: none;
  min-height: 163px;
  padding: 24px;
  text-align: center;
  width: 100%;
  overflow: hidden;
  top: -13px;
  position: relative;
  @media only screen and ${(props) => props.theme.media.small} {
    min-height: 133px;
  }
`;
