import React from "react";
import styled from "styled-components";
import { GridRow, GridColumn, H1, H2, H4, Logo } from "notes";
import { Section } from "Components";
import {
  FrameTopLeftDesktop,
  FrameTopRightDesktop,
  FrameBottomDesktop,
  FrameTopMobile,
  FrameBottomMobile,
} from "./images";

export const RequestsBlock = ({ shows }) => {
  return (
    <BlockDefault>
      <Section xCenter yCenter>
        <DividerTopMobile />
        <GridRow yCenter>
          <DividerTopLeft />
          <STSLogo name="SetTheSetReversed" />
          <TextContainer xStart>
            <H4>Artists Accepting Song Requests</H4>
            <H1>Set The Set</H1>
          </TextContainer>
          <DividerTopRight />
        </GridRow>
        <TileContainer>
          {shows?.map(({ id, artist, image, requests }) => (
            <Tile key={id} yEnd href={requests} target="_blank">
              <ArtistImage backgroundImage={image} />
              <Gradient xCenter yEnd>
                <Title>{artist}</Title>
              </Gradient>
            </Tile>
          ))}
        </TileContainer>
        <DividerBottomDesktop />
        <DividerBottomMobile />
      </Section>
    </BlockDefault>
  );
};

const BlockDefault = styled(GridRow)`
  background-color: #222;
  position: relative;
  z-index: 0;
  h1,
  h4 {
    color: #fff;
  }
  h1 {
    font-size: 40px;
    line-height: 44px;
  }
  h4 {
    letter-spacing: 0.2px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    flex-wrap: wrap;
    padding: 96px 16px;
    h1 {
      font-size: 6.667vw;
      line-height: 7.733vw;
    }
    h4 {
      font-size: 3.733vw;
      line-height: 5.867vw;
    }
  }

  @media only screen and ${(props) => props.theme.media.medium} {
    padding: 7.362vw 3.067vw;
  }

  @media only screen and ${(props) => props.theme.media.large} {
    padding: 96px 40px;
    h1 {
      font-size: 40px;
      line-height: 44px;
    }
  }

  @media only screen and (max-width: 375px) {
    padding: 25.6vw 4.267vw;
  }
`;

const STSLogo = styled(Logo)`
  margin-right: 24px;
  width: 56px;
  height: 56px;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 12.8vw;
    height: 12.8vw;
  }
`;

const TextContainer = styled(GridColumn)`
  align-items: flex-start;
`;

const ArtistImage = styled(GridColumn)`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Tile = styled(GridColumn).attrs({ as: "a" })`
  border-radius: 4px;
  box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24), 0 1px 8px 0 rgba(0, 0, 0, 0.32);
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 22.086vw;
  height: 22.086vw;
  z-index: 0;
  transform: scale(1);
  transition: all ease 0.3s;
  &:hover {
    transform: scale(1.015);
  }

  @media only screen and ${(props) => props.theme.media.small} {
    margin: 2.133vw;
    width: 41.4vw;
    height: 41.4vw;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    & + & {
      margin-left: 1.84vw;
    }
  }
  @media only screen and ${(props) => props.theme.media.large} {
    & + & {
      margin-left: 24px;
    }
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    width: 288px;
    height: 288px;
  }
  @media only screen and (max-width: 375px) {
    margin: 2.133vw;
    width: 41.4vw;
    height: 41.4vw;
  }
`;

const Gradient = styled(GridRow)`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  position: absolute;
  bottom: -2px;
  height: 50%;
  width: 100%;
  z-index: 2;
`;

const Title = styled(H2)`
  color: #fff;
  font-weight: 700;
  margin-bottom: 24px;
  position: relative;
  text-align: center;
  z-index: 3;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 3.733vw;
    line-height: 4.267vw;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 1.917vw;
    line-height: 2.454vw;
    margin-bottom: 1.84vw;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 1.917vw;
    line-height: 2.454vw;
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    font-size: 25px;
    line-height: 32px;
  }

  @media only screen and (max-width: 375px) {
    margin-bottom: 6.4vw;
    font-size: 3.733vw;
    line-height: 4.267vw;
  }
`;

const DividerTopLeft = styled(FrameTopLeftDesktop)`
  margin-right: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const DividerTopRight = styled(FrameTopRightDesktop)`
  margin-left: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const DividerBottomDesktop = styled(FrameBottomDesktop)`
  margin-top: 70px;
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const DividerTopMobile = styled(FrameTopMobile)`
  width: 87.467vw;
  height: 2.133vw;
  margin-bottom: 40px;
  display: none;
  @media only screen and ${(props) => props.theme.media.small} {
    display: block;
  }
`;

const DividerBottomMobile = styled(FrameBottomMobile)`
  width: 87.467vw;
  height: 2.133vw;
  margin-top: 56px;
  display: none;
  @media only screen and ${(props) => props.theme.media.small} {
    display: block;
  }
`;

const TileContainer = styled(GridRow)`
  margin-top: 56px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 24px;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 91.733vw;
  }
`;
