import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GridColumn } from "notes";
import { FormWrapper, SignUp, PurchaseTicket, StoreContext } from "Components";

export const Enrollment = ({ location: { state } }) => {
  const { Store } = useContext(StoreContext);
  const history = useHistory();

  const handleOAuth = (success) => {
    let name = success.user.displayName;
    Store.firestore()
      .collection("profiles")
      .doc(success.user.uid)
      .set({
        name: {
          firstName: name.substr(0, name.indexOf(" ")),
          lastName: name.substr(name.indexOf(" ") + 1),
        },
      });
    !!success && !!state ? history.push("/purchase", state) : history.push("/");
  };

  const handleManual = (success) => {
    !!success && history.push("/requestverification", state);
  };

  const handleLogin = () => {
    history.push("/login", state);
  };

  return (
    <FormWrapper>
      <Container showTicket={!!state}>
        {!!state && <PurchaseTicket eventId={state.id} />}
        <SignUp
          hasTicket={!!state}
          onOAuth={handleOAuth}
          onManual={handleManual}
          onLoginClick={handleLogin}
        />
      </Container>
    </FormWrapper>
  );
};

const purchaseStylesDesktop = `
  flex-direction: row;
  max-width: 912px;
  width: 100%;
  padding-top: 72px;
  padding-bottom: 96px;
`;

const purchaseStylesMobile = `
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 72px;
`;

export const Container = styled(GridColumn)`
  padding-top: 40px;
  ${(props) => props.showTicket && purchaseStylesDesktop};
  @media only screen and ${(props) => props.theme.media.small} {
    ${(props) => props.showTicket && purchaseStylesMobile};
  }
`;
