import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Landing } from "./Landing";
import { Enrollment } from "./Enrollment";
import { Login } from "./Login";
import { RequestVerification } from "./RequestVerification";
import { Verify } from "./Verify";
import { Purchase } from "./Purchase";
import { Event } from "./Event";
import { Account } from "./Account";
import { Privacy } from "./Privacy";
import { Terms } from "./Terms";
import { Contact } from "./Contact";
import Reset from "./Reset";
import { ModalProvider, GlobalErrorHandler, StoreContext } from "Components";

export const Routes = () => {
  const { user, userLoading, userVerified } = useContext(StoreContext);
  if (userLoading) {
    return null;
  }
  return (
    <Router>
      <GlobalErrorHandler>
        <ModalProvider>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/contact" component={Contact} />
            <Route
              exact
              path="/requestverification"
              component={RequestVerification}
            />
            <Route exact path="/verify" component={Verify} />
            {user && !userVerified && (
              <>
                <Redirect to="/requestverification" />
              </>
            )}
            <Route exact path="/signup" component={Enrollment} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset" component={Reset.Main} />
            <Route
              exact
              path="/reset/password"
              component={Reset.PasswordForm}
            />
            <Route exact path="/event/:event" component={Event} />
            <Route
              exact
              path={["/purchase", "/purchase/:view"]}
              component={Purchase}
            />
            <Route exact path="/account" component={Account} />
            <Redirect to="/" />
          </Switch>
        </ModalProvider>
      </GlobalErrorHandler>
    </Router>
  );
};
