import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import InputMask from "react-input-mask";
import { Input, FlexBox, Icon } from "notes";
import { CountrySelect } from "./Country";
import { CountryList, countryCodes } from "Data";

const Container = styled(FlexBox)`
  position: relative;
`;

export const CountryPhone = ({ phoneNumber: value, onChange }) => {
  const [country, setCountry] = useState(CountryList[0]);
  // if receiving data formatted with a country code
  // remove it and reformat
  if (value && typeof value !== "object") {
    if (value.indexOf("+") > -1) {
      let parts = value.replace(/\+/g, "").split(" ");
      if (parts.length > 1) {
        // remove country code
        parts.shift();
        value = parts.join();
      }
    }
    // make sure there are no spaces
    value = value.replace(/\s/g, "");
  }

  if (!country.value) {
    return null;
  }

  const countryKey = country.value.toLowerCase();
  const countryCode = _.get(countryCodes, `${countryKey}.countryCode`, "");
  let phoneMask = "999999999999999";
  if (countryKey === "us") {
    phoneMask = _.get(countryCodes, `${countryKey}.phone`, "9999 999999");
  }
  const mask = `${countryCode !== "" ? `+${countryCode} ` : ""}${phoneMask}`;

  return (
    <Container>
      <CountrySelect selected={country} onChange={setCountry} />
      <InputMask maskChar="" mask={mask} value={value} onChange={onChange}>
        {() => (
          <StyledInput
            style={{
              borderRadius: "4px 0 0 4px",
            }}
            type="tel"
            data-javelin-name={`input-phone`}
            data-javelin-meta={JSON.stringify(value)}
            leftIcon={<Icon form name="Phone" />}
            placeholder={!!mask ? mask.replace(/9/g, "0") : ""}
          ></StyledInput>
        )}
      </InputMask>
    </Container>
  );
};

const StyledInput = styled(Input)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
