import React, { useState, useContext } from "react";
import styled from "styled-components";
import {
  H1,
  H3,
  H4,
  GridColumn,
  GridRow,
  TextArea,
  CheckboxLarge,
  Label,
  Radio,
  Checkbox,
  Footnote,
  ButtonPrimary,
  Rating,
  Subheader,
} from "notes";
import { StoreContext } from "Components";
import { internalSurveyData } from "Data";

export const Survey = ({ show, initialRating, onSuccess }) => {
  const { Store, user } = useContext(StoreContext);
  const [surveyQuestions, setSurveyQuestions] = useState(internalSurveyData);

  const handleRadio = (questionIndex, optionIndex) => {
    let qArray = [...surveyQuestions];
    qArray[questionIndex].options.map((option) => (option.selected = false));
    qArray[questionIndex].options[optionIndex].selected = true;
    setSurveyQuestions(qArray);
  };

  const handleCheckbox = (questionIndex, optionIndex, value) => {
    let cArray = [...surveyQuestions];
    cArray[questionIndex].options[optionIndex].selected = value;
    setSurveyQuestions(cArray);
  };

  const handleTextArea = (questionIndex, value) => {
    let tArray = [...surveyQuestions];
    tArray[questionIndex].value = value;
    setSurveyQuestions(tArray);
  };

  const handleNotifications = (questionIndex, value) => {
    let nArray = [...surveyQuestions];
    nArray[questionIndex].selected = value;
    setSurveyQuestions(nArray);
  };

  const handleRating = (value) => {
    let nArray = [...surveyQuestions];
    let nArrayIndex = nArray.findIndex(({ type }) => type.includes("rating"));
    nArray[nArrayIndex].value = value;
    setSurveyQuestions(nArray);
  };

  let ratingExists = surveyQuestions.find(({ type }) => type.includes("rating"))
    .value;

  if (initialRating && !ratingExists) {
    handleRating(initialRating);
  }

  const handleSubmit = () => {
    Store.firestore()
      .collection("feedback")
      .doc(show.id)
      .collection("responses")
      .doc(user.uid)
      .set({ response: surveyQuestions });
    onSuccess();
  };

  const renderField = (question, questionIndex) => {
    switch (question.type) {
      case "radio":
        return question.options.map(
          ({ id, title, subtitle, selected }, optionIndex) => (
            <Radio
              key={id}
              checked={selected}
              onChange={(checked) =>
                handleRadio(questionIndex, optionIndex, checked)
              }
              title={!!subtitle && title}
            >
              {!!subtitle ? subtitle : title}
            </Radio>
          )
        );
      case "checkbox":
        return question.options.map(
          ({ id, title, subtitle, selected }, optionIndex) => (
            <Checkbox
              key={id}
              checked={selected}
              onChange={(checked) =>
                handleCheckbox(questionIndex, optionIndex, checked)
              }
              title={!!subtitle && title}
            >
              {!!subtitle ? subtitle : title}
            </Checkbox>
          )
        );
      case "textarea":
        return (
          <TextArea
            onChange={(e) => handleTextArea(questionIndex, e.target.value)}
            value={question.value}
            placeholder={question.placeholder}
          />
        );
      case "notifications":
        return (
          <CheckboxLargeStyled
            title={question.title}
            checked={question.selected}
            onChange={(checked) => handleNotifications(questionIndex, checked)}
          >
            <Italic>{question.subtitle}</Italic>
          </CheckboxLargeStyled>
        );
      default:
        break;
    }
  };

  return (
    <Container xCenter yCenter>
      <Card>
        <ArtistImage image={show.image}>
          <OverlayContent xCenter>
            <ArtistName>{show.artist}</ArtistName>
            <Subtitle>{show.subtitle}</Subtitle>
            <Subheader>How Was The Show?</Subheader>
            <RatingContainer xCenter yCenter noShrink>
              <Rating
                max={5}
                value={
                  surveyQuestions.find(({ type }) => type.includes("rating"))
                    .value
                }
                setValue={(value) => handleRating(value)}
              />
            </RatingContainer>
          </OverlayContent>
          <Gradient />
        </ArtistImage>
        <CardContent>
          <Form>
            <H4>We Appreciate The Feedback!</H4>
            <Footnote>
              Si sine causa? quae fuerit causa, mox videro; interea hoc.
            </Footnote>
            {surveyQuestions
              .sort((a, b) => a.order - b.order)
              .map((question, index) => {
                return (
                  <QuestionContainer key={question.id}>
                    {question.type !== "notifications" && (
                      <Label>{question.label}</Label>
                    )}
                    {renderField(question, index)}
                  </QuestionContainer>
                );
              })}
            <ButtonContainer columns={2}>
              <ButtonPrimary onClick={handleSubmit}>
                Send Feedback
              </ButtonPrimary>
            </ButtonContainer>
          </Form>
        </CardContent>
      </Card>
    </Container>
  );
};

const Container = styled(GridColumn)`
  margin: 40px 0 96px 0;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    margin: 0;
  }
`;

const Card = styled(GridColumn)`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  max-width: 808px;
  min-width: 0;
  width: 100%;
  overflow: hidden;
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 0;
  }
`;

const CardContent = styled(GridColumn)`
  padding: 40px 24px 72px 24px;
`;

const ArtistImage = styled(GridColumn)`
  background-image: url(${(props) => props.image});
  background-position: center 40%;
  background-size: cover;
  padding-top: 57.55%;
  position: relative;
  width: 100%;
  z-index: 1;
  @media only screen and ${(props) => props.theme.media.small} {
    background-position: center 30%;
    padding-top: 56.38%;
  }
`;

const OverlayContent = styled(GridColumn)`
  position: absolute;
  left: 0;
  bottom: 72px;
  width: 100%;
  height: 156px;
  z-index: 3;
  ${Subheader} {
    color: #fff;
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    bottom: 24px;
    height: 70px;
    ${Subheader} {
      display: block;
    }
  }
`;

const ArtistName = styled(H1)`
  color: #fff;
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const Subtitle = styled(H3)`
  color: ${(props) => props.theme.palette.gray.lighter};
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const RatingContainer = styled(GridRow)`
  background-color: #fff;
  border-radius: 2px;
  margin-top: 32px;
  width: 264px;
  height: 40px;
  svg + svg {
    margin-left: 24px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 12px;
    width: 242px;
    svg + svg {
      margin-left: 18px;
    }
  }
`;

const Gradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const Form = styled(GridColumn)`
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  h4 {
    display: none;
    font-weight: 700;
  }
  & > ${Footnote} {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    h4 {
      display: block;
    }
    & > ${Footnote} {
      display: block;
    }
  }
`;

const QuestionContainer = styled(GridColumn)`
  & + & {
    margin-top: 24px;
  }
  & > ${Label} {
    margin-bottom: 4px;
  }
`;

const ButtonContainer = styled(GridColumn)`
  margin-top: 52px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 24px;
  }
`;

const Italic = styled(Footnote)`
  margin-top: 4px;
  font-style: italic;
`;

const CheckboxLargeStyled = styled(CheckboxLarge)`
  margin: 0;
`;
