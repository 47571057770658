import React, { Fragment } from "react";
import styled from "styled-components";
import { H4, GridRow, P, Icon } from "notes";

const SupportListContainer = styled.div`
  padding: 20px;
  background-color: #222222;
`;

const SupportListRow = styled(GridRow)`
  padding: 12px 0;
  justify-content: space-between;
  border-bottom: 1px solid #596266;
`;

const SupportTitleText = styled(H4)`
  color: #ffeaad;
`;

const SupportText = styled(P)`
  color: ${(props) => props.theme.colors.white};
`;

const PlaceHolderIcon = styled(Icon)`
  transform: rotate(90deg);
  path {
    fill: #596266;
  }
`;

const IconContainer = styled.div`
  margin-right: 5px;
`;

export const SupportList = ({ topSupporters, user, levels }) => {
  return (
    <SupportListContainer>
      <SupportListRow>
        <SupportTitleText>SHOW YOUR SUPPORT</SupportTitleText>
      </SupportListRow>
      {topSupporters.map(({ id, user, rank, supportLevel }) => {
        return (
          <SupportListRow key={id}>
            <SupportText>
              {rank < 10 && "0"}
              {rank}. {user}
            </SupportText>
            <IconContainer>{levels[supportLevel]}</IconContainer>
          </SupportListRow>
        );
      })}
      {user && (
        <Fragment>
          <SupportListRow>
            <PlaceHolderIcon platform name="More"></PlaceHolderIcon>
          </SupportListRow>
          <SupportListRow>
            <SupportText>
              {user.rank < 9 ? "0" : ""}
              {user.rank}. {user.user}
            </SupportText>
            <IconContainer>{levels[user.level]}</IconContainer>
          </SupportListRow>
        </Fragment>
      )}
    </SupportListContainer>
  );
};
