import React from "react";
import styled from "styled-components";
import { Gradient, Artist } from "../../Mobile/Components";

export const Image = ({ image, children }) => {
  return (
    <ArtistStub image={image}>
      {children}
      <GradientStub />
    </ArtistStub>
  );
};

const GradientStub = styled(Gradient)`
  @media only screen and ${(props) => props.theme.media.small} {
    height: 100%;
  }
`;

const ArtistStub = styled(Artist)`
  padding-top: 70.4%;
  @media only screen and ${(props) => props.theme.media.small} {
    padding-top: 53.66%;
  }
`;
