import React from "react";
import styled from "styled-components";
import { GridColumn } from "notes";

export const SplashBlock = ({
  desktopImage,
  mobileImage,
  children,
  ...props
}) => (
  <BlockContainer
    desktopImage={desktopImage}
    mobileImage={mobileImage}
    {...props}
  >
    {children}
  </BlockContainer>
);

const BlockContainer = styled(GridColumn)`
  background-color: #000;
  ${(props) =>
    props.desktopImage && `background-image: url(${props.desktopImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4.294vw 3.067vw 5.521vw 3.067vw;
  position: relative;
  width: 100%;

  @media only screen and ${(props) => props.theme.media.small} {
    ${(props) =>
      props.mobileImage && `background-image: url(${props.mobileImage})`};
  }
`;

export const Section = ({ children, ...props }) => (
  <SectionContainer {...props}>{children}</SectionContainer>
);

const SectionContainer = styled(GridColumn)`
  margin: 0 auto;
  max-width: 1016px;
  width: 100%;
`;
