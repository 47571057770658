import styled from "styled-components";
import { GridColumn, H3, P } from "notes";

export const FormContainer = styled(GridColumn)`
  max-width: 472px;
  margin: 0 auto;
  width: 100%;

  ${H3} {
    line-height: 32px;
    margin-bottom: 4px;
  }
  ${H3} + ${P} {
    margin-bottom: 24px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    ${H3} {
      font-size: 17px;
      height: auto;
      line-height: 22px;
    }
    ${H3} + ${P} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
