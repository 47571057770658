import React from "react";
import styled from "styled-components";
import { GridRow, GridColumn, Logo, P } from "notes";

export const Image = ({ image, requests, children, ...props }) => {
  return (
    <Artist image={image}>
      {requests && (
        <>
          <CallOut />
          <Requests xCenter>
            <STSIcon name="SetTheSetReversed" />
            <RequestText>Accepting Song Requests</RequestText>
          </Requests>
        </>
      )}
      {children}
      <Gradient />
    </Artist>
  );
};

const CallOut = styled(GridColumn)`
  background-color: ${(props) => props.theme.palette.teal.primary};
  border-radius: 78px;
  position: absolute;
  top: -32px;
  right: -32px;
  height: 156px;
  width: 156px;
  z-index: 2;
`;

const Requests = styled(GridColumn)`
  position: absolute;
  top: 26px;
  right: 16px;
  height: 60px;
  width: 80px;
  z-index: 3;
`;

const STSIcon = styled(Logo)`
  flex-shrink: 0;
  margin-bottom: 8px;
  height: 24px;
  width: 24px;
  path {
    fill: #fff;
  }
`;

const RequestText = styled(P)`
  color: #ffffff;
  font-size: 11px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
  width: 80px;
`;

export const Gradient = styled(GridRow)`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  bottom: 0;
  position: absolute;
  height: 138px;
  width: 100%;
  z-index: 1;
`;

export const Artist = styled(GridColumn)`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center 40%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 84.15%;
  position: relative;
  width: 100%;
  overflow: hidden;
`;
