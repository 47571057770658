import React, { useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import {
  H1,
  H3,
  P,
  GridColumn,
  Label,
  Input,
  ButtonPrimary,
  withDefaultMedia,
  Icon,
  Footnote,
} from "notes";
import { Link, FieldMessage, LoaderInline } from "Components";
import { Api } from "modules";
import { DeviceArtwork } from "./Artwork";
import { handleErrors } from "./handleErrors";

const initState = { available: false, type: "", content: "" };

const detailText = `All that’s left to do is choose a display name. Your display name will show up in the concert chat as well as the leaderboard for as well as any other set.live show you attend. The display name can use only numbers, letters or an underscore (no spaces or other punctuation). In the space below, let us know what you would like yours to be. Your display name will be uniquely yours so we’ll check to make sure no one else already has it.`;

export const UsernameForm = withDefaultMedia(
  ({ matches, onChange, onSuccess }) => {
    const [name, setName] = useState("");
    const [message, setMessage] = useState(initState);
    const [inputLoading, setInputLoading] = useState(false);
    const [buttonState, setButtonState] = useState({
      disabled: false,
      loading: false,
      confirmed: false,
    });
    const scrollRef = useRef();

    const scrollToField = () => {
      var element = document.getElementById("scroll-container");
      let scrollPosition = scrollRef.current.offsetHeight + 172;
      element.scrollTo({
        behavior: "smooth",
        top: scrollPosition - window.innerHeight,
      });
    };

    const handleResponse = (available) => {
      if (available) {
        return {
          available: true,
          type: "success",
          content: "This name is available!",
        };
      } else {
        return {
          available: false,
          type: "error",
          content: "This name is unavailable.",
        };
      }
    };

    const handleCall = (username) => {
      Api.checkUsername({ action: "check_availability", username: username })
        .then(({ data }) => {
          const message = handleResponse(data.available);
          setMessage(message);
          setInputLoading(false);
          setButtonState({
            disabled: false,
            loading: false,
            confirmed: false,
          });
        })
        .catch((error) => {
          const displayError = handleErrors(error);
          setMessage(displayError);
          setInputLoading(false);
          setButtonState({
            disabled: false,
            loading: false,
            confirmed: false,
          });
          console.error(`Got error: ${error.code} - ${error.message}`);
        });
    };

    const handleCheck = useCallback(
      debounce((value) => handleCall(value), 2000),
      []
    );

    const handleChange = (username) => {
      if (!username.match(/[^\w]|__{2}/g)) {
        setMessage(initState);
        setName(username);
        onChange(username);
        if (username.length > 3) {
          setInputLoading(true);
          setButtonState({
            disabled: true,
            loading: false,
            confirmed: false,
          });
          handleCheck(username);
        } else if (!username) {
          setMessage(initState);
        }
      }
    };

    const handleSubmit = (name) => {
      if (message.available) {
        setButtonState({ disabled: true, loading: true, confirmed: false });
        Api.checkUsername({ action: "set", username: name })
          .then(({ data }) => {
            if (data.success) {
              !!onSuccess && onSuccess(true);
              setButtonState({
                disabled: true,
                loading: false,
                confirmed: true,
              });
            }
          })
          .catch((error) => {
            setButtonState({
              disabled: false,
              loading: false,
              confirmed: false,
            });
            setMessage({
              available: false,
              type: "error",
              content:
                "There was an issue with your request, please try again.",
            });
            console.error(`Got error: ${error.code} - ${error.message}`);
          });
      } else {
        setMessage({
          available: false,
          type: "error",
          content: "Your name must be a minimum of 3 characters.",
        });
      }
    };

    return (
      <Content yStart ref={scrollRef}>
        <H1>Reservation Complete!</H1>
        <H3>
          {!matches.large
            ? "Get Ready For The Show!"
            : "Let's Get You Ready For The Show"}
        </H3>
        <P>
          {detailText}
          {matches.small && (
            <Link onClick={scrollToField}>Set your display name now!</Link>
          )}
        </P>
        {matches.small && <DeviceArtwork username={name} />}
        <Label>Display name</Label>
        <StyledInput
          isError={message.type.includes("error")}
          isSuccess={message.type.includes("success")}
          value={name}
          onChange={handleChange}
          placeholder="Select a unique name for yourself..."
          rightIcon={
            inputLoading ? (
              <Loader />
            ) : name.length > 3 ? (
              <Icon
                onClick={() => {
                  setName("");
                  setMessage(initState);
                }}
                form
                name="RemoveCircle"
              />
            ) : undefined
          }
        />
        <Footnote style={{ paddingTop: "12px" }}>
          Must be at least 4 characters in length and cannot include special
          characters.
        </Footnote>
        <FieldMessage
          active={
            message.type.includes("error") || message.type.includes("success")
          }
          type={message.type}
          content={message.content}
        />
        <ConfirmButton
          disabled={buttonState.disabled || message.type === "error"}
          onClick={() => handleSubmit(name)}
        >
          {buttonState.loading ? (
            <LoaderInline />
          ) : buttonState.confirmed ? (
            "Confirmed!"
          ) : (
            "Confirm Display Name"
          )}
        </ConfirmButton>
      </Content>
    );
  }
);

const Loader = styled(LoaderInline)`
  & {
    top: -15px;
    left: -20px;
  }
`;

const ConfirmButton = styled(ButtonPrimary)`
  margin-top: 40px;
  margin-bottom: 18px;
  & + ${P} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 15px;
    font-weight: 400;
  }
`;

const Content = styled(GridColumn).attrs({ as: "div" })`
  display: flex;
  width: 100%;
  margin-left: 72px;
  max-width: 392px;
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-left: 0;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    flex-shrink: 0;
    margin-left: 0;
    max-width: 100%;
  }
  ${H1} {
    margin-bottom: 24px;
  }
  ${H3} {
    margin-bottom: 10px;
  }
  ${Label} {
    margin-top: 40px;
    margin-bottom: 4px;
  }
`;

const Color = styled.span`
  color: ${(props) => props.theme.palette.purple.primary};
  border-bottom: 1px solid ${(props) => props.theme.palette.purple.primary};
  font-weight: 600;
`;

const sharedStyles = `
  border-width: 2px;
  box-shadow: none;
`;

const StyledInput = styled(Input)`
  ${(props) =>
    props.isSuccess && `border-color: ${props.theme.palette.green.primary};`};
  ${(props) =>
    props.isError && `border-color: ${props.theme.palette.red.primary};`};
  ${(props) => (props.isSuccess || props.isError) && sharedStyles};

  &:focus {
    ${(props) =>
      props.isError && `border-color: ${props.theme.palette.red.primary};`};
    ${(props) =>
      props.isSuccess && `border-color: ${props.theme.palette.green.primary};`};
    ${(props) => (props.isSuccess || props.isError) && sharedStyles};
  }
`;
