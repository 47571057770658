import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { H4, GridRow, withDefaultMedia } from "notes";
import { Link, ModalContext } from "Components";

export const ShowStatus = withDefaultMedia(
  ({ matches, days, hours, minutes, seconds, live }) => {
    const { toggleLoginModal } = useContext(ModalContext);
    const history = useHistory();
    if (live) {
      return (
        <Wrapper xCenter yCenter>
          <StatusLabel>
            The show has already started! Get your ticket below or{" "}
            <Link
              color="white"
              onClick={() => {
                matches.small ? history.push("/login") : toggleLoginModal(true);
              }}
            >
              Log In
            </Link>{" "}
            to join!
          </StatusLabel>
        </Wrapper>
      );
    }
    return (
      <Wrapper xCenter>
        <Container yCenter>
          <StatusLabel>Show Starts In:</StatusLabel>
          {days > 0 && (
            <Increment>{`${days} Day${days > 1 ? "s" : ""}`}</Increment>
          )}
          {hours > 0 && (
            <Increment>{`${hours} Hr${hours > 1 ? "s" : ""}`}</Increment>
          )}
          {minutes > 0 && (
            <Increment>{`${minutes} Min${minutes > 1 ? "s" : ""}`}</Increment>
          )}
          {days < 1 && (
            <Increment>
              <span style={{ display: "inline-block", minWidth: "35px" }}>
                {seconds}
              </span>
              {` Second${seconds !== 1 ? "s" : ""}`}
            </Increment>
          )}
        </Container>
      </Wrapper>
    );
  }
);

const Wrapper = styled(GridRow)`
  display: flex;
  background-color: ${(props) => props.theme.palette.gray.primary};
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 12px 16px;
  min-height: 80px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 2px;
    min-height: 50px;
  }
`;

const Container = styled(GridRow)`
  width: 100%;
  justify-content: center;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: space-evenly;
    max-width: 300px;
  }
`;

const StatusLabel = styled(H4)`
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  ${Link} {
    font-size: 25px;
    line-height: 32px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    ${Link} {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const Increment = styled(H4)`
  color: #fff;
  ${(props) => props.theme.fonts.header};
  font-size: 25px;
  font-weight: 700;
  line-height: 29px;
  margin-left: 24px;

  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 14px;
    line-height: 18px;
    margin-left: 0;
  }
`;
