import React from "react";
import styled from "styled-components";
import { Modal, GridColumn, H3, P, ButtonPrimary } from "notes";

export const MusicianSuccess = ({ open, setOpen, ...props }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalStyle size="small" open={open} setOpen={handleClose} {...props}>
      <Container>
        <H3>Thank You For Your Interest!</H3>
        <P>
          Your information has been sent to our artist team and will be reviewed
          in the coming days. A Set.Live team member will then contact you with
          next steps and additional information.
        </P>

        <Done onClick={handleClose}>Done</Done>
      </Container>
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  margin-top: 8.589vw;
  min-height: 282px;
  svg {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    max-width: 296px;
    padding: 24px;
  }
`;

const Container = styled(GridColumn)`
  height: 100%;
  ${H3} {
    margin-bottom: 16px;
    text-align: center;
  }
  ${P} {
    text-align: center;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    ${H3} {
      font-size: 17px;
      line-height: 22px;
    }
  }
`;

const Done = styled(ButtonPrimary)`
  margin-top: 40px;
`;
