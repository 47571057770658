import React, { useContext, useRef } from "react";
import styled from "styled-components";
import { Modal, Icon } from "notes";
import { FormWrapper, CreateUsername, Receipt, ModalContext } from "Components";

export const ConfirmModal = ({ open, setOpen }) => {
  const { confirmModal } = useContext(ModalContext);

  const handleSuccess = () => {
    setOpen(false);
  };

  return (
    <ModalStyle size="full" open={open} setOpen={setOpen}>
      <ScrollContainer id="scroll-container">
        <Wrapper hideFooter>
          <CreateUsername onSuccess={() => handleSuccess()} />
          <Receipt data={confirmModal?.items} />
        </Wrapper>
      </ScrollContainer>
    </ModalStyle>
  );
};

const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 32px;
  z-index: 2;
`;

const ModalStyle = styled(Modal)`
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: inherit;
  & > svg {
    display: none;
  }
`;

const ScrollContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(FormWrapper)`
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0;
    & > svg {
      align-self: flex-start;
      margin-left: 24px;
      flex-shrink: 0;
    }
  }
`;
