import { Eventable } from "./Eventable";

export class Payments extends Eventable {
  constructor(stripe, firebase, analytics = null) {
    ///
    // Events fired by this component. All event callbacks are passed
    // two variables.  The first variable type depends on the event,
    // while all callbacks receive the instance of this class as the
    // second argument.  Some events receive a <response> object as the
    // first argument which looks like:
    //    {
    //      success: <boolean>,
    //      context: <object>
    //    }
    //
    // For error responses, the `context` object will contains
    // a `message` parameter that can be displayed to the user.
    //
    const events = [
      // Payment finished (success or failure)
      // Signature: (<response>, api)
      "payment",

      // Add payment method complete (success or failure)
      // Signature: (<response>, api)
      "payment_method",

      // PaymentRequest initialized
      // Signature: (<boolean>, api)
      "payment_request_init",
      "payment_request_update",
    ];

    // Register events with the parent class
    super(events);

    this.stripe = stripe;

    this.analytics = analytics;

    this.firebase = firebase;

    const functions = firebase.functions();

    // XXX: Wrap all of these in a single cloud function?
    this.endpoints = {
      getSavedCards: functions.httpsCallable("payments-getSavedCards"),
      removeCards: functions.httpsCallable("payments-removeCards"),
      initAddCard: functions.httpsCallable("payments-initAddCard"),
      initPayment: functions.httpsCallable("payments-initPayment"),
      finalizePayment: functions.httpsCallable("payments-finalizePayment"),
    };

    this.item = {
      amount: 0,
      label: "Place Holder",
    };

    this.paymentRequest = null;
  }

  /**
   *
   * Initialize the payment system.  This is needed
   * primariliy to setup the PaymentRequest plumbing
   *
   */
  async init() {
    if (this.paymentRequest === null) {
      this.paymentRequest = this.stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: this.item,
        requestPayerName: true,
        requestPayerEmail: true,
      });

      this.paymentRequest.on("paymentmethod", (event) =>
        this.submitPayment({
          paymentMethod: event.paymentMethod.id,
          paymentRequestEvent: event,
        })
      );

      const result = await this.paymentRequest.canMakePayment();
      this.fire("payment_request_init", result);
    }
  }

  success(data = {}) {
    const payload = {
      success: true,
      data: data,
    };

    return payload;
  }

  error(message, code, err = null) {
    const payload = {
      success: false,
      error: {
        message: message,
        code: code,
      },
    };

    if (err) {
      console.error(`${message}: ${JSON.stringify(err)}`);
    } else {
      console.error(message);
    }

    return payload;
  }

  /**
   * `item` should have a `label` and an `amount`.
   */
  async setItem(item) {
    // TODO: Validate item payload
    this.item = item;

    const qty = item.quantity || 1;

    if (this.analytics) {
      this.analytics.logEvent(
        this.firebase.analytics.EventName.SELECT_ITEM,
        item
      );
    }

    this.paymentRequest.update({
      total: {
        label: this.item.label,
        amount: this.item.amount * qty, // Convert from dollars to cents
      },
    });
    const result = await this.paymentRequest.canMakePayment();
    this.fire("payment_request_update", result);
  }

  response(success, context = {}, response = {}) {
    return {
      success,
      context,
      response,
    };
  }

  async addPaymentMethod(card) {
    const eventName = "payment_method";

    let response = null;

    console.log("Adding new payment method");

    console.log("Initializing new payment method");
    try {
      response = await this.endpoints.initAddCard();
    } catch (err) {
      console.log(`Initialization failed with exception: ${err}`);
      return await this.fire(
        eventName,
        this.response(false, {
          message: "unknown error",
        })
      );
    }

    if (!response.data.success) {
      console.log(`Initialization failed`);
      return await this.fire(
        eventName,
        this.response(false, {
          message: "unknown error",
        })
      );
    }

    const clientSecret = response.data.data.token;

    if (!clientSecret) {
      console.log("Couldn't get client secret");
      return await this.fire(
        eventName,
        this.response(false, {
          message: "unknown error",
        })
      );
    }

    if (this.analytics) {
      this.analytics.logEvent(
        this.firebase.analytics.EventName.ADD_PAYMENT_INFO,
        {
          secret: clientSecret,
        }
      );
    }

    console.log("Contacting Stripe...");
    try {
      response = await this.stripe.confirmCardSetup(clientSecret, {
        payment_method: { card: card },
      });
    } catch (err) {
      console.log(`Contacting Stripe failed: ${err}`);
      return await this.fire(
        eventName,
        this.response(false, {
          message: "unknown error",
        })
      );
    }

    if (response.error) {
      console.log(`Stripe request failed: ${response.error}`);
      return await this.fire(
        eventName,
        this.response(false, {
          message: response.error.message,
        })
      );
    }

    console.log("Payment method added successfully");

    return await this.fire(
      eventName,
      this.response(true, {
        message: "payment method added",
      })
    );
  }

  async getSavedCards() {
    try {
      const response = await this.endpoints.getSavedCards();
      return response.data;
    } catch (error) {
      return {
        success: false,
        context: {
          message: error,
        },
      };
    }
  }

  async removeSavedCards() {
    try {
      const response = await this.endpoints.removeCards();
      return response.data;
    } catch (error) {
      return {
        success: false,
        context: {
          message: error,
        },
      };
    }
  }

  /**
   * Submit a payment to Stripe.  Returns `true` if payment completed
   * successfully, `false` if there was a failure.
   *
   * For PaymentIntent flow details, see:
   *    https://stripe.com/docs/payments/intents#intent-statuses
   */
  async submitPayment(options = {}) {
    const paymentMethod = options.paymentMethod || null;
    const paymentRequestEvent = options.paymentRequestEvent || null;

    if (options.item) {
      this.setItem(options.item);
    }

    const response = await this._submitPayment(
      paymentMethod,
      paymentRequestEvent
    );

    if (!response.success && paymentRequestEvent) {
      await paymentRequestEvent.complete("fail");
    }

    if (this.analytics) {
      this.analytics.logEvent(this.firebase.analytics.EventName.PURCHASE, {
        item: this.item,
      });
    }

    if (window.fbq && this.item && this.item.type == "ticket") {
      console.log("Firing fb pixel", this.item.conversionTracking);
      window.fbq("track", "Purchase", {
        contents: this.item.conversionTracking,
        content_ids: [this.item.id],
        content_type: "ticket",
        currency: "USD",
        value: this.item.amount / 100,
      });
    }

    //if (response.context.id) {
    //  console.log("Finalizing payment");
    //  try {
    //    await this.endpoints.finalizePayment({id: response.context.id});
    //  } catch (err) {
    //    console.log("Error finalizing payment", err);
    //  }
    //}

    await this.fire("payment", response);

    return response;
  }

  getTimezone() {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (err) {
      // Default to EST
      return "America/New_York";
    }
  }

  async _submitPayment(paymentMethod = null, paymentRequestEvent = null) {
    let response = null;

    console.log(`Submitting payment for item: ${JSON.stringify(this.item)}`);

    try {
      response = await this.endpoints.initPayment({
        item: this.item,
        tz: this.getTimezone(),
      });
    } catch (err) {
      console.log("Error initializing payment");
      return this.response(
        false,
        {
          message: err,
          error: err,
        },
        response
      );
    }

    console.log("Got response from initPayment:", response);

    if (!response.data.success) {
      console.error("Error creating payment");
      // TODO: Use Stripe's error message
      return this.response(
        false,
        {
          message: response.data.error.message,
          error: response.data.error,
        },
        response
      );
    }

    if (response.data.data.purchase_complete) {
      console.log("Payment completed successfully");
      return this.response(
        true,
        {
          message: "payment successful",
          transactions: response.data.data.transactions,
        },
        response
      );
    }

    if (!response.data.data.has_payment_method && paymentMethod == null) {
      console.error("No payment method on file...please add one first");
      return this.response(
        false,
        {
          message: "No payment method provided",
        },
        response
      );
    }

    const initResponse = response;

    const clientSecret = response.data.data.token;

    console.log("Contacting Stripe...");

    try {
      const options = {};
      if (paymentMethod) {
        options.payment_method = paymentMethod;
      }

      // If a paymentRequestEvent is provided, then we
      // need to tell Stripe that we will handle any further
      // actions ourselves (handleActions: false).  We need
      // to do this because we need to signal the
      // paymentRequestEvent before we can propmt the user
      // for additional action.
      response = await this.stripe.confirmCardPayment(clientSecret, options, {
        handleActions: paymentRequestEvent ? false : true,
      });

      console.log("Got response from Stripe");
    } catch (err) {
      console.log("Error confirming payment with Stripe");
      return this.response(
        false,
        {
          message: "Unknown error",
        },
        initResponse
      );
    }

    if (response.error) {
      console.log("Got error from Stripe: ", response.error);

      if (paymentRequestEvent) {
        console.log("Marking Payment Request fail");
        await paymentRequestEvent.complete("fail");

        // We need to do this again to properly cleanup
        // the PaymentRequest process.
        await this.stripe.confirmCardPayment(clientSecret);
      }

      return this.response(
        false,
        {
          message: response.error.message,
        },
        initResponse
      );
    }

    // Things are looking good...

    if (response.paymentIntent) {
      if (paymentRequestEvent) {
        console.log("Marking Payment Request success");
        await paymentRequestEvent.complete("success");
      }

      let finalizePaymentPromise = null;
      if (response.paymentIntent.status === "succeeded") {
        finalizePaymentPromise = this.endpoints.finalizePayment({
          id: response.paymentIntent.id,
        });
      }

      //
      // This can return a 400 error because the payment is possibly already
      // complete.  However, we need to do this incase there is a verification
      // step required.  This is the official way to do this.
      //
      // See more:
      //  * https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-complete-payment
      //  * https://github.com/stripe/stripe-payments-demo/issues/133#issuecomment-632593669
      //
      response = await this.stripe.confirmCardPayment(clientSecret);

      if (response.paymentIntent.status === "succeeded") {
        console.log("Payment completed successfully");

        let transactions = [];
        if (finalizePaymentPromise) {
          try {
            const result = await finalizePaymentPromise;
            transactions = result.data.transactions;
          } catch (err) {
            console.log("Error finalizePayment:", err);
          }
        }

        if (!transactions) {
          try {
            const result = await this.endpoints.finalizePayment({
              id: response.paymentIntent.id,
            });
            transactions = result.data.transactions;
          } catch (err) {
            console.log("Error finalizePayment:", err);
          }
        }

        return this.response(true, {
          message: "payment successful",
          id: response.paymentIntent.id,
          transactions: transactions,
        });
      }
    }

    if (paymentRequestEvent) {
      console.log("Marking Payment Request fail");
      await paymentRequestEvent.complete("fail");
    }

    console.log("Payment failed");

    return this.response(
      false,
      {
        message: "unknown error",
      },
      initResponse
    );
  }
}
