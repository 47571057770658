import React, { useState } from "react";
import styled from "styled-components";
import { P } from "notes";
import { Link } from "../Link";

export const ExpandingText = ({ text, count, ...props }) => {
  const [expand, setExpand] = useState(false);
  let textLength = text.length;
  return textLength >= count ? (
    <P {...props}>
      {expand ? text : text.substr(0, count)}
      {" ... "}
      <SeeMoreLink onClick={() => setExpand(!expand)}>
        {expand ? "See Less" : "See More"}
      </SeeMoreLink>
    </P>
  ) : (
    <P {...props}>{text}</P>
  );
};

const SeeMoreLink = styled(Link)`
  ${(props) => props.theme.dark && `color: white;`}
  font-size: 15px;
  line-height: 19px;
`;
