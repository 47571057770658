import React from "react";
import styled from "styled-components";
import { Icon } from "notes";
import {
  AmericanExpressBadge,
  DiscoverBadge,
  MastercardBadge,
  VisaBadge,
} from "./Assets";

export const CreditCardBadge = ({ type, ...props }) => {
  switch (type) {
    case "amex":
      return <AmericanExpressBadge {...props} />;
    case "discover":
      return <DiscoverBadge {...props} />;
    case "mastercard":
      return <MastercardBadge {...props} />;
    case "visa":
      return <VisaBadge {...props} />;
    default:
      return <CreditCardIcon form name="CreditCard" {...props} />;
  }
};

const CreditCardIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`;
