import styled from "styled-components";
import { Link as NotesLink } from "notes";

export const Link = styled(NotesLink).attrs((props) => ({
  default: props.color
    ? props.color === "white"
      ? "#fff"
      : props.theme.palette[props.color].primary
    : props.theme.palette.purple.primary,
  hover: props.color
    ? props.color === "white"
      ? "#fff"
      : props.theme.palette[props.color].darker
    : props.theme.palette.purple.darker,
}))`
  color: ${(props) => props.default};
  display: inline-flex;
  box-shadow: inset 0 0px 0 white, inset 0 -2px 0 ${(props) => props.default};
  &:hover,
  &:focus {
    color: ${(props) => props.hover};
    box-shadow: inset 0 0px 0 white, inset 0 -2px 0 ${(props) => props.hover};
    text-decoration: none;
  }
`;
