import React, { useContext } from "react";
import styled from "styled-components";
import { Modal } from "notes";
import { Login, ModalContext } from "Components";

export const LoginModal = ({ open, setOpen }) => {
  const { toggleSignUpModal } = useContext(ModalContext);
  return (
    <ModalStyle size="small" open={open} setOpen={setOpen}>
      <Login
        onSuccess={(value) => setOpen(!value)}
        onSignUpClick={() => {
          setOpen(false);
          toggleSignUpModal(true);
        }}
      />
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  margin: 5.521vw 0;
  min-height: inherit;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 0;
    min-height: 776px;
  }
`;
