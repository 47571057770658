import flagus from "./us.svg";
import flagca from "./ca.svg";
import flagmx from "./mx.svg";
import flagaf from "./af.svg";
import flagax from "./ax.svg";
import flagal from "./al.svg";
import flagdz from "./dz.svg";
import flagas from "./as.svg";
import flagad from "./ad.svg";
import flagao from "./ao.svg";
import flagai from "./ai.svg";
import flagag from "./ag.svg";
import flagar from "./ar.svg";
import flagam from "./am.svg";
import flagaw from "./aw.svg";
import flagau from "./au.svg";
import flagat from "./at.svg";
import flagaz from "./az.svg";
import flagbs from "./bs.svg";
import flagbh from "./bh.svg";
import flagbd from "./bd.svg";
import flagbb from "./bb.svg";
import flagby from "./by.svg";
import flagbe from "./be.svg";
import flagbz from "./bz.svg";
import flagbj from "./bj.svg";
import flagbm from "./bm.svg";
import flagbt from "./bt.svg";
import flagbo from "./bo.svg";
import flagba from "./ba.svg";
import flagbw from "./bw.svg";
import flagbr from "./br.svg";
import flagvg from "./vg.svg";
import flagbn from "./bn.svg";
import flagbg from "./bg.svg";
import flagbf from "./bf.svg";
import flagmm from "./mm.svg";
import flagbi from "./bi.svg";
import flagtc from "./tc.svg";
import flagkh from "./kh.svg";
import flagcm from "./cm.svg";
import flagcv from "./cv.svg";
import flagky from "./ky.svg";
import flagcf from "./cf.svg";
import flagtd from "./td.svg";
import flagcl from "./cl.svg";
import flagcn from "./cn.svg";
import flagcx from "./cx.svg";
import flagcc from "./cc.svg";
import flagco from "./co.svg";
import flagkm from "./km.svg";
import flagcd from "./cd.svg";
import flagck from "./ck.svg";
import flagcr from "./cr.svg";
import flagci from "./ci.svg";
import flaghr from "./hr.svg";
import flagcu from "./cu.svg";
import flagcy from "./cy.svg";
import flagcz from "./cz.svg";
import flagdk from "./dk.svg";
import flagdj from "./dj.svg";
import flagdm from "./dm.svg";
import flagdo from "./do.svg";
import flagec from "./ec.svg";
import flageg from "./eg.svg";
import flagsv from "./sv.svg";
import flaggq from "./gq.svg";
import flager from "./er.svg";
import flagee from "./ee.svg";
import flaget from "./et.svg";
import flageu from "./eu.svg";
import flagfk from "./fk.svg";
import flagfo from "./fo.svg";
import flagfj from "./fj.svg";
import flagfi from "./fi.svg";
import flagfr from "./fr.svg";
import flagpf from "./pf.svg";
import flagtf from "./tf.svg";
import flagga from "./ga.svg";
import flaggm from "./gm.svg";
import flagge from "./ge.svg";
import flagde from "./de.svg";
import flaggh from "./gh.svg";
import flaggi from "./gi.svg";
import flaggr from "./gr.svg";
import flaggl from "./gl.svg";
import flaggd from "./gd.svg";
import flaggp from "./gp.svg";
import flaggu from "./gu.svg";
import flaggt from "./gt.svg";
import flaggn from "./gn.svg";
import flaggw from "./gw.svg";
import flaggy from "./gy.svg";
import flaght from "./ht.svg";
import flaghn from "./hn.svg";
import flaghk from "./hk.svg";
import flaghu from "./hu.svg";
import flagis from "./is.svg";
import flagin from "./in.svg";
import flagio from "./io.svg";
import flagid from "./id.svg";
import flagir from "./ir.svg";
import flagiq from "./iq.svg";
import flagie from "./ie.svg";
import flagil from "./il.svg";
import flagit from "./it.svg";
import flagjm from "./jm.svg";
import flagsj from "./sj.svg";
import flagjp from "./jp.svg";
import flagjo from "./jo.svg";
import flagkz from "./kz.svg";
import flagke from "./ke.svg";
import flagki from "./ki.svg";
import flagkw from "./kw.svg";
import flagkg from "./kg.svg";
import flagla from "./la.svg";
import flaglv from "./lv.svg";
import flaglb from "./lb.svg";
import flagls from "./ls.svg";
import flaglr from "./lr.svg";
import flagly from "./ly.svg";
import flagli from "./li.svg";
import flaglt from "./lt.svg";
import flaglu from "./lu.svg";
import flagmo from "./mo.svg";
import flagmk from "./mk.svg";
import flagmg from "./mg.svg";
import flagmw from "./mw.svg";
import flagmy from "./my.svg";
import flagmv from "./mv.svg";
import flagml from "./ml.svg";
import flagmt from "./mt.svg";
import flagmh from "./mh.svg";
import flagmq from "./mq.svg";
import flagmr from "./mr.svg";
import flagmu from "./mu.svg";
import flagyt from "./yt.svg";
import flagfm from "./fm.svg";
import flagmd from "./md.svg";
import flagmc from "./mc.svg";
import flagmn from "./mn.svg";
import flagme from "./me.svg";
import flagms from "./ms.svg";
import flagma from "./ma.svg";
import flagmz from "./mz.svg";
import flagna from "./na.svg";
import flagnr from "./nr.svg";
import flagnp from "./np.svg";
import flagnl from "./nl.svg";
import flagan from "./an.svg";
import flagnc from "./nc.svg";
import flagpg from "./pg.svg";
import flagnz from "./nz.svg";
import flagni from "./ni.svg";
import flagne from "./ne.svg";
import flagng from "./ng.svg";
import flagnu from "./nu.svg";
import flagnf from "./nf.svg";
import flagkp from "./kp.svg";
import flagmp from "./mp.svg";
import flagno from "./no.svg";
import flagom from "./om.svg";
import flagpk from "./pk.svg";
import flagpw from "./pw.svg";
import flagps from "./ps.svg";
import flagpa from "./pa.svg";
import flagpy from "./py.svg";
import flagpe from "./pe.svg";
import flagph from "./ph.svg";
import flagpn from "./pn.svg";
import flagpl from "./pl.svg";
import flagpt from "./pt.svg";
import flagpr from "./pr.svg";
import flagqa from "./qa.svg";
import flagre from "./re.svg";
import flagro from "./ro.svg";
import flagru from "./ru.svg";
import flagrw from "./rw.svg";
import flagsh from "./sh.svg";
import flagkn from "./kn.svg";
import flaglc from "./lc.svg";
import flagpm from "./pm.svg";
import flagvc from "./vc.svg";
import flagws from "./ws.svg";
import flagsm from "./sm.svg";
import flaggs from "./gs.svg";
import flagst from "./st.svg";
import flagsa from "./sa.svg";
import flagsn from "./sn.svg";
import flagrs from "./rs.svg";
import flagsc from "./sc.svg";
import flagsl from "./sl.svg";
import flagsg from "./sg.svg";
import flagsk from "./sk.svg";
import flagsi from "./si.svg";
import flagsb from "./sb.svg";
import flagso from "./so.svg";
import flagza from "./za.svg";
import flagkr from "./kr.svg";
import flages from "./es.svg";
import flaglk from "./lk.svg";
import flagsd from "./sd.svg";
import flagsr from "./sr.svg";
import flagsz from "./sz.svg";
import flagse from "./se.svg";
import flagch from "./ch.svg";
import flagsy from "./sy.svg";
import flagtw from "./tw.svg";
import flagtj from "./tj.svg";
import flagtz from "./tz.svg";
import flagth from "./th.svg";
import flagtl from "./tl.svg";
import flagtg from "./tg.svg";
import flagtk from "./tk.svg";
import flagto from "./to.svg";
import flagtt from "./tt.svg";
import flagtn from "./tn.svg";
import flagtr from "./tr.svg";
import flagtm from "./tm.svg";
import flagtv from "./tv.svg";
import flagae from "./ae.svg";
import flagug from "./ug.svg";
import flagua from "./ua.svg";
import flaggb from "./gb.svg";
import flaguy from "./uy.svg";
import flagvi from "./vi.svg";
import flaguz from "./uz.svg";
import flagvu from "./vu.svg";
import flagva from "./va.svg";
import flagve from "./ve.svg";
import flagvn from "./vn.svg";
import flagwf from "./wf.svg";
import flagye from "./ye.svg";
import flagzm from "./zm.svg";
import flagzw from "./zw.svg";

export const CountryList = [
  { id: "us", value: "us", text: "United States", image: flagus },
  { id: "ca", value: "ca", text: "Canada", image: flagca },
  { id: "mx", value: "mx", text: "Mexico", image: flagmx },
  { id: "gb", value: "gb", text: "United Kingdom", image: flaggb },
  { id: "af", value: "af", text: "Afghanistan", image: flagaf },
  { id: "ax", value: "ax", text: "Aland Islands", image: flagax },
  { id: "al", value: "al", text: "Albania", image: flagal },
  { id: "dz", value: "dz", text: "Algeria", image: flagdz },
  { id: "as", value: "as", text: "American Samoa", image: flagas },
  { id: "ad", value: "ad", text: "Andorra", image: flagad },
  { id: "ao", value: "ao", text: "Angola", image: flagao },
  { id: "ai", value: "ai", text: "Anguilla", image: flagai },
  { id: "ag", value: "ag", text: "Antigua", image: flagag },
  { id: "ar", value: "ar", text: "Argentina", image: flagar },
  { id: "am", value: "am", text: "Armenia", image: flagam },
  { id: "aw", value: "aw", text: "Aruba", image: flagaw },
  { id: "au", value: "au", text: "Australia", image: flagau },
  { id: "at", value: "at", text: "Austria", image: flagat },
  { id: "az", value: "az", text: "Azerbaijan", image: flagaz },
  { id: "bs", value: "bs", text: "Bahamas", image: flagbs },
  { id: "bh", value: "bh", text: "Bahrain", image: flagbh },
  { id: "bd", value: "bd", text: "Bangladesh", image: flagbd },
  { id: "bb", value: "bb", text: "Barbados", image: flagbb },
  { id: "by", value: "by", text: "Belarus", image: flagby },
  { id: "be", value: "be", text: "Belgium", image: flagbe },
  { id: "bz", value: "bz", text: "Belize", image: flagbz },
  { id: "bj", value: "bj", text: "Benin", image: flagbj },
  { id: "bm", value: "bm", text: "Bermuda", image: flagbm },
  { id: "bt", value: "bt", text: "Bhutan", image: flagbt },
  { id: "bo", value: "bo", text: "Bolivia", image: flagbo },
  { id: "ba", value: "ba", text: "Bosnia", image: flagba },
  { id: "bw", value: "bw", text: "Botswana", image: flagbw },
  { id: "br", value: "br", text: "Brazil", image: flagbr },
  { id: "vg", value: "vg", text: "British Virgin Islands", image: flagvg },
  { id: "bn", value: "bn", text: "Brunei", image: flagbn },
  { id: "bg", value: "bg", text: "Bulgaria", image: flagbg },
  { id: "bf", value: "bf", text: "Burkina Faso", image: flagbf },
  { id: "mm", value: "mm", text: "Burma", image: flagmm },
  { id: "bi", value: "bi", text: "Burundi", image: flagbi },
  { id: "tc", value: "tc", text: "Turks and Caicos Islands", image: flagtc },
  { id: "kh", value: "kh", text: "Cambodia", image: flagkh },
  { id: "cm", value: "cm", text: "Cameroon", image: flagcm },
  { id: "cv", value: "cv", text: "Cape Verde", image: flagcv },
  { id: "ky", value: "ky", text: "Cayman Islands", image: flagky },
  { id: "cf", value: "cf", text: "Central African Republic", image: flagcf },
  { id: "td", value: "td", text: "Chad", image: flagtd },
  { id: "cl", value: "cl", text: "Chile", image: flagcl },
  { id: "cn", value: "cn", text: "China", image: flagcn },
  { id: "cx", value: "cx", text: "Christmas Island", image: flagcx },
  { id: "cc", value: "cc", text: "Cocos Islands", image: flagcc },
  { id: "co", value: "co", text: "Colombia", image: flagco },
  { id: "km", value: "km", text: "Comoros", image: flagkm },
  { id: "cd", value: "cd", text: "Congo", image: flagcd },
  { id: "ck", value: "ck", text: "Cook Islands", image: flagck },
  { id: "cr", value: "cr", text: "Costa Rica", image: flagcr },
  { id: "ci", value: "ci", text: "Cote Divoire", image: flagci },
  { id: "hr", value: "hr", text: "Croatia", image: flaghr },
  { id: "cu", value: "cu", text: "Cuba", image: flagcu },
  { id: "cy", value: "cy", text: "Cyprus", image: flagcy },
  { id: "cz", value: "cz", text: "Czech Republic", image: flagcz },
  { id: "dk", value: "dk", text: "Denmark", image: flagdk },
  { id: "dj", value: "dj", text: "Djibouti", image: flagdj },
  { id: "dm", value: "dm", text: "Dominica", image: flagdm },
  { id: "do", value: "do", text: "Dominican Republic", image: flagdo },
  { id: "ec", value: "ec", text: "Ecuador", image: flagec },
  { id: "eg", value: "eg", text: "Egypt", image: flageg },
  { id: "sv", value: "sv", text: "El Salvador", image: flagsv },
  { id: "gq", value: "gq", text: "Equatorial Guinea", image: flaggq },
  { id: "er", value: "er", text: "Eritrea", image: flager },
  { id: "ee", value: "ee", text: "Estonia", image: flagee },
  { id: "et", value: "et", text: "Ethiopia", image: flaget },
  { id: "eu", value: "eu", text: "Europeanunion", image: flageu },
  { id: "fk", value: "fk", text: "Falkland Islands", image: flagfk },
  { id: "fo", value: "fo", text: "Faroe Islands", image: flagfo },
  { id: "fj", value: "fj", text: "Fiji", image: flagfj },
  { id: "fi", value: "fi", text: "Finland", image: flagfi },
  { id: "fr", value: "fr", text: "France", image: flagfr },
  { id: "pf", value: "pf", text: "French Polynesia", image: flagpf },
  { id: "tf", value: "tf", text: "French Territories", image: flagtf },
  { id: "ga", value: "ga", text: "Gabon", image: flagga },
  { id: "gm", value: "gm", text: "Gambia", image: flaggm },
  { id: "ge", value: "ge", text: "Georgia", image: flagge },
  { id: "de", value: "de", text: "Germany", image: flagde },
  { id: "gh", value: "gh", text: "Ghana", image: flaggh },
  { id: "gi", value: "gi", text: "Gibraltar", image: flaggi },
  { id: "gr", value: "gr", text: "Greece", image: flaggr },
  { id: "gl", value: "gl", text: "Greenland", image: flaggl },
  { id: "gd", value: "gd", text: "Grenada", image: flaggd },
  { id: "gp", value: "gp", text: "Guadeloupe", image: flaggp },
  { id: "gu", value: "gu", text: "Guam", image: flaggu },
  { id: "gt", value: "gt", text: "Guatemala", image: flaggt },
  { id: "gn", value: "gn", text: "Guinea", image: flaggn },
  { id: "gw", value: "gw", text: "Guinea-Bissau", image: flaggw },
  { id: "gy", value: "gy", text: "Guyana", image: flaggy },
  { id: "ht", value: "ht", text: "Haiti", image: flaght },
  { id: "hn", value: "hn", text: "Honduras", image: flaghn },
  { id: "hk", value: "hk", text: "Hong Kong", image: flaghk },
  { id: "hu", value: "hu", text: "Hungary", image: flaghu },
  { id: "is", value: "is", text: "Iceland", image: flagis },
  { id: "in", value: "in", text: "India", image: flagin },
  { id: "io", value: "io", text: "Indian Ocean Territory", image: flagio },
  { id: "id", value: "id", text: "Indonesia", image: flagid },
  { id: "ir", value: "ir", text: "Iran", image: flagir },
  { id: "iq", value: "iq", text: "Iraq", image: flagiq },
  { id: "ie", value: "ie", text: "Ireland", image: flagie },
  { id: "il", value: "il", text: "Israel", image: flagil },
  { id: "it", value: "it", text: "Italy", image: flagit },
  { id: "jm", value: "jm", text: "Jamaica", image: flagjm },
  { id: "sj", value: "sj", text: "Jan Mayen", image: flagsj },
  { id: "jp", value: "jp", text: "Japan", image: flagjp },
  { id: "jo", value: "jo", text: "Jordan", image: flagjo },
  { id: "kz", value: "kz", text: "Kazakhstan", image: flagkz },
  { id: "ke", value: "ke", text: "Kenya", image: flagke },
  { id: "ki", value: "ki", text: "Kiribati", image: flagki },
  { id: "kw", value: "kw", text: "Kuwait", image: flagkw },
  { id: "kg", value: "kg", text: "Kyrgyzstan", image: flagkg },
  { id: "la", value: "la", text: "Laos", image: flagla },
  { id: "lv", value: "lv", text: "Latvia", image: flaglv },
  { id: "lb", value: "lb", text: "Lebanon", image: flaglb },
  { id: "ls", value: "ls", text: "Lesotho", image: flagls },
  { id: "lr", value: "lr", text: "Liberia", image: flaglr },
  { id: "ly", value: "ly", text: "Libya", image: flagly },
  { id: "li", value: "li", text: "Liechtenstein", image: flagli },
  { id: "lt", value: "lt", text: "Lithuania", image: flaglt },
  { id: "lu", value: "lu", text: "Luxembourg", image: flaglu },
  { id: "mo", value: "mo", text: "Macau", image: flagmo },
  { id: "mk", value: "mk", text: "Macedonia", image: flagmk },
  { id: "mg", value: "mg", text: "Madagascar", image: flagmg },
  { id: "mw", value: "mw", text: "Malawi", image: flagmw },
  { id: "my", value: "my", text: "Malaysia", image: flagmy },
  { id: "mv", value: "mv", text: "Maldives", image: flagmv },
  { id: "ml", value: "ml", text: "Mali", image: flagml },
  { id: "mt", value: "mt", text: "Malta", image: flagmt },
  { id: "mh", value: "mh", text: "Marshall Islands", image: flagmh },
  { id: "mq", value: "mq", text: "Martinique", image: flagmq },
  { id: "mr", value: "mr", text: "Mauritania", image: flagmr },
  { id: "mu", value: "mu", text: "Mauritius", image: flagmu },
  { id: "yt", value: "yt", text: "Mayotte", image: flagyt },
  { id: "fm", value: "fm", text: "Micronesia", image: flagfm },
  { id: "md", value: "md", text: "Moldova", image: flagmd },
  { id: "mc", value: "mc", text: "Monaco", image: flagmc },
  { id: "mn", value: "mn", text: "Mongolia", image: flagmn },
  { id: "me", value: "me", text: "Montenegro", image: flagme },
  { id: "ms", value: "ms", text: "Montserrat", image: flagms },
  { id: "ma", value: "ma", text: "Morocco", image: flagma },
  { id: "mz", value: "mz", text: "Mozambique", image: flagmz },
  { id: "na", value: "na", text: "Namibia", image: flagna },
  { id: "nr", value: "nr", text: "Nauru", image: flagnr },
  { id: "np", value: "np", text: "Nepal", image: flagnp },
  { id: "nl", value: "nl", text: "Netherlands", image: flagnl },
  { id: "an", value: "an", text: "Netherlandsantilles", image: flagan },
  { id: "nc", value: "nc", text: "New Caledonia", image: flagnc },
  { id: "pg", value: "pg", text: "New Guinea", image: flagpg },
  { id: "nz", value: "nz", text: "New Zealand", image: flagnz },
  { id: "ni", value: "ni", text: "Nicaragua", image: flagni },
  { id: "ne", value: "ne", text: "Niger", image: flagne },
  { id: "ng", value: "ng", text: "Nigeria", image: flagng },
  { id: "nu", value: "nu", text: "Niue", image: flagnu },
  { id: "nf", value: "nf", text: "Norfolk Island", image: flagnf },
  { id: "kp", value: "kp", text: "North Korea", image: flagkp },
  { id: "mp", value: "mp", text: "Northern Mariana Islands", image: flagmp },
  { id: "no", value: "no", text: "Norway", image: flagno },
  { id: "om", value: "om", text: "Oman", image: flagom },
  { id: "pk", value: "pk", text: "Pakistan", image: flagpk },
  { id: "pw", value: "pw", text: "Palau", image: flagpw },
  { id: "ps", value: "ps", text: "Palestine", image: flagps },
  { id: "pa", value: "pa", text: "Panama", image: flagpa },
  { id: "py", value: "py", text: "Paraguay", image: flagpy },
  { id: "pe", value: "pe", text: "Peru", image: flagpe },
  { id: "ph", value: "ph", text: "Philippines", image: flagph },
  { id: "pn", value: "pn", text: "Pitcairn Islands", image: flagpn },
  { id: "pl", value: "pl", text: "Poland", image: flagpl },
  { id: "pt", value: "pt", text: "Portugal", image: flagpt },
  { id: "pr", value: "pr", text: "Puerto Rico", image: flagpr },
  { id: "qa", value: "qa", text: "Qatar", image: flagqa },
  { id: "re", value: "re", text: "Reunion", image: flagre },
  { id: "ro", value: "ro", text: "Romania", image: flagro },
  { id: "ru", value: "ru", text: "Russia", image: flagru },
  { id: "rw", value: "rw", text: "Rwanda", image: flagrw },
  { id: "sh", value: "sh", text: "Saint Helena", image: flagsh },
  { id: "kn", value: "kn", text: "Saint Kitts and Nevis", image: flagkn },
  { id: "lc", value: "lc", text: "Saint Lucia", image: flaglc },
  { id: "pm", value: "pm", text: "Saint Pierre", image: flagpm },
  { id: "vc", value: "vc", text: "Saint Vincent", image: flagvc },
  { id: "ws", value: "ws", text: "Samoa", image: flagws },
  { id: "sm", value: "sm", text: "San Marino", image: flagsm },
  { id: "gs", value: "gs", text: "Sandwich Islands", image: flaggs },
  { id: "st", value: "st", text: "Sao Tome", image: flagst },
  { id: "sa", value: "sa", text: "Saudi Arabia", image: flagsa },
  { id: "sn", value: "sn", text: "Senegal", image: flagsn },
  { id: "rs", value: "rs", text: "Serbia", image: flagrs },
  { id: "sc", value: "sc", text: "Seychelles", image: flagsc },
  { id: "sl", value: "sl", text: "Sierra Leone", image: flagsl },
  { id: "sg", value: "sg", text: "Singapore", image: flagsg },
  { id: "sk", value: "sk", text: "Slovakia", image: flagsk },
  { id: "si", value: "si", text: "Slovenia", image: flagsi },
  { id: "sb", value: "sb", text: "Solomon Islands", image: flagsb },
  { id: "so", value: "so", text: "Somalia", image: flagso },
  { id: "za", value: "za", text: "South Africa", image: flagza },
  { id: "kr", value: "kr", text: "South Korea", image: flagkr },
  { id: "es", value: "es", text: "Spain", image: flages },
  { id: "lk", value: "lk", text: "Sri Lanka", image: flaglk },
  { id: "sd", value: "sd", text: "Sudan", image: flagsd },
  { id: "sr", value: "sr", text: "Suriname", image: flagsr },
  { id: "sz", value: "sz", text: "Swaziland", image: flagsz },
  { id: "se", value: "se", text: "Sweden", image: flagse },
  { id: "ch", value: "ch", text: "Switzerland", image: flagch },
  { id: "sy", value: "sy", text: "Syria", image: flagsy },
  { id: "tw", value: "tw", text: "Taiwan", image: flagtw },
  { id: "tj", value: "tj", text: "Tajikistan", image: flagtj },
  { id: "tz", value: "tz", text: "Tanzania", image: flagtz },
  { id: "th", value: "th", text: "Thailand", image: flagth },
  { id: "tl", value: "tl", text: "Timorleste", image: flagtl },
  { id: "tg", value: "tg", text: "Togo", image: flagtg },
  { id: "tk", value: "tk", text: "Tokelau", image: flagtk },
  { id: "to", value: "to", text: "Tonga", image: flagto },
  { id: "tt", value: "tt", text: "Trinidad", image: flagtt },
  { id: "tn", value: "tn", text: "Tunisia", image: flagtn },
  { id: "tr", value: "tr", text: "Turkey", image: flagtr },
  { id: "tm", value: "tm", text: "Turkmenistan", image: flagtm },
  { id: "tv", value: "tv", text: "Tuvalu", image: flagtv },
  { id: "ae", value: "ae", text: "U.A.E.", image: flagae },
  { id: "ug", value: "ug", text: "Uganda", image: flagug },
  { id: "ua", value: "ua", text: "Ukraine", image: flagua },
  { id: "uy", value: "uy", text: "Uruguay", image: flaguy },
  { id: "vi", value: "vi", text: "US Virgin Islands", image: flagvi },
  { id: "uz", value: "uz", text: "Uzbekistan", image: flaguz },
  { id: "vu", value: "vu", text: "Vanuatu", image: flagvu },
  { id: "va", value: "va", text: "Vatican City", image: flagva },
  { id: "ve", value: "ve", text: "Venezuela", image: flagve },
  { id: "vn", value: "vn", text: "Vietnam", image: flagvn },
  { id: "wf", value: "wf", text: "Wallis and Futuna", image: flagwf },
  { id: "ye", value: "ye", text: "Yemen", image: flagye },
  { id: "zm", value: "zm", text: "Zambia", image: flagzm },
  { id: "zw", value: "zw", text: "Zimbabwe", image: flagzw },
];
