import React, { useContext } from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import { GridColumn } from "notes";
import { StoreContext, TicketStub } from "Components";

export const PurchaseTicket = ({ eventId }) => {
  const { Store } = useContext(StoreContext);

  const [event, loadingEvent, errorEvent] = useDocumentDataOnce(
    Store.firestore().doc(`events/${eventId}`)
  );

  if (loadingEvent) {
    return null;
  }

  return (
    !loadingEvent && (
      <TicketContainer>
        <TicketStub show={event} />
      </TicketContainer>
    )
  );
};

const TicketContainer = styled(GridColumn)`
  margin-right: 104px;
  max-width: 392px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    max-width: inherit;
    padding: 0 24px 40px 24px;
  }
`;
