import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GridRow, Logo } from "notes";
import { HeaderActions } from "Components";
import { HeaderBannerSecondary, HeaderBannerSecondaryMobile } from "Images";

export const HeaderSecondary = ({ banner, inverse }) => {
  const history = useHistory();
  return (
    <Wrapper>
      {banner && (
        <>
          <BannerImageDesktop />
          <BannerImageMobile />
        </>
      )}
      <Content yCenter>
        <SetLiveLogo
          onClick={() => history.push("/")}
          name={inverse ? "SetLiveDefault" : "SetLiveReversed"}
        />
        <HeaderActions inverse={inverse} />
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(GridRow)`
  background-color: ${(props) => (!props.theme.dark ? "#ffffff" : "#000000")};
  transition: background-color ease 2000ms;
  position: relative;
  overflow: hidden;
  height: 88px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    height: 72px;
  }
`;

const BannerImageDesktop = styled(HeaderBannerSecondary)`
  position: absolute;
  top: 0;
  left: 0;
  width: 1304px;
  height: 89px;
  z-index: 0;
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    width: 100%;
    height: auto;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const BannerImageMobile = styled(HeaderBannerSecondaryMobile)`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 0;
  @media only screen and ${(props) => props.theme.media.small} {
    display: block;
  }
`;

const Content = styled(GridRow)`
  justify-content: space-between;
  padding: 0 40px;
  position: relative;
  width: 100%;
  z-index: 1;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px;
  }
`;

const SetLiveLogo = styled(Logo)`
  cursor: pointer;
  height: 24px;
  width: 114px;
  ${(props) => props.theme.dark && "path { fill: #fff };"}
  @media only screen and ${(props) => props.theme.media.small} {
    height: 16px;
    width: 76px;
  }
`;
