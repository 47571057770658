import React, { useState, useReducer } from "react";
import styled from "styled-components";
import { P, Label, GridColumn, Icon, Input, Select } from "notes";
import { reducer, validate } from "modules";
import { statesData, countryCodes } from "Data";
import { shippingAddressSchema } from "./schema";
import { FieldMessage } from "Components";
import {
  FieldContainer,
  FieldLabel,
  EditContainer,
  EditAction,
  InfoIcon,
  PurpleText,
  LinkStyle,
  Actions,
} from "../Name";

export const EditShipping = ({ defaultValue, onSubmit, ...props }) => {
  const [address, setAddress] = useReducer(
    reducer,
    !!defaultValue
      ? defaultValue
      : {
          country: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode: "",
        }
  );
  const [editMode, setEditMode] = useState(false);
  const [errors, setErrors] = useState({});

  const stateList = statesData.map((item, index) => {
    return { id: ++index, text: item };
  });

  let selectedState =
    address.state && stateList.find(({ text }) => text.includes(address.state));

  const countryList = Object.keys(countryCodes).map((item, index) => {
    return { id: ++index, text: countryCodes[item].name };
  });

  let selectedCountry =
    address.country && countryList.find(({ text }) => text === address.country);

  const handleChange = (name, value) => {
    setAddress({ [name]: value });
    delete errors[name];
    setErrors(errors);
  };

  const handleZip = (value) => {
    if (value.match(/^[0-9\b]+$/) || value === "") {
      handleChange("zipCode", value);
    }
  };

  const handleSubmit = (fields) => {
    const isError = validate(fields, shippingAddressSchema);
    if (isError) {
      setErrors(isError);
    } else {
      onSubmit(fields);
      setEditMode(false);
    }
  };

  return (
    <FieldContainer yCenter editMode={editMode}>
      {(!!defaultValue || editMode) && (
        <FieldLabel>Shipping Address</FieldLabel>
      )}
      {editMode ? (
        <EditContainerStyle>
          <GridColumn {...props}>
            <Label>Country</Label>
            <Select
              onChange={(value) => handleChange("country", value.text)}
              options={countryList}
              selected={selectedCountry}
              placeholder="Select an option..."
              error={errors.country}
              style={{ cursor: "default" }}
            />
            <FieldMessage
              active={!!errors.country}
              content="This is a required field and cannot be blank."
            />

            <Label>Address</Label>
            <Input
              onChange={(value) => handleChange("addressLine1", value)}
              value={address.addressLine1}
              placeholder="Address Line 1..."
              error={errors.addressLine1}
            />
            <FieldMessage
              active={!!errors.addressLine1}
              content="This is a required field and cannot be blank."
            />
            <Label>Apartment, suite, etc. (optional)</Label>
            <Input
              onChange={(value) => handleChange("addressLine2", value)}
              value={address.addressLine2}
              placeholder="Address Line 2..."
            />

            <Label>City</Label>
            <Input
              onChange={(value) => handleChange("city", value)}
              value={address.city}
              placeholder="City..."
              error={errors.city}
            />
            <FieldMessage
              active={!!errors.city}
              content="This is a required field and cannot be blank."
            />

            <Label>State or province</Label>
            <Select
              onChange={(value) => handleChange("state", value.text)}
              options={stateList}
              selected={selectedState}
              placeholder="Select an option..."
              error={errors.state}
              style={{ cursor: "default" }}
            />
            <FieldMessage
              active={!!errors.state}
              content="This is a required field and cannot be blank."
            />

            <Label>Zip or postal code</Label>
            <Input
              leftIcon={<Icon form name="Marker" />}
              onChange={handleZip}
              value={address.zipCode}
              placeholder="5-digit code..."
              style={{ width: "184px" }}
              type="number"
              error={errors.zipCode}
            />
            <FieldMessage
              active={!!errors.zipCode}
              content="This is a required field and cannot be blank."
            />
          </GridColumn>
          <Actions>
            <LinkStyle
              onClick={() => {
                setEditMode(false);
                setErrors({});
                setAddress(
                  !!defaultValue
                    ? defaultValue
                    : {
                        country: "",
                        addressLine1: "",
                        addressLine2: "",
                        city: "",
                        state: "",
                        zipCode: "",
                      }
                );
              }}
            >
              Cancel
            </LinkStyle>
            <LinkStyle onClick={() => handleSubmit(address)}>Apply</LinkStyle>
          </Actions>
        </EditContainerStyle>
      ) : !defaultValue ? (
        <>
          <PurpleText onClick={() => setEditMode(true)}>
            Add Shipping Address
          </PurpleText>
          <InfoIcon
            indicator
            name="Information"
            onClick={() => setEditMode(true)}
          />
        </>
      ) : (
        <>
          <P>{address.addressLine1}</P>
          {address.addressLine2 && <P>{address.addressLine2}</P>}
          <P>
            {address.city}, {address.state} {address.zipCode}
          </P>
          <P>{address.country}</P>

          <EditAction name="Edit" onClick={() => setEditMode(true)} />
        </>
      )}
    </FieldContainer>
  );
};

const EditContainerStyle = styled(EditContainer)`
  ${Label} {
    margin-top: 16px;
    margin-bottom: 4px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
