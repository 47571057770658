import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GridColumn, GridRow } from "notes";
import {
  FormWrapper,
  StoreContext,
  TicketStub,
  formatCurrency,
  ModalContext,
} from "Components";
import { Details } from "./Details";
import {
  useCollectionOnce,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { Container } from "../Enrollment";

export const Purchase = ({ location: { state } }) => {
  const { id, selectedTicket } = state;
  const { Store, user } = useContext(StoreContext);
  const { toggleConfirmModal } = useContext(ModalContext);
  const history = useHistory();
  const [tickets, setTickets] = useState([]);
  const [apiTickets, loading, error] = useCollectionOnce(
    Store.firestore()
      .collection("payment_items")
      .where("eventId", "==", id)
      .where("type", "==", "ticket")
      .orderBy("amount", "desc")
  );

  useEffect(() => {
    if (apiTickets) {
      let newTickets = apiTickets.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .reverse();
      if (newTickets.length)
        selectedTicket
          ? (newTickets.find(({ id }) => id === selectedTicket).selected = true)
          : (newTickets[0].selected = true);
      setTickets(newTickets);
    }
  }, [apiTickets]);
  const [event, loadingEvent, errorEvent] = useDocumentDataOnce(
    Store.firestore().doc(`events/${id}`)
  );

  if (loading || loadingEvent) {
    return null;
  }

  let minPrice = tickets.reduce((min, { amount }) => {
    return min && min < amount ? min : amount;
  }, null);

  let maxPrice = tickets.reduce((max, { amount }) => {
    return max && max > amount ? max : amount;
  }, null);

  const show = {
    ...event,
    price: `${formatCurrency(minPrice)} ${
      maxPrice && ` - ${formatCurrency(maxPrice)}`
    }`,
  };

  if (!user) {
    history.replace("/signup", state);
  }

  const handleSuccess = (response) => {
    toggleConfirmModal({
      open: true,
      items: response.context.transactions[0].data,
    });
    history.push(`/event/${state.id}`);
  };

  return (
    <FormWrapper hideFooter>
      <FormFlow showTicket>
        <TicketContainer>
          <TicketStub show={show} />
        </TicketContainer>
        <Details
          tickets={tickets}
          setTickets={setTickets}
          onSuccess={(response) => handleSuccess(response)}
        />
      </FormFlow>
    </FormWrapper>
  );
};

const FormFlow = styled(Container)`
  @media only screen and ${(props) => props.theme.media.small} {
    padding-bottom: 0;
  }
`;

const TicketContainer = styled(GridRow)`
  margin-right: 104px;
  max-width: 393px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: 0;
    margin-bottom: 40px;
    max-width: inherit;
    padding: 0 24px;
  }
`;
