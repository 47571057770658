import React, { Fragment } from "react";
import styled from "styled-components";
import { Rating, GridRow, H2, GridColumn } from "notes";
import { SplashBlock } from "Components";

export const ShowRating = ({ show, artistInfo, value, setValue }) => {
  return (
    <Container desktopImage={show.image} xCenter>
      <ShowDetails yEnd>
        <Column xCenter>
          {artistInfo ? (
            <Fragment>
              <BoldLabel>{show.artist}</BoldLabel>
              <Subtitle>{show.subtitle}</Subtitle>
            </Fragment>
          ) : (
            <BoldLabel>How was the show?</BoldLabel>
          )}
          <RatingContainer yCenter xCenter>
            <Rating max={5} value={value} setValue={setValue}></Rating>
          </RatingContainer>
        </Column>
      </ShowDetails>
      <Gradient />
    </Container>
  );
};

const RatingContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 200px;
  padding: 10px 20px;
  margin-top: 24px;
  border-radius: 4px;
`;

const Container = styled(SplashBlock)`
  width: 100%;
  height: 100%;
  padding: 22px;
  background-position: center center;
`;

const Gradient = styled(GridRow)`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  bottom: 0;
  position: absolute;
  height: 400px;
  width: 100%;
  z-index: 1;
`;

const ShowDetails = styled(GridRow)`
  height: 100%;
  position: relative;
  z-index: 2;
`;

const Column = styled(GridColumn)`
  margin-bottom: 24px;
  width: 100%;
`;

const BoldLabel = styled(H2)`
  color: ${(props) => props.theme.colors.white};
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 32px;
  text-transform: uppercase;
`;

const Subtitle = styled(H2)`
  color: ${(props) => props.theme.colors.disabledText};
  font-family: "Overpass", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 24px;
  text-align: center;
`;
