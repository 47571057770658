import React, { useReducer, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Joi from "@hapi/joi";
import styled from "styled-components";
import {
  GridColumn,
  P,
  H1,
  Select,
  Label,
  Input,
  TextArea,
  Icon,
  ButtonPrimary,
  GridRow,
  H4,
  withDefaultMedia,
} from "notes";
import {
  FieldMessage,
  StoreContext,
  HeaderSecondary,
  FooterBlock,
  LoaderInline,
  Link as BorderLink,
} from "Components";
import {
  Wrapper,
  Container,
  InlineLink,
  RelatedItems,
  PageHeader,
  RightColumn,
} from "../Privacy";
import { reducer, validate } from "modules";

const selectOptions = [
  {
    id: "registration",
    text: "Creating or Using a Set.Live Account",
  },
  {
    id: "billing",
    text: "Billing Questions",
  },
  {
    id: "show",
    text: "Questions About a Show",
  },
  {
    id: "feedback",
    text: "Thoughts and Feedback",
  },
];

const formSchema = Joi.object({
  reason: Joi.string().min(1).required(),
  name: Joi.string().min(1).required(),
  email: Joi.string().email({ tlds: false }).required(),
  message: Joi.string().min(3).required(),
});

export const Contact = withDefaultMedia(({ matches }) => {
  const { Store, user } = useContext(StoreContext);
  const [form, setForm] = useReducer(reducer, {
    reason: "",
    name: "",
    email: "",
    message: "",
    code: "",
    uid: user?.uid ?? "",
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleChange = (value, name) => {
    setForm({ [name]: value });
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = (form) => {
    const isError = validate(form, formSchema);
    setLoading(false);
    if (!isError) {
      setLoading(true);
      Store.firestore()
        .collection("contact_us")
        .add(form)
        .then((response) => {
          if (response) {
            setSuccess(true);
          }
        });
    } else {
      setErrors(isError);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let selected =
    !!form.reason && selectOptions.find(({ text }) => text === form.reason);

  return (
    <Wrapper>
      <HeaderSecondary banner />
      <PageHeader>
        <H1>Contact Us</H1>
      </PageHeader>
      <Container>
        {success ? (
          <GridColumn>
            <P>
              Thanks - We'll review your question and get back to you as soon as
              possible.
            </P>
          </GridColumn>
        ) : (
          <Content>
            <P>
              We’re here to help. Fill out the form and we will get back to you
              shortly.
            </P>

            <Form>
              <GridColumn columns={4}>
                <Label>What are you contacting us about?</Label>
                <Select
                  selected={selected}
                  options={selectOptions}
                  onChange={({ text, id }) => {
                    handleChange(text, "reason");
                    handleChange(id, "code");
                  }}
                  placeholder="Select an option..."
                  error={!!errors.reason}
                />
                <FieldMessage
                  active={!!errors.reason}
                  content="You must select a reason for contacting us."
                />

                <Label>Name</Label>
                <Input
                  onChange={(value) => handleChange(value, "name")}
                  placeholder="First and last name..."
                  value={form.name}
                  error={!!errors.name}
                />
                <FieldMessage
                  active={!!errors.name}
                  content="This is a required field and cannot be blank."
                />

                <Label>Email address</Label>
                <Input
                  leftIcon={<Icon form name="Email" />}
                  onChange={(value) => handleChange(value, "email")}
                  placeholder="john@email.com"
                  value={form.email}
                  error={!!errors.email}
                />
                <FieldMessage
                  active={!!errors.email}
                  content="This is a required field and cannot be blank."
                />
              </GridColumn>
              <Label>Message</Label>
              <TextArea
                onChange={(e) => handleChange(e.target.value, "message")}
                placeholder="Comments..."
                value={form.message}
                error={!!errors.message}
                style={{ minHeight: "102px" }}
              />
              <FieldMessage
                active={!!errors.message}
                content="Please provide a short description of your reason for contacting us."
              />

              <Submit disabled={loading} onClick={() => handleSubmit(form)}>
                {loading ? <LoaderInline /> : "Submit"}
              </Submit>
            </Form>
          </Content>
        )}
        <RightColumn>
          {!matches.small && (
            <RelatedItems>
              <P>Related Links</P>
              <InlineLink onClick={() => history.push("/privacy")}>
                Privacy Policy
              </InlineLink>
              <InlineLink onClick={() => history.push("/terms")}>
                Terms of Use
              </InlineLink>
            </RelatedItems>
          )}
          <ContactInfo>
            <H4>Set.Live</H4>
            <Address>
              <CityIcon form name="City" />
              <P>
                2595 Dallas Pkwy.
                <br />
                Suite 440
                <br />
                Frisco, TX 75034
                <br />
                United States
              </P>
            </Address>
            <GridRow yCenter>
              <IconStyle form name="Email" />
              <BorderLink href="mailto:support@set.live" target="_blank">
                support@set.live
              </BorderLink>
            </GridRow>
          </ContactInfo>
        </RightColumn>
      </Container>

      <FooterBlock />
    </Wrapper>
  );
});

const Content = styled(GridColumn)`
  max-width: 600px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: 100%;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px;
    max-width: 100%;
  }
`;

const Form = styled(GridColumn)`
  ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
`;

const Submit = styled(ButtonPrimary)`
  margin-top: 56px;
  width: 100%;
  max-width: 184px;
  @media only screen and ${(props) => props.theme.media.small} {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const ContactInfo = styled(GridColumn)`
  margin-top: 72px;
  ${H4} {
    font-weight: 700;
    margin-bottom: 8px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    background-color: #fafafa;
    border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
    padding: 40px 24px;
    margin: 0;
  }
`;

const Address = styled(GridColumn)`
  padding-left: 24px;
  position: relative;
  margin-bottom: 8px;
`;

const CityIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
`;

const IconStyle = styled(Icon)`
  margin-right: 8px;
`;
