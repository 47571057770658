import React, { createContext, useReducer } from "react";
import { reducer } from "modules";
export * from "./Field";
export * from "./handleErrors";

export const FormContext = createContext();
export const FormConsumer = FormContext.Consumer;

const initMusician = {
  about: "",
  email: "",
  name: {
    firstName: "",
    lastName: "",
  },
  phone: "",
  relationship: "",
};

export const FormProvider = ({ children }) => {
  const [musicianForm, setMusicianForm] = useReducer(reducer, initMusician);

  const values = {
    musicianForm,
    setMusicianForm,
  };

  return <FormContext.Provider value={values}>{children}</FormContext.Provider>;
};
