import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  HeaderSecondary,
  FooterBlock,
  SplashBlock,
  Ticket,
  Packages,
} from "Components";
import { GridColumn, withDefaultMedia } from "notes";
import { Stage, ArtistDescription, ShowStatus, SetList } from "./Blocks";

export const PreShow = withDefaultMedia(({ matches, show, live, tickets }) => {
  const history = useHistory();
  const { timeToStart } = show;
  const handleActionClick = (show, purchased) => {
    purchased
      ? window.open(show.requests)
      : history.push(`/purchase`, { id: show.id });
  };

  const packageRef = useRef();

  const handleScroll = () => {
    let scrollPosition = packageRef.current;
    scrollPosition.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  return (
    <GridColumn>
      <HeaderSecondary banner />
      <Stage>
        <ShowStatus
          days={timeToStart.days}
          hours={timeToStart.hours}
          minutes={timeToStart.minutes}
          seconds={timeToStart.seconds}
          live={live}
        />

        <Ticket
          onActionClick={() => handleActionClick(show, !!tickets)}
          show={show}
          purchased={!!tickets}
          onScrollClick={handleScroll}
        />
      </Stage>
      <Container xCenter>
        <Content ref={packageRef}>
          {!tickets && <Packages eventId={show.id} />}
          {!!show.requests && <SetList link={show.requests} />}
          <ArtistDescription show={show} />
        </Content>
      </Container>
      <FooterBlock secondary />
    </GridColumn>
  );
});

const Container = styled(SplashBlock)`
  background-color: #fff;
  padding: 0 24px 90px 24px;
  position: relative;
  @media only screen and ${(props) => props.theme.media.small} {
    padding-bottom: 72px;
  }
`;

const Content = styled(GridColumn).attrs({ as: "div" })`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1016px;
`;
