import React from "react";
import styled from "styled-components";
import { SplashBlock } from "Components";
import { ConcertSeatingChart } from "Images";
import { GridColumn } from "notes";

export const Stage = ({ children }) => {
  return (
    <Container xCenter>
      <Content>{children}</Content>
      <ConcertImageContainer xCenter>
        <ConcertSeatingChartSvg />
      </ConcertImageContainer>
    </Container>
  );
};

const ConcertImageContainer = styled(GridColumn)`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  padding-top: 136px;
  width: 100%;
  height: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: flex-end;
  }
`;

const ConcertSeatingChartSvg = styled(ConcertSeatingChart)`
  height: 1172.52px;
  width: 2108.75px;
  flex-shrink: 0;
  z-index: 1;
  path {
    fill: #000;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    height: 556px;
    width: 1000px;
  }
  @media only screen and (max-width: 420px) {
    height: 338px;
    width: 608px;
  }
`;

const Content = styled(GridColumn)`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1304px;
  z-index: 3;
`;

const Container = styled(SplashBlock)`
  background-color: ${(props) => props.theme.palette.black};
  position: relative;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 24px 24px 40px 24px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    padding: 3.067vw 3.067vw 5.521vw 3.067vw;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    padding: 3.067vw 3.067vw 5.521vw 3.067vw;
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    padding: 40px 40px 72px 40px;
  }
`;
