import React from "react";
import styled from "styled-components";
import { googleLogin, facebookLogin } from "services";
import { GridColumn, ButtonGoogle, ButtonFacebook } from "notes";

export const OpenAuth = ({ onSuccess }) => {
  const signInWithGoogle = async () => {
    try {
      const result = await googleLogin();
      onSuccess(result);
    } catch (err) {
      console.log(err);
      window.scrollTo(0, 0);
    }
  };

  const signInWithFacebook = async () => {
    try {
      const result = await facebookLogin();
      onSuccess(result);
    } catch (err) {
      console.log(err);
      window.scrollTo(0, 0);
    }
  };

  return (
    <GridColumn noShrink>
      <ButtonFacebook onClick={signInWithFacebook}>
        Continue With Facebook
      </ButtonFacebook>
      <GoogleButton onClick={signInWithGoogle}>
        Continue With Google
      </GoogleButton>
    </GridColumn>
  );
};

const GoogleButton = styled(ButtonGoogle)`
  border: 1px solid ${(props) => props.theme.palette.gray.primary};
  margin-top: 8px;
`;
