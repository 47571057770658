import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  ButtonSecondarySmall,
  GridRow,
  GridColumn,
  Logo,
  withDefaultMedia,
} from "notes";
import {
  ButtonSignUp,
  SplashBlock,
  Section,
  StoreContext,
  HeaderActions,
  ModalContext,
} from "Components";
import { Messaging } from "./Messaging";

const concertImageDesktop =
  "https://cdn1.musicaudience.info/4e74b6ca31b0c4786ee1efab8e54a8e7.jpeg";
const concertImageMobile =
  "https://cdn1.musicaudience.info/8e773dd0ef40172cc1f59682216eedfd.jpeg";

export const HeaderBlock = withDefaultMedia(({ matches, nextShows }) => {
  const history = useHistory();

  const { user, tickets } = useContext(StoreContext);
  const { toggleSignUpModal } = useContext(ModalContext);

  return (
    <Fragment>
      <BlockHeader
        desktopImage={concertImageDesktop}
        mobileImage={concertImageMobile}
        loggedIn={user}
      >
        <Header yCenter>
          <SetLiveLogo name="SetLiveReversed" />
          <GridRow>
            <HeaderActions primary />
          </GridRow>
        </Header>
        <ContentSection yEnd stretch>
          <Messaging user={user} shows={nextShows} tickets={tickets} />
          {!user && (
            <ActionContainer>
              <ButtonSignUp
                onClick={() => {
                  matches.small
                    ? history.push("/signup")
                    : toggleSignUpModal(true);
                }}
              >
                Get a Free Set.Live Account
              </ButtonSignUp>
              <LoginButton small onClick={() => history.push("/login")}>
                Log In
              </LoginButton>
            </ActionContainer>
          )}
        </ContentSection>
        <Gradient />
      </BlockHeader>
    </Fragment>
  );
});

const SetLiveLogo = styled(Logo)`
  height: 16px;
  width: 76px;

  @media only screen and ${(props) => props.theme.media.medium} {
    height: 3.067vw;
    width: 14.571vw;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    height: 3.067vw;
    width: 14.571vw;
  }

  @media only screen and ${(props) => props.theme.media.extraLarge} {
    height: 40px;
    width: 190px;
  }
`;

const Gradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #222222 100%);
  display: none;
  position: absolute;
  top: 186px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  @media only screen and ${(props) => props.theme.media.small} {
    display: block;
  }
`;

const Header = styled(GridRow)`
  height: 40px;
  justify-content: space-between;
`;

const ActionContainer = styled(GridColumn)`
  margin-top: 56px;
  max-width: 392px;
  width: 100%;
`;

const LoginButton = styled(ButtonSecondarySmall)`
  border-color: #fff;
  color: #fff;
  margin: 0 auto;
  ${(props) => props.small && "display: none"};
  &:hover,
  &:focus {
    background-color: #fff;
    border-color: #fff;
    color: ${(props) => props.theme.colors.text};
  }
  @media only screen and ${(props) => props.theme.media.small} {
    display: ${(props) => (props.small ? "flex" : "none")};
    margin-top: 8px;
    width: 100%;
  }
`;

const Light = styled.span`
  color: ${(props) => props.theme.colors.disabledText};
  font-weight: 300;
`;

const ContentSection = styled(Section)`
  align-items: center;
  z-index: 2;
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: stretch;
  }
`;

const BlockHeader = styled(SplashBlock)`
  background-position: center top;
  position: relative;
  min-height: 44.325vw;
  z-index: 0;
  h1,
  h3 {
    text-align: center;
  }
  h1 {
    color: #fff;
    font-size: 4.294vw;
    line-height: 5.521vw;
  }
  h3 {
    color: ${(props) =>
      props.loggedIn ? props.theme.palette.gray.lighter : "#ffffff"};
    font-size: 1.917vw;
    font-weight: 700;
    line-height: 2.454vw;
    margin-top: 16px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    background-size: contain;
    background-position: center top;
    min-height: 540px;
    padding: 16px 24px 40px 24px;
    h1,
    h3 {
      text-align: left;
    }
    h1 {
      font-size: 40px;
      line-height: 44px;
    }
    h3 {
      font-size: 17px;
      line-height: 22px;
    }
  }

  @media only screen and ${(props) => props.theme.media.medium} {
    min-height: 471px;
  }

  @media only screen and ${(props) => props.theme.media.extraLarge} {
    padding: 56px 40px 72px 40px;
    min-height: 578px;
    h1,
    h2 {
      text-align: center;
    }
    h1 {
      font-size: 56px;
      line-height: 72px;
    }
    h3 {
      font-size: 25px;
      line-height: 32px;
    }
  }
`;
