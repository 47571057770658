import React from "react";
import { CountryPhone, FieldMessage } from "Components";

export const PhoneField = ({
  onChange,
  field,
  value,
  error = {},
  ...props
}) => {
  const handleText = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <>
      <CountryPhone
        phoneNumber={value}
        onChange={(value) => handleText(value)}
        error={!!error.status}
        {...props}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};
