const socialWindow = (url) => {
  var left = (window.screen.width - 570) / 2;
  var top = (window.screen.height - 570) / 2;
  var params =
    "titlebar=no,menubar=no,toolbar=no,status=no,width=570,height=300,top=" +
    top +
    ",left=" +
    left;
  window.open(url, "NewWindow", params);
};

export const socialShare = (type, url, message) => {
  var pageUrl = encodeURIComponent(url);
  var tweet = encodeURIComponent(message);

  if (type === "facebook") {
    url =
      "https://www.facebook.com/sharer.php?u=" + pageUrl + "&quote=" + message;
    return socialWindow(url);
  }

  if (type === "twitter") {
    url = "https://twitter.com/intent/tweet?url=" + pageUrl + "&text=" + tweet;
    return socialWindow(url);
  }
};
