import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyC_WUpwAD8lZt3q2Hm6Rwkd53NNHSOJStY",
  authDomain: "set-live-stage.firebaseapp.com",
  databaseURL: "https://set-live-stage.firebaseio.com",
  projectId: "set-live-stage",
  storageBucket: "set-live-stage.appspot.com",
  messagingSenderId: "321388924990",
  appId: "1:321388924990:web:b1c3aba9ab90a83d20c16f",
  measurementId: "G-2JS3TBMJC9",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().useDeviceLanguage();
firebase
  .functions()
  .useFunctionsEmulator("https://set-live-stage.uc.r.appspot.com");

if (
  window.location.hostname === "localhost" &&
  process.env.REACT_APP_USE_EMULATOR
) {
  console.log("USING LOCAL EMULATOR");
  firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

export const Store = firebase;
