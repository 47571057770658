import React from "react";
import styled from "styled-components";
import {
  GridRow,
  GridColumn,
  Icon,
  ButtonPrimary,
  withDefaultMedia,
} from "notes";

export const Reminder = withDefaultMedia(({ matches, ...props }) => {
  return (
    <ReminderContainer xEnd yCenter {...props}>
      {matches.small ? (
        <ClickPadding
          xCenter
          yCenter
          onClick={() => console.log("add to calendar mobile")}
        >
          <StandAloneIcon name="Calendar" />
        </ClickPadding>
      ) : (
        <AddToCalendarButton
          onClick={() => console.log("add to calendar desktop")}
        >
          <AddIcon tertiary name="CalendarAdd" />
          Add to Calendar
        </AddToCalendarButton>
      )}
      {matches.small ? (
        <ClickPadding
          xCenter
          yCenter
          onClick={() => console.log("notifications mobile")}
        >
          <StandAloneIcon name="Bell" />
        </ClickPadding>
      ) : (
        <NotificationIcon
          onClick={() => console.log("notifications desktop")}
          name="Bell"
        />
      )}
      {matches.small && (
        <ClickPadding
          xCenter
          yCenter
          onClick={() => console.log("notifications mobile")}
        >
          <StandAloneIcon name="Share" />
        </ClickPadding>
      )}
    </ReminderContainer>
  );
});

const ClickPadding = styled(GridColumn)`
  width: 40px;
  height: 40px;
`;

const ReminderContainer = styled(GridRow)`
  height: 40px;
  width: 40.5%;
  flex-shrink: 0;
  padding-right: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding-right: 0;
    width: 100%;
  }
`;

const AddToCalendarButton = styled(ButtonPrimary)`
  background-color: #fff;
  color: ${(props) => props.theme.palette.black};
  &:focus,
  &:hover {
    background-color: #fff;
    color: ${(props) => props.theme.palette.black};
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    min-width: 17.293vw;
    height: 3.759vw;
    font-size: 1.598vw;
  }
`;

const AddIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: 9px;
  path {
    fill: ${(props) => props.theme.palette.black};
  }
`;

const StandAloneIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
`;

const NotificationIcon = styled(StandAloneIcon)`
  cursor: pointer;
  margin-left: 36px;
  path {
    fill: #fff;
  }
`;
