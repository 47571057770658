import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  GridRow,
  GridColumn,
  H2,
  P,
  H4,
  Subheader,
  ButtonPrimary,
} from "notes";
import { StoreContext, formatCurrency, ModalContext } from "Components";
import { ReactComponent as DefaultTicketImage } from "./TicketGreen.svg";
import { ReactComponent as MagnifyIcon } from "./Magnify.svg";

export const Packages = ({ eventId, smallView, ...props }) => {
  const { Store } = useContext(StoreContext);
  const { togglePackageModal } = useContext(ModalContext);
  const [tickets, loading, error] = useCollectionOnce(
    Store.firestore()
      .collection("payment_items")
      .where("eventId", "==", eventId)
      .where("type", "==", "ticket")
      .orderBy("amount", "desc")
  );
  const history = useHistory();

  if (loading) {
    return null;
  }

  let updatedTickets = tickets.docs
    .map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    .reverse();

  if (updatedTickets.length < 2) {
    return null;
  }

  return (
    <Wrapper {...props} smallView={smallView}>
      <Subheader>Pricing and Packages</Subheader>
      <Container smallView={smallView}>
        {updatedTickets.map(
          ({
            id,
            eventId,
            label,
            description,
            amount,
            purchaseLabel,
            assets,
          }) => {
            let packageImage = assets.primary?.path;
            let carouselObject = assets.carousel;
            let carouselArray = carouselObject?.map(
              ({ path }) => `${process.env.REACT_APP_ASSETS_PATH}/${path}`
            );
            return (
              <Card xCenter>
                <ImageContainer hasCarousel={!!carouselArray} xCenter yCenter>
                  {packageImage ? (
                    <>
                      <PackageImage
                        onClick={() =>
                          !!carouselArray &&
                          togglePackageModal({
                            open: true,
                            images: carouselArray,
                          })
                        }
                        src={`${process.env.REACT_APP_ASSETS_PATH}/${packageImage}`}
                      />
                      {!!carouselArray && (
                        <Magnify
                          onClick={() =>
                            togglePackageModal({
                              open: true,
                              images: carouselArray,
                            })
                          }
                        />
                      )}
                    </>
                  ) : (
                    <DefaultTicketImage />
                  )}
                </ImageContainer>
                <H2>{label}</H2>
                <P>{description}</P>
                <PurchaseContainer stretch yEnd>
                  <Price>{formatCurrency(amount)}</Price>
                  <ButtonPrimary
                    onClick={() =>
                      history.push("/purchase", {
                        id: eventId,
                        selectedTicket: id,
                      })
                    }
                  >
                    {purchaseLabel}
                  </ButtonPrimary>
                </PurchaseContainer>
              </Card>
            );
          }
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(GridColumn).attrs({ as: "div" })`
  ${(props) => props.smallView && `margin: 0 16px 16px 16px;`}
  ${Subheader} {
    margin-top: 40px;
    margin-bottom: 8px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media only screen and ${(props) => props.theme.media.small} {
      margin-top: 24px;
    }
  }
`;

const Container = styled(GridRow)`
  ${(props) =>
    props.smallView
      ? `flex-wrap: wrap; ${Card} {
        margin-bottom: 16px;}`
      : `
  ${Card} {
    margin: 0 8px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }`}
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    flex-wrap: wrap;
    margin-left: -16px;
  }

`;

const Card = styled(GridColumn)`
  background: #ffffff;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  padding: 24px;
  position: relative;
  width: 100%;
  min-height: 478px;
  ${H2} {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
    text-transform: uppercase;
    text-align: center;
  }
  ${P} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 15px;
    line-height: 19px;
    text-align: center;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: 235px;
    margin-bottom: 16px;
    margin-left: 16px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    min-height: inherit;
    & + & {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

const ImageContainer = styled(GridRow)`
  margin-bottom: 24px;
  width: 100%;
  height: 164px;
  cursor: ${(props) => (props.hasCarousel ? "pointer" : "default")};
`;

const PurchaseContainer = styled(GridColumn)`
  width: 100%;
`;

const PackageImage = styled.img`
  height: 100%;
  width: auto;
`;

const Price = styled(H4)`
  color: ${(props) => props.theme.palette.black};
  font-weight: 900;
  margin: 16px 0;
`;

const Magnify = styled(MagnifyIcon)`
  cursor: pointer;
  position: absolute;
  top: 27px;
  right: 35px;
  z-index: 2;
  path {
    fill: ${(props) => props.theme.palette.gray.lighter};
  }
`;
