import { Store } from "Components";

const { auth } = Store;

export const signup = async (data) => {
  const result = await auth().createUserWithEmailAndPassword(
    data.email,
    data.password
  );
  if (result.user) {
    Store.firestore()
      .collection("profiles")
      .doc(result.user.uid)
      .set({ name: { firstName: data.firstName, lastName: data.lastName } });
  }
  console.log("RETURNING CREATED USER", result);
  return result;
};

export const emailLogin = (email, password) => {
  return auth().signInWithEmailAndPassword(email, password);
};

export const googleLogin = () => {
  const provider = new auth.GoogleAuthProvider();
  const result = auth().signInWithPopup(provider);
  return result;
};

export const facebookLogin = () => {
  const provider = new auth.FacebookAuthProvider();
  provider.addScope("email");
  const result = auth().signInWithPopup(provider);
  return result;
};

export const logout = () => {
  return auth().signOut();
};
