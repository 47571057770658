import { DateTime } from "luxon";

export const formatShow = (show) => {
  const { assets, startDate, endDate, ...remain } = show;
  const start = DateTime.fromJSDate(startDate.toDate());
  const end = DateTime.fromJSDate(endDate.toDate());
  const date = start.toFormat("MMM d");
  const time = start.toFormat("t").toLowerCase();
  const length = Math.floor(end.diff(start, "minutes").minutes) + "min";
  const newImage = `${process.env.REACT_APP_ASSETS_PATH}/${assets.preshowBanner?.path}`;
  return {
    date,
    time,
    length,
    start,
    end,
    image: newImage,
    ...remain,
  };
};
