import Joi from "@hapi/joi";
export const user_schema = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: false })
    .required()
    .messages({
      "string.email": "This does not appear to be a valid address.",
      "any.required": "This is a required field and cannot be blank.",
    }),
  firstName: Joi.string().min(1).max(25).required().messages({
    "string.min": `"Last name" should have a minimum length of {#limit} characters`,
    "string.max": `"Last name" should have a maximum length of {#limit} characters`,
    "any.required": "This is a required field and cannot be blank.",
  }),
  lastName: Joi.string().min(1).max(50).required().messages({
    "string.min": `"Last name" should have a minimum length of {#limit} characters`,
    "string.max": `"Last name" should have a maximum length of {#limit} characters`,
    "any.required": "This is a required field and cannot be blank.",
  }),
  password: Joi.string().min(8).max(25).required().messages({
    "string.min": `"Password" should have a minimum length of {#limit} characters`,
    "string.max": `"Password" should have a maximum length of {#limit} characters`,
    "any.required": `This is a required field and cannot be blank.`,
  }),
  agree: Joi.any().valid(1, true).required().messages({
    "any.only": "You must confirm that you are at least 13 years old.",
    "any.required": "You must confirm that you are at least 13 years old.",
  }),
});
